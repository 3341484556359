import React, { useState, useEffect } from 'react';
import { Space, Row, Col, Form, Input } from 'antd';

const parameters = ['CH4Dest PR', 'CH4 Dest i', 'Q', 'LFG', 'PRCH4', 'P5', 'P8', 'n'];

const CustomParameter = ({ parameter, disabledAll }) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (parameters.includes(parameter?.name) || disabledAll) {
      setDisabled(true);
    }
  }, [parameter?.name]); // eslint-disable-line

  return (
    <div className='form-row'>
      <Row justify='space-between' align='middle' gutter={70}>
        <Col span={18}>
          <div>
            <Space direction='vertical' size='middle'>
              <b>{parameter?.name}</b>
              <span>{parameter?.data?.description}</span>
            </Space>
          </div>
        </Col>
        <Col span={6}>
          <Form.Item
            name={parameter?.name}
            label='Value'
            initialValue={Number(parameter?.data?.value)}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please provide the value for the parameter'
              },
              {
                validator: (_, value) => {
                  return !value || Number(value) > 0
                    ? Promise.resolve()
                    : Promise.reject(`This is not a valid value`);
                }
              }
            ]}>
            <Input type='number' disabled={disabled} className='rounded-input-analytics' />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CustomParameter;
