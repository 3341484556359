import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { AppContext } from '../context/globalState';
import { checkToken } from '../utils/firebase';
import { Form, Input, Modal, Space } from 'antd';
import { scribehub, logo, LoginWelcImg, betaDots } from '../components/index';

const Login = () => {
  let history = useHistory();
  const { jwt } = useParams();
  const [modal, contextHolder] = Modal.useModal();
  const { isLoggedIn, setLoggedIn } = useContext(AppContext);
  const [token, setToken] = useState('');
  const [loginMode, setLoginMode] = useState('ScribeHub');

  useEffect(() => {
    if (jwt) {
      setToken(jwt);
      checkToken(jwt, callback, onError);
    }
  }, [jwt]); // eslint-disable-line

  useEffect(() => {
    isLoggedIn && history.push('/overview');
  }, [isLoggedIn]); // eslint-disable-line

  const callback = () => setLoggedIn(true) || history.push('/overview');

  const onError = error => {
    const config = {
      title: 'Login error',
      content: (
        <div>
          <p>{error}</p>
        </div>
      ),
      onOk: () => {
        setLoggedIn(false);
        history.push('/');
      }
    };
    modal.error(config);
  };
  const onFinish = () => {
    checkToken(token, callback, onError);
  };

  return (
    <React.Fragment>
      {loginMode === 'ScribeHub' && (
        <div className='login-main-section-v3'>
          <div className='login-scribehub-wrapper-v3'>
            <div className='login-logo-wrapper'>
              <img src={logo} alt='Logo' className='login-logo' />
            </div>
            <div className='login-scribehub'>
              <Space direction='vertical' size={50}>
                <Space direction='vertical'>
                  <h2>
                    Sign in to access <br /> the dashboard
                  </h2>
                  <a
                    href='https://digitalmrv.scribehub.com/sign-in?return=/sso/create'
                    className='primary-btn-xxlg'>
                    <img src={scribehub} alt='Scribehub logo' />
                    &nbsp;&nbsp; Sign in with ScribeHub
                  </a>
                  <div className='info-wrapper'>
                    <p className='login-scribehub-note'>Don’t have an account?</p>
                    <a
                      href='mailto:info@digitalmrv.io?subject=DigitalMRV%20access'
                      className='request-access-link'>
                      Contact Us
                    </a>
                  </div>
                </Space>
                { 
                  window.location.hostname === 'localhost' ? ( 
                    <button className='switch-mode-btn login-btn' onClick={() => setLoginMode('JWT')}> 
                      Login with token
                    </button>
                  ) : null
                }
              </Space>
            </div>
            <div className='login-footer-wrapper'>
              <Space direction='vertical'>
                <img src={betaDots} alt='beta-dots' />
                <Space size={4} direction='vertical'>
                  <span className='medium'>BETA version</span>
                  <span className='ant-card-title'>Demos & Enquiries</span>
                  <span className='small-grey'>Launched Nov 2020.</span>
                </Space>
              </Space>
            </div>
          </div>
          <div className='login-welc-wrapper'>
            <img src={LoginWelcImg} alt='login-welc' />
          </div>
        </div>
      )}

      {loginMode === 'JWT' && (
        <div className='login-main-section-v3'>
          <div className='login-scribehub-wrapper-v3'>
            <div className='login-logo-wrapper'>
              <img src={logo} alt='Logo' className='login-logo' />
            </div>
            <div className='login-content login-scribehub'>
              <h2> Login </h2>
              <Form
                size='large'
                layout='vertical'
                className='login-form'
                hideRequiredMark
                onFinish={onFinish}
                validateTrigger='onSubmit'>
                <Form.Item
                  name={['token']}
                  label='Token'
                  hasFeedback
                  onChange={e => setToken(e.target.value.replace('https://digitalmrv.io/', ''))}>
                  <Input className='rounded-input' />
                </Form.Item>
                <button className='login-btn primary-btn-xxlg' type='submit'>
                  <img src={scribehub} alt='Scribehub logo' />
                  &nbsp;&nbsp; Login
                </button>
                <br />
                <br />
                <br />
                <button className='switch-mode-btn login-btn' onClick={() => setLoginMode('ScribeHub')}>
                  Login with ScribeHub
                </button>
              </Form>
              {contextHolder}
            </div>
            <div className='login-footer-wrapper'>
              <Space direction='vertical'>
                <img src={betaDots} alt='beta-dots' />
                <Space size={4} direction='vertical'>
                  <span className='medium'>BETA version</span>
                  <span className='ant-card-title'>Demos & Enquiries</span>
                  <span className='small-grey'>Launched Nov 2020.</span>
                </Space>
              </Space>
            </div>
          </div>
          <div className='login-welc-wrapper'>
            <img src={LoginWelcImg} alt='login-welc' />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Login;
