import React, { useState, useEffect } from 'react';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { Upload, Tabs } from 'antd';
import { DocumentationTable, Layout, SiteHeader } from '../components';
import { deleteFile, listFiles, uploadFile } from '../utils/firebase';
import callApi from '../utils/callApi';

const { TabPane } = Tabs;

const Documentation = () => {
  const { siteId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  useEffect(() => {
    loadDocuments();
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredDocuments(
      documents.filter(document => document.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [searchQuery, documents]);

  const handleUpload = async ({ file }) => {
    await uploadFile(file, uploadSuccessCallback);
  };

  const handleDeletion = async file => {
    await deleteFile(file?.name, loadDocuments);
  };

  const uploadSuccessCallback = async file => {
    try {
      const response = await callApi('newactivity', { 
        activity: 'uploaded file', item: file.trim() 
      });

      if (!response?.error && response?.status !== 'error') {
        loadDocuments();
      } else {
        console.error('Error registering activity', response?.error);
      }
    } catch (err) {
      console.error('Error while registering activity', err);
    }
  };

  const loadDocuments = async () => {
    try {
      const callback = file => {
        setDocuments(documents => [...documents.filter(doc => doc?.name !== file?.name), file])
      };
      setDocuments([]);
      await listFiles(callback, `dmrv/docs/${siteId}`);
    } catch (err) {
      console.error('Error while loading files', err);
    }
  };

  return (
    <Layout>
      <React.Fragment>
        <SiteHeader search callback={value => setSearchQuery(value)} />
        <div className='site-info'>
          <div className='sub-header-wrapper'>
            <div className='notification-wrapper'>
              <h3> Documentation </h3>
            </div>
            <Upload name='file' customRequest={handleUpload} onRemove={handleDeletion}>
              <button className='primary-btn-inverse'>
                <VerticalAlignTopOutlined className='icon-add' />
                Upload
              </button>
            </Upload>
          </div>
          <Tabs tabBarGutter={50} defaultActiveKey='1'>
            <TabPane tab='Certification' key='1'>
              {/* <DocumentationCertificates /> */}
              <DocumentationTable data={filteredDocuments} onDelete={handleDeletion} />
            </TabPane>
            <TabPane tab='Monitoring plans' key='2'>
              {/* <MonitoringPlans /> */}
              <DocumentationTable data={filteredDocuments} onDelete={handleDeletion} />
            </TabPane>
            <TabPane tab='Exported Assets' key='3'>
              <DocumentationTable data={filteredDocuments} onDelete={handleDeletion} />
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default Documentation;
