import React, { useState, useEffect } from 'react';
import { dateOptions } from '../../utils/dateOptions';
import { Table } from 'antd';

const SpreadsheetLogTable = ({ data, sensor }) => {
  const [columns, setColumns] = useState([]);
  const [sourceData, setSourceData] = useState([]);

  const LogTableColumns = [];

  useEffect(() => {
    async function tableColumns() {
      const sourceData = sensor?.Data?.[0];
      for (const element in sourceData) {
        let newColumn = {};
        if (element === 'Fecha') {
          newColumn = {
            title: element,
            key: element,
            dataIndex: element,
            render: record => (
              record && record?.toLocaleString(
                'en-us',
                dateOptions
              )),
            defaultSortOrder: 'descend'
          };
        } else {
          newColumn = {
            title: element,
            dataIndex: element,
          };
        }
        if (!element.startsWith('__EMPTY') && !['simulated', 'backendTimestamp', 'dataTimestamp'].includes(element)) {
          LogTableColumns.push(newColumn);
        }
      }
      setColumns(LogTableColumns);
    }
    setSourceData(data);

    tableColumns();
    const flattenedData = sensor?.Data && data?.map(Object.values)?.flat(2)?.filter(obj => typeof obj === 'object');
    flattenedData && setSourceData(flattenedData);
  }, [sensor, data]); // eslint-disable-line

  return (
    <Table
      className={data?.length > 10 ? 'ant-table-cell-pagination-log' : 'ant-table-cell-log'}
      columns={columns}
      dataSource={sourceData}
      rowKey={item => JSON.stringify(item)}
      pagination={{
        hideOnSinglePage: true,
        showTotal: (total, range) => (
          <span className='table-total'>
            Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
          </span>
        )
      }}
      scroll={{ y: 800, x: 'max-content' }}
    />
  );
};

export default SpreadsheetLogTable;
