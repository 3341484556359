import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../context/globalState';
import { CloseOutlined } from '@ant-design/icons';

const SensorMapPopup = ({ sensorId, payload, callback }) => {
  let history = useHistory();
  const { siteId } = useParams();
  const { sensors } = useContext(AppContext);
  const [data, setData] = useState({ title1: '', title2: '', data1: null, data2: null });
  const [sensor] = useState(sensors.find(sensor => sensor?.EquipmentUniqueIdentifier === sensorId));
  useEffect(() => {
    if (sensor && sensor?.EquipmentUniqueIdentifier) {
      switch (sensor?.EquipmentUniqueIdentifier) {
        case 'FIQ_4_3.Totalizador':
        case 'FIQ_4_4.Totalizador':
        case 'PIT_4_1.Presion_Simulada':
        case 'PIT_4_2.Presion_Simulada':
          setData({
            title1: 'Sensor ID',
            title2: 'Sensor Model',
            data1: sensor?.EquipmentUniqueIdentifier,
            data2: sensor?.Model || sensor?.EquipmentType
          });
          break;  

        case 'Feedstock':
        case 'Grape_Harvest':
          setData({
            title1: 'Type',
            title2: 'Data Source',
            data1: `${sensor?.EquipmentName}`,
            data2: sensor?.Model || sensor?.EquipmentType
          });
          break; 
        default:
          break;
      }
    }
  }, []); // eslint-disable-line

  const redirect = () => {
    history.push(`/${siteId}/sensors/${sensor.EquipmentUniqueIdentifier}`, sensor);
  };

  return (
    <div className="sensor-map-popup-wrapper">
      <div className='sensor-map-popup-header'>
        {payload?.[2] || sensor?.EquipmentName}
        <button className='popup-close' onClick={() => callback(false)}>
          <CloseOutlined />
        </button>
      </div>
      <div className="sensor-map-popup-body">
        {
          data?.data1 && (
            <div className="sensor-map-popup-entry">
              <div className="sensor-map-popup-entry-name">
                {data?.title1}
              </div>
              <div className="sensor-map-popup-entry-value">
                {data?.data1}
              </div>
            </div>
          )
        }
        {
          data?.data2 && (
            <div className="sensor-map-popup-entry">
              <div className="sensor-map-popup-entry-name">
                {data?.title2}
              </div>
              <div className="sensor-map-popup-entry-value">
                {data?.data2}
              </div>
            </div>
          )
        }
      </div>
      <div className="sensor-map-popup-footer">
        <button onClick={redirect} className='primary-btn-md'>
          View data source
        </button>
      </div>
    </div>
  );
}

export default SensorMapPopup;
