import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/globalState';
import { Loading, UsersTable, Layout, SiteHeader } from '../components';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { user } = useContext(AppContext);

  useEffect(() => {
    async function loadUsers() {
      try {
        const storedUsers = await localStorage.getItem('users');
        storedUsers && setUsers(JSON.parse(storedUsers));
        setLoading(false);
      } catch (err) {
        console.error('Error while loading users data', err);
      }
    }

    loadUsers();
  }, [user?.userId]); // eslint-disable-line

  useEffect(() => {
    setFilteredUsers(
      users.filter(user =>
        user?.displayName?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, users]);

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <SiteHeader search callback={value => setSearchQuery(value)} />
          <div className='site-info'>
            <div className='sub-header-wrapper'>
              <div className='notification-wrapper'>
                <h3> All Users </h3>
              </div>
            </div>
            <div className='transactions-tab-wrapper'>
              <UsersTable data={filteredUsers} />
            </div>
          </div>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default Users;
