import React, { useEffect, useState } from 'react';
import { Card, Space, Row, Col } from 'antd';
import { format, isToday, isYesterday } from 'date-fns';
import { getAverage, getPercentage } from '../utils/chartHelpers';
import {
  RangeBarChart,
  DateSelector,
  DailyFlowIcon,
  TotalFlowIcon,
	CurrentFlowIcon,
	InfosDashboard
} from '.';

const SensorInfo = ({ data, sensor, status }) => {
  const [currentDayAvg, setCurrentDayAvg] = useState(0);
  const [percent, setPercent] = useState(0);

	useEffect(() => {
    const todayValues = data?.filter(element => isToday(element?.dataTimestamp * 1000));
    const previousDayValues = data?.filter(element => isYesterday(element?.dataTimestamp * 1000));
    const percent = getPercentage(todayValues, previousDayValues, 'Real Time Flow');
    const currentDayAvg = getAverage(todayValues, 'Real Time Flow');

    setCurrentDayAvg(currentDayAvg);
    setPercent(percent);
	}, [data]); // eslint-disable-line

  return (
		<div>
			<Row gutter={16}>
				<Col span={24}>
					<Card bordered={false} className='sensor-info-card-horizontal'>
						<Row justify='space-between' gutter={20}>
							<Col span={8}>
								<div className='sensor-image-cover'>
									<img
										className='sensor-image'
										alt='sensor'
										src={sensor?.EquipmentImage}
									/>
								</div>
							</Col>
							<Col offset={1} span={6}>
								<Space direction='vertical' size='large'>
									<InfosDashboard title='Sensor name' body={sensor?.EquipmentName} />
									<InfosDashboard
										title='Sensor ID'
										body={sensor?.EquipmentUniqueIdentifier}
									/>
									<InfosDashboard
										title='Manufacturer'
										body={sensor?.EquipmentManufacturer || sensor?.Manufacturer}
									/>
									<InfosDashboard
										title='Type'
										body={sensor?.EquipmentType || sensor?.EquipmentModel}
									/>
									<InfosDashboard title='Model' body={sensor?.Model} />
									<InfosDashboard
										title='Serial number'
										body={sensor?.EquipmentSerialNumber}
									/>
								</Space>
							</Col>
							<Col span={6}>
								<Space direction='vertical' size='large'>
									<InfosDashboard title='Accuracy' body={sensor?.EquipmentAccuracy} />
									<InfosDashboard title='Installed on' body={sensor?.['Installed on']} />
									<InfosDashboard
										title='Last calibration'
										body={sensor?.EquipmentLastCalibration}
									/>
									<InfosDashboard
										title='Next calibration'
										body={sensor?.EquipmentNextCalibration}
									/>
									<InfosDashboard
										title='Last data snapshot'
										body={format(sensor?.LocalTimeStamp * 1000, 'MMM d, yyyy HH:mm:ss')}
									/>
								</Space>
							</Col>
							<Col span={4}>
								<div>
									<span className={`${status?.toLowerCase()}-info`}>{status}</span>
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			{sensor?.EquipmentGroup === 'Caudalimetro' ? (
				<div>
					<Row gutter={16}>
						<Col span={24}>
							<Row gutter={16}>
								<Col span={8}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img
													className='GHG-image'
													alt='current-flow'
													src={CurrentFlowIcon}
												/>
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>
															{sensor?.Data?.['Real Time Flow']}
														</span>
														<span className='ant-card-body-info-2'>Nm3/h</span>
													</Space>
													<span className='ant-card-title'>Current flow</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
								<Col span={8}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img className='GHG-image' alt='daily-flow' src={DailyFlowIcon} />
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>{currentDayAvg}</span>
														{!isNaN(percent) ? (
															Math.sign(percent) === 1 ? (
																<span className='connected-info'>+{percent}%</span>
															) : (
																<span className='offline-info'>{percent}%</span>
															)
														) : null}
													</Space>
													<span className='ant-card-title'>Daily average flow</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
								<Col span={8}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img className='GHG-image' alt='total-flow' src={TotalFlowIcon} />
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>
															{Number(sensor?.Data?.['Total Flow']).toLocaleString()}
														</span>
														<span className='ant-card-body-info-2'>Nm3</span>
													</Space>
													<span className='ant-card-title'>Total flow</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={24}>
							<Card bordered={false} className='site-info-card-2'>
								<div className='ant-card-title-wrapper-dashboard'>
									<Space>
										<span className='ant-card-title-activity'>Gas flowrate</span>
										<span className='ant-card-title'>(Nm3/h)</span>
									</Space>
									<DateSelector bordered />
								</div>
								<div className='chart-body-wrapper'>
									<RangeBarChart data={data} />
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			) : null}
		</div>
	);
};

export default SensorInfo;
