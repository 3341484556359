import React, { Fragment } from 'react';
import { Space, Row, Col } from 'antd';
import { InfoCardStackedBar } from '../';

const FeedstockType = ({ data, group, number }) => (
	<Fragment>
		<div className='sub-header'>Feedstock {number}</div>
		<div className='info-wrapper-2'>
			<div className='info-box'>
				<Space direction='vertical'>
					<span className='label'>Source:</span>
					<span className='value'>{group}</span>
				</Space>
			</div>
			<div className='info-box'>
				<Space direction='vertical'>
					<span className='label'>Last Update:</span>
					<span className='value'>
						{data?.at(-1)?.Fecha}
					</span>
				</Space>
			</div>
			<div className='info-box'>
				<Space direction='vertical'>
					<span className='label'>Quantity:</span>
					<span className='value'>
						{data?.reduce((acc, data) => acc + Number(data?.['Toneladas '] ?? 0), 0)?.toFixed(2)}
						<span className='unit'>Tonnes</span>
					</span>
				</Space>
			</div>
		</div>
		<Row gutter={20}>
			<Col span={24}>
				<InfoCardStackedBar
					title='Flowrate'
					data={data}
					dataset='Toneladas '
					color='blue'
					dateSelector
				/>
			</Col>
		</Row>
	</Fragment>
);

export default FeedstockType;
