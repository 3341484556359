import React, { Fragment } from 'react';

const InfosDashboard = ({ title, body }) => (
	<Fragment>
		{body && (
			<div>
				<h3 className='ant-card-infos-dashboard'>{title}</h3>
				<span className='ant-card-infos'>{body}</span>
			</div>
		)}
	</Fragment>
);

export default InfosDashboard;
