import { format, startOfDay } from 'date-fns';

const loadChartData = async (data, dataset, mainColor, simColor, siteId) => {
	let colorArr = [];
	let currentlySimulated = data[0]?.simulated;
	let dataSeries = [[]];
	let index = 0;

	for (const record of data) {
		let dataTimestamp;
		if (siteId === 'molina') {
			const [day, month, year] = record?.dataTimestamp?.split('/');
			const date = new Date(+year, month - 1, +day);
			dataTimestamp = date.getTime();
		} else { 
			if (!(typeof record?.dataTimestamp === 'number') || String(record?.dataTimestamp).length === 13) {
				dataTimestamp = record?.dataTimestamp;
			} else {
				dataTimestamp = record?.dataTimestamp * 1000;
			}
		}
		if (record?.simulated === currentlySimulated) {
			const innerArr = [dataTimestamp, Number(record?.[dataset])];
			dataSeries[index].push(innerArr);
			colorArr[index] = record?.simulated ? simColor : mainColor;
		} else {
			currentlySimulated = record?.simulated;
			dataSeries[index].push([dataTimestamp, Number(record?.[dataset])]);
			dataSeries[index].push([dataTimestamp, null]);
			index++;
			dataSeries.push([]);
			dataSeries[index].push([dataTimestamp, Number(record?.[dataset])]);
			colorArr[index] = record?.simulated ? simColor : mainColor;
		}
	}

	return [dataSeries, colorArr];
};

const loadStackedChartData = async (data, dataset, mainColor, simColor) => {
	const isSimulated = data?.find(record => record?.simulated);
	const n = isSimulated ? 2 : 1;
	let dataSeries = [n];
	let colorArr = [];
	let innerArrSim = [];
	let innerArr = [];
	const dailySensorData = data && (await dailyAverage(data, dataset));
	const sourceData = !dailySensorData ? data : dailySensorData[0];
	let timestamp;
	let date;

	for (const record of sourceData) {
		if (dataset === 'Toneladas ') {
			const [day, month, year] = record?.dataTimestamp?.split('/');
			date = new Date(+year, month - 1, +day);
			timestamp = date.getTime();
		}
		const dataTimestamp =
			dataset === 'Toneladas ' ? timestamp : format(record?.dataTimestamp, 'yyyy-MM-dd');
		const value =
			dataset === 'Toneladas '
				? Number(record?.sum).toFixed(2)
				: Number(record?.avgValue).toFixed(2);

		if (record?.simulated) {
			innerArrSim.push({
				x: dataTimestamp,
				y: value
			});
			dataSeries[0] = {
				name: '(Simulated) ' + dataset,
				data: innerArrSim
			};
			colorArr[0] = simColor;
		} else {
			innerArr.push({
				x: dataTimestamp,
				y: value
			});
			dataSeries[n - 1] = {
				name: dataset,
				data: innerArr
			};
			colorArr[n - 1] = mainColor;
		}
	}

	return [dataSeries, colorArr];
};

const getSeriesArr = async (dataSeries, colorArr, simColor, name) => {
	const series = [];
	let names = ['Simulated ' + name, name];

	for (let i = 0; i < colorArr?.length; i++) {
		if (colorArr[i] === simColor) {
			series.push({
				name: names[0],
				data: dataSeries[i]
			});
		} else {
			series.push({
				name: names[1],
				data: dataSeries[i]
			});
		}
	}

	return series;
};

const getAverage = (sensorData, dataset) =>
	sensorData?.length &&
	(
		sensorData
			?.map(element => Number(element[dataset] ?? element))
			.reduce((a, b) => a + b, 0) / sensorData?.length
	).toFixed(2);

const getCumulative = (sensorData, dataset) =>
sensorData?.length &&
(
	sensorData
		?.map(element => Number(element[dataset] ?? element))
		.reduce((a, b) => a + b, 0)).toFixed(2);

const everyNth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);

const eachCons = (array, num) => {
	return Array.from({ length: array.length - num + 1 }, (_, i) =>
		array.slice(i, i + num)
	);
};

const dailyAverage = async (data, dataset, calc) => {
	let dailySensorData = {};
	let periodTimestampArr = [];
	let day;

	for (const record of data) {
		if (dataset === 'Toneladas ' || calc) {
			day = record?.Fecha;
		} else {
			day = record?.dataTimestamp?.toString()?.length === 10 ? startOfDay(record?.dataTimestamp * 1000) : startOfDay(record?.dataTimestamp);
		}
		if (record[dataset] && Number(record[dataset]) >= 0) {
			let element = {
				sum: Number(record[dataset]),
				totalCount: 1,
				avgValue: Number(record[dataset]),
				dataTimestamp: day,
				...(record?.Empresa && { Empresa: record?.Empresa }),
				...(record?.simulated && { simulated: record?.simulated })
			};
			if (!dailySensorData.hasOwnProperty(day)) {
				dailySensorData[day] = element;
			} else {
				dailySensorData[day].sum =
					Number(dailySensorData[day].sum) + Number(record[dataset]);
				dailySensorData[day].simulated = record?.simulated && record?.simulated;
				dailySensorData[day].totalCount++;
				if (dailySensorData[day].sum === 0) {
					dailySensorData[day].avgValue = 0;
				} else {
					dailySensorData[day].avgValue = (
						Number(dailySensorData[day].sum) / dailySensorData[day].totalCount
					).toFixed(2);
				}
			}
		}
	}
	dailySensorData = Object?.entries(dailySensorData)?.map(e => e[1]);
	if (dataset === 'Toneladas ' || calc) {
		periodTimestampArr = dailySensorData?.map(record => record?.dataTimestamp);
	} else {
		periodTimestampArr = dailySensorData?.map(record =>
			format(record?.dataTimestamp, 'MMM d, yyyy')
		);
	}
	return [dailySensorData, periodTimestampArr];
};

const getPercentage = (todayArr, previousDayArr, dataset) => {
	let percent;
	const avgTodayFlow = getAverage(todayArr, dataset);
	const avgPreviousDayFlow = getAverage(previousDayArr, dataset);

	if (avgTodayFlow !== 0) {
		if (avgPreviousDayFlow !== 0) {
			percent = ((avgTodayFlow - avgPreviousDayFlow) / avgPreviousDayFlow) * 100;
		} else {
			percent = avgTodayFlow * 100;
		}
	} else {
		percent = -avgPreviousDayFlow * 100;
	}

	return percent.toFixed(2);
};

export {
	loadChartData,
	loadStackedChartData,
	getSeriesArr,
	getAverage,
	everyNth,
	eachCons,
	getPercentage,
	dailyAverage,
	getCumulative
};
