import React, { Fragment, useEffect, useState } from 'react';
import { Card, Space, Row, Col } from 'antd';
import { format } from 'date-fns';
import { feedstockTypes } from '../../assets/FeedstockTypes';
import { getAverage } from '../../utils/chartHelpers';
import {
	getTotalUncertainty,
	getAggregatedUncertainty
} from '../../utils/annotationsHelper';
import {
	DailyFlowIcon,
	TotalFlowIcon,
	CurrentFlowIcon,
	FeedstockType,
	InfosDashboard,
  DateSelector,
	Loading
} from '../';

const SpreadsheetInfo = ({ data, annotations, source, status }) => {
	const [feedstockGroup, setFeedstockGroup] = useState([]);
	const [currentFlow, setCurrentFlow] = useState(Number);
	const [dailyAvg, setDailyAvg] = useState(Number);
	const [loading, setLoading] = useState(false);
	const [uncertainty, setUncertainty] = useState();

	useEffect(() => {
		async function modifiedData() {
			try {
				setLoading(true);
				const flattenedData = data
					?.map(Object.values)
					?.flat(2)
					?.filter(obj => typeof obj === 'object');
				const dataset = source?.hasOwnProperty('EquipmentGroup')
					? 'OpcQuality'
					: 'Toneladas ';
				const dataSource = source?.hasOwnProperty('EquipmentGroup')
					? data
					: flattenedData;
				const datasetArr = dataSource?.map(record => record?.[dataset] ?? 0);

				const dailyAvg = getAverage(datasetArr);
				setDailyAvg(dailyAvg);

				const currentFlow = dataSource?.at(-1)?.[dataset];
				setCurrentFlow(currentFlow);

				const modifiedData = flattenedData?.map(obj => {
					const found = feedstockTypes?.find(
						record =>
							record?.Company?.replace(/\s+/g, '') === obj?.Empresa?.replace(/\s+/g, '') ||
							record?.Company?.split(/\s+/).includes(obj?.Empresa?.replace(/\s+/g, ''))
					)?.['Type of Feedstock'];
					return { ...(found && { 'Type of Feedstock': found }), ...obj };
				});
				const groupedData = groupBy(modifiedData, 'Type of Feedstock');
				setFeedstockGroup(groupedData);
				setLoading(false);
			} catch (err) {
				console.error('Error while transforming data', err);
				setLoading(false);
			}
		}
		modifiedData();
	}, [data]); // eslint-disable-line

	useEffect(() => {
		async function annotationsCalc() {
			try {
				setLoading(true);
				let simulatedData;
				const flattenedData = data
					?.map(Object.values)
					?.flat(2)
					?.filter(obj => typeof obj === 'object');
				const totalUncertainty = await getTotalUncertainty(annotations);
				const AggUn = await getAggregatedUncertainty(totalUncertainty);
				
				if (flattenedData && flattenedData?.length) {
					simulatedData = flattenedData.filter(record => record?.simulated)?.length;
					/*
					AggUn = Aggregated Uncertainty for sensor over time: (total no. of readings * sum of all uncertainty for those reading)/total no. of readings
					Uncertainty = [((total no. of missing/simulated readings * AggUn)/(total no. of readings * AggUn))^2 * (100% - Aggun)] + AggUn%
					*/
					const uncertainty =
						((simulatedData / flattenedData?.length) ** 2) * (100 - AggUn) + AggUn;
					setUncertainty(uncertainty);
				} else {
					simulatedData = data?.filter(record => record?.simulated)?.length;
					const uncertainty =
						((simulatedData / data?.length) ** 2) * (100 - AggUn) + AggUn;
					setUncertainty(uncertainty);
				}
			} catch (err) {
				console.error('Error while loading annotations', err);
			}
			setLoading(false);
		}
		annotationsCalc();
	}, [annotations]); // eslint-disable-line

	const groupBy = (arr, key) => {
		const initialValue = {};
		return arr.reduce((acc, cval) => {
			const myAttribute = cval[key];
			acc[myAttribute] = [...(acc[myAttribute] || []), cval];
			return acc;
		}, initialValue);
	};

	return (
		<div className='spreadsheet-info-wrapper'>
			{source?.hasOwnProperty('EquipmentGroup') ? (
				<div>
					<Row gutter={16}>
						<Col span={24}>
							<Card bordered={false} className='sensor-info-card-horizontal'>
								<Row justify='space-between' gutter={20}>
									<Col span={8}>
										<div className='sensor-image-cover'>
											<img
												className='sensor-image'
												alt='sensor'
												src={source?.EquipmentImage}
											/>
										</div>
									</Col>
									<Col offset={1} span={6}>
										<Space direction='vertical' size='large'>
											<InfosDashboard title='Sensor name' body={source?.EquipmentName} />
											<InfosDashboard
												title='Sensor ID'
												body={source?.EquipmentUniqueIdentifier}
											/>
											<InfosDashboard
												title='Manufacturer'
												body={source?.EquipmentManufacturer || source?.Manufacturer}
											/>
											<InfosDashboard
												title='Type'
												body={source?.EquipmentType || source?.EquipmentModel}
											/>
											<InfosDashboard title='Model' body={source?.Model} />
											<InfosDashboard
												title='Serial number'
												body={source?.EquipmentSerialNumber}
											/>
										</Space>
									</Col>
									<Col span={6}>
										<Space direction='vertical' size='large'>
											<InfosDashboard title='Accuracy' body={source?.EquipmentAccuracy} />
											<InfosDashboard
												title='Installed on'
												body={source?.['Installed on']}
											/>
											<InfosDashboard
												title='Last calibration'
												body={source?.EquipmentLastCalibration}
											/>
											<InfosDashboard
												title='Next calibration'
												body={source?.EquipmentNextCalibration}
											/>
											<InfosDashboard
												title='Last data snapshot'
												body={
													source?.LocalTimeStamp &&
													format(source?.LocalTimeStamp * 1000, 'MMM d, yyyy HH:mm:ss')
												}
											/>
										</Space>
									</Col>
									<Col span={4}>
										<div>
											<span className={`${status?.toLowerCase()}-info`}>{status}</span>
										</div>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Row gutter={16}>
								{currentFlow && (
									<Col span={8}>
										<Card bordered={false} className='dashboard-info-card'>
											<div className='ant-card-body-wrapper-2'>
												<Space align='center' size='middle' direction='horizontal'>
													<img
														className='GHG-image'
														alt='current-flow'
														src={CurrentFlowIcon}
													/>
													<Space direction='vertical'>
														<Space direction='horizontal'>
															<span className='ant-card-body-info'>
																{currentFlow?.toLocaleString()}
															</span>
															<span className='ant-card-body-info-2'>
																{source?.EquipmentGroup === 'Flowmeters'
																	? 'Nm3/h'
																	: 'mbar/h'}
															</span>
														</Space>
														<span className='ant-card-title'>Current flow</span>
													</Space>
												</Space>
											</div>
										</Card>
									</Col>
								)}
								{dailyAvg > 0 && (
									<Col span={8}>
										<Card bordered={false} className='dashboard-info-card'>
											<div className='ant-card-body-wrapper-2'>
												<Space align='center' size='middle' direction='horizontal'>
													<img
														className='GHG-image'
														alt='daily-flow'
														src={DailyFlowIcon}
													/>
													<Space direction='vertical'>
														<Space direction='horizontal'>
															<span className='ant-card-body-info'>
																{Number(dailyAvg)?.toLocaleString()}
															</span>
															<span className='ant-card-body-info-2'>
																{source?.EquipmentGroup === 'Flowmeters'
																	? 'Nm3/h'
																	: 'mbar/h'}
															</span>
														</Space>
														<span className='ant-card-title'>Daily average flow</span>
													</Space>
												</Space>
											</div>
										</Card>
									</Col>
								)}
								<Col span={8}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img
													className='GHG-image'
													alt='uncertainty'
													src={TotalFlowIcon}
												/>
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>
															{Number(uncertainty).toLocaleString()}
														</span>
														<span className='ant-card-body-info-2'>%</span>
													</Space>
													<span className='ant-card-title'>Uncertainty</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			) : (
				<Fragment>
					<div className='info-wrapper'>
						<div className='spreadsheet-img-wrapper'>
							<img src={source?.EquipmentImage} alt='data-source' />
						</div>
						<div className='info-card-wrapper'>
							<Row gutter={24}>
								<Col span={12}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img
													className='GHG-image'
													alt='current-flow'
													src={CurrentFlowIcon}
												/>
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>
															{currentFlow?.toLocaleString()}
														</span>
														<span className='ant-card-body-info-2'>mbar/h</span>
													</Space>
													<span className='ant-card-title'>Current flow</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
								<Col span={12}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img className='GHG-image' alt='daily-flow' src={DailyFlowIcon} />
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>
															{Number(dailyAvg)?.toLocaleString()}
														</span>
														<span className='ant-card-body-info-2'>mbar/h</span>
													</Space>
													<span className='ant-card-title'>Daily average flow</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={12}>
									<Card bordered={false} className='dashboard-info-card'>
										<div className='ant-card-body-wrapper-2'>
											<Space align='center' size='middle' direction='horizontal'>
												<img
													className='GHG-image'
													alt='uncertainty'
													src={TotalFlowIcon}
												/>
												<Space direction='vertical'>
													<Space direction='horizontal'>
														<span className='ant-card-body-info'>
															{Number(uncertainty).toLocaleString()}
														</span>
														<span className='ant-card-body-info-2'>%</span>
													</Space>
													<span className='ant-card-title'>Uncertainty</span>
												</Space>
											</Space>
										</div>
									</Card>
								</Col>
							</Row>
						</div>
					</div>
					<div className='selector-wrapper'>
						<DateSelector bordered={false} />
					</div>
					{loading ? (
						<Loading />
					) : (
						Object.keys(feedstockGroup).map((group, i) => (
							<FeedstockType
								data={feedstockGroup?.[group]}
								key={i}
								group={group}
								number={i + 1}
							/>
						))
					)}
				</Fragment>
			)}
		</div>
	);
};

export default SpreadsheetInfo;
