import React from 'react';
import { Layout, SolutionArchitecture, SiteHeader } from '../../components';

const Architecture = () => (
	<Layout>
		<SiteHeader />
		<div className='site-sub-header-wrapper'>
			<h3> Solution Architecture </h3>
		</div>
		<div className='arch-img-wrapper'>
			<img src={SolutionArchitecture} alt='solution-architecture' />
		</div>
	</Layout>
);

export default Architecture;
