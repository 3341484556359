/* eslint-disable no-extend-native */
String.prototype.toColor = function () {
  const avatarClassColor = [
    '#33D3FF',
    '#F25C9B',
    '#5F79FF',
    '#FE968A',
    '#69DF7C',
    '#FF7474',
    '#31C0FD',
    '#D868EB',
    '#735FED'
  ];
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % avatarClassColor.length) + avatarClassColor.length) % avatarClassColor.length;
  return avatarClassColor[hash];
};
