import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppContext } from './context/globalState';
import { 
  Activity,
  Analytics,
  AnalyticsSettings,
  AnalyticsSetup,
  AnalyticsExpanded,
  Login, 
  Documentation,
  Notifications,
  Overview, 
  Sensors, 
  DataSource, 
  SiteInfo,
  Users,
  DataSources,
  Architecture
} from './pages';

const protectedRoutes = [
  {
    path: '/overview',
    main: props => <Overview {...props} />
  },
  {
    path: '/:siteId/dashboard',
    main: props => <SiteInfo {...props} />
  },
  {
    path: '/:siteId/sensors/:sensorId',
    main: props => <DataSource {...props} />
  },
  {
    path: '/:siteId/sensors',
    main: props => <Sensors {...props} />
  },
  {
    path: '/:siteId/data-sources',
    main: props => <DataSources {...props} />
  },
  {
    path: '/users',
    main: props => <Users {...props} />
  },
  {
    path: '/:siteId/activity',
    main: props => <Activity {...props} />
  },
  {
    path: '/:siteId/analytics-settings/:equationId',
    main: props => <AnalyticsSettings {...props} />
  },
  {
    path: '/:siteId/analytics-setup',
    main: props => <AnalyticsSetup {...props} />
  },
  {
    path: '/:siteId/analytics/:equationId',
    main: props => <AnalyticsExpanded {...props} />
  },
  {
    path: '/:siteId/analytics',
    main: props => <Analytics {...props} />
  },
  {
    path: '/:siteId/documentation',
    main: props => <Documentation {...props} />
  },
  {
    path: '/:siteId/notifications',
    main: props => <Notifications {...props} />
  },
  {
    path: '/:siteId/architecture',
    main: props => <Architecture {...props} />
  }
];

const App = () => {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <BrowserRouter>
      <Switch>
        {protectedRoutes.map(route =>
          isLoggedIn ? (
            <Route key={route.path} path={route.path} component={route.main} />
          ) : (
            <Route exact key={route.path} path={route.path} component={Login} />
          )
        )}
        <Route exact path={'/:jwt'} component={Login} />
        <Route component={Login} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
