const feedstockTypes = [
    {
      "Company": "Aconcagua Food",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Agrícola Coexca",
      "Type of Feedstock": "Manure",
      "Region": "Maule",
      "__EMPTY": "manure",
      "DOC": undefined,
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Agrícola Coexca Las Astas",
      "Type of Feedstock": "Manure",
      "Region": "Biobío",
      "__EMPTY": "manure",
      "DOC": undefined,
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Agrícola Las Tizas",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Agrosuper",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Agrosuper Lo Miranda",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Agrosuper Rosario",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Aquasaim spa",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": undefined,
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Biodiversa",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.09",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Carozzi",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "CMPC Cordillera",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Metropolitana de Santiago ",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0.26"
    },
    {
      "Company": "CMPC Laja",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Biobío",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Coexca",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Concha y Toro",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Dole",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.2",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Ecofood",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Esmeralda",
      "Type of Feedstock": "Agricultural residue",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.2",
      "K": "0.05",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Goodvalley",
      "Type of Feedstock": "Agricultural residue",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.2",
      "K": "0.05",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "La Destileria",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Ñuble",
      "__EMPTY": "landfill",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Lincoyan Supermercado",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Lincoyan Supermercado",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Lincoyan Supermercado Aceite Oliva",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Lorenzini",
      "Type of Feedstock": "Manure",
      "Region": "Maule",
      "__EMPTY": "manure",
      "DOC": undefined,
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Matetic Wine Group",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Minuto Verde",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Metropolitana de Santiago ",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0.26"
    },
    {
      "Company": "Molina Vive Verde",
      "Type of Feedstock": "Organic fraction of municipal solid waste",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Nestle",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Pesquera Pacific Star",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Los Lagos",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0.14"
    },
    {
      "Company": "PF",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Proex",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "RR Wine",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.1",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Salmones Antartica",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Los Lagos",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0.14"
    },
    {
      "Company": "Santa Rita",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Santa Rita Buin",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Metropolitana de Santiago ",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0.26"
    },
    {
      "Company": "Santa Rita Palmilla",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Libertador General Bernardo O'Higgins ",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0"
    },
    {
      "Company": "Santa Rosa",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Sopraval",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Valparaíso",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.06",
      "ϕ": "0.8",
      "f_y": "0.27"
    },
    {
      "Company": "TAK",
      "Type of Feedstock": "Manure",
      "Region": "Maule",
      "__EMPTY": "manure",
      "DOC": "0.15",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Tamm",
      "Type of Feedstock": "Organic residues from fishery and meat industry",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.15",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Terranoble",
      "Type of Feedstock": "Biosolids – Stabilized sludge",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.05",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "VSPT",
      "Type of Feedstock": "Agricultural residue",
      "Region": "Maule",
      "__EMPTY": "landfill",
      "DOC": "0.2",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    },
    {
      "Company": "Watts",
      "Type of Feedstock": "Liquid industrial waste",
      "Region": "Maule",
      "__EMPTY": "wws",
      "DOC": "0.09",
      "K": "0.185",
      "ϕ": "0.85",
      "f_y": "0"
    }
]

export { feedstockTypes };