import React, { useContext, useState, useEffect, Suspense, lazy } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Card, Space, Row, Col } from 'antd';
import { AppContext } from '../context/globalState';
import { getAverage } from '../utils/chartHelpers';
import {
	Loading,
	Layout,
	SiteHeader,
	SensorMap3D,
	SensorMap3DMolina,
	SiteInfoCard,
	TotalFlowIcon,
	GHG30Days,
	GHGAnnual
} from '../components';
import { Activity } from '.';

const GoogleMapContainer = lazy(() => import('../components/GoogleMapContainer'));

const SiteInfo = () => {
	let history = useHistory();
	const { siteId } = useParams();
	const [avgDCF, setAvgDCF] = useState();
	const { sites, site, setSite, sensors, DCF } = useContext(AppContext);

	useEffect(() => {
		async function loadSites() {
			try {
				setSite(sites.find(site => site.id === siteId));
			} catch (err) {
				console.error('Error while loading sites data', err);
			}
		}

		loadSites();
	}, [siteId]); // eslint-disable-line

	useEffect(() => {
		async function getAvgDCF() {
			try {
				const DCF_Arr = DCF?.filter(obj => obj?.DCF); // filter data with DCF property only
				const avgDCF = DCF_Arr && DCF_Arr?.length && getAverage(DCF_Arr, 'DCF');
				setAvgDCF(avgDCF);
			} catch (err) {
				console.error('Error while calculating average data confidence score', err);
			}
		}
		getAvgDCF();
	}, [sensors, DCF]); // eslint-disable-line

	return (
		<Layout>
			<SiteHeader />
			{site?.id ? (
				<React.Fragment>
					<div className='site-sub-header-wrapper'>
						<h3>{site?.name}</h3>
					</div>
					<div className='site-info'>
						<Row gutter={16}>
							<Col span={16}>
								<Row gutter={24}>
									<Col span={24}>
										<Card className='site-info-overview-card' bordered={false}>
											<div className='site-map'>
												<Suspense fallback={<Loading />}>
													<GoogleMapContainer
														latitude={Number(site?.location?.latitude)}
														longitude={Number(site?.location?.longitude)}
													/>
												</Suspense>
											</div>
											<div className='site-info-card-description'>
												<Space direction='vertical' size='large'>
													<div>
														<h3 className='ant-card-infos-dashboard'>Project type</h3>
														<span className='ant-card-infos'>{site?.project?.type}</span>
													</div>
													<div>
														<h3 className='ant-card-infos-dashboard'>Methodology</h3>
														<span className='ant-card-infos'>
															{site?.project?.methodology}
														</span>
													</div>
													<div>
														<h3 className='ant-card-infos-dashboard'>
															Project developer
														</h3>
														<span className='ant-card-infos'>
															{site?.project?.developer}
														</span>
													</div>
													<div>
														<h3 className='ant-card-infos-dashboard'>Project ID</h3>
														<span className='ant-card-infos'>{site?.project?.id}</span>
													</div>
												</Space>
											</div>
										</Card>
									</Col>
								</Row>
								{siteId === 'copiulemu' && (
									<Row gutter={16}>
										<Col span={12}>
											<SiteInfoCard
												value={site?.GHG?.last30days}
												unit='t CO2e'
												label='GHG emission reductions last 30 day'
											/>
										</Col>
										<Col span={12}>
											<SiteInfoCard
												value={site?.GHG?.annual}
												unit='t CO2e'
												label='Annual GHG emission reductions'
											/>
										</Col>
									</Row>
								)}
							</Col>
							<Col span={8}>
								<Card
									bordered={false}
									className={
										siteId === 'molina'
											? 'dashboard-info-card-users-molina'
											: 'dashboard-info-card-users'
									}
									onClick={() => history.push(`/${siteId}/activity`)}>
									<div className='ant-card-title-wrapper-dashboard'>
										<span className='ant-card-title-activity'>Recent activity</span>
										<button
											onClick={() => history.push(`/${siteId}/activity`)}
											className='ant-card-title-link'>
											View all
										</button>
									</div>
									<Activity mini />
								</Card>
							</Col>
						</Row>
						{siteId === 'molina' && (
							<Row gutter={16}>
								<Col span={8}>
									<SiteInfoCard
										value={site?.GHG?.last30days}
										unit='t CO2e'
										label='GHG emission reductions last 30 day'
										icon={GHG30Days}
									/>
								</Col>
								<Col span={8}>
									<SiteInfoCard
										value={site?.GHG?.annual}
										unit='t CO2e'
										label='Annual GHG emission reductions'
										icon={GHGAnnual}
									/>
								</Col>
								<Col span={8}>
								{!avgDCF ? (
										<Loading />
									) : (
										<SiteInfoCard
											value={avgDCF}
											unit='%'
											label='Data confidence score'
											icon={TotalFlowIcon}
										/>
									)}
								</Col>
							</Row>
						)}
						<Row gutter={24}>
							<Col span={24}>
								{siteId === 'molina' ? <SensorMap3DMolina /> : <SensorMap3D />}
							</Col>
						</Row>
					</div>
				</React.Fragment>
			) : (
				<Loading />
			)}
		</Layout>
	);
};

export default SiteInfo;
