import React, { useState, useEffect } from 'react';
import { getTime, subDays } from 'date-fns';
import { auth, logout, onMessageListener } from '../utils/firebase';
import callApi from '../utils/callApi';
import firebaseConfig from '../firebase.config';
import { requestFirebaseNotificationPermission } from '../utils/firebase';
import { getDataConfidenceScore } from '../utils/annotationsHelper';

export const AppContext = React.createContext({});

const GlobalState = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isBrowserNotificationEnabled, setBrowserNotificationEnabled] = useState(true);
  const [settings, setSettings] = useState({});
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [notifications, setNotificationsCounter] = useState([]);
  const [site, setSite] = useState({});
  const [sites, setSites] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [equations, setEquations] = useState([]);
  const [dateRange, setDateRange] = useState('7D');
  const [DCF, setDCF] = useState([]);

  async function readSession() {
    const user = await window.sessionStorage.getItem(
      `firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`
    );
    if (user) {
      setLoggedIn(true);
    }
  };
    
  useEffect(() => {
    readSession();
    auth.onAuthStateChanged(async firebaseUser => {
      setLoggedIn(!!firebaseUser);
      if (!!firebaseUser) {
        setUser({ userId: firebaseUser?.uid });
        const existingUser = await localStorage.getItem('user');
        setUser({ userId: firebaseUser?.uid, ...JSON.parse(existingUser) });
        
        if (!existingUser) {
          console.log('Store user');
          setUser({ userId: firebaseUser?.uid });
          await localStorage.setItem('user', JSON.stringify({ userId: user?.uid }));

          const settingsResponse = await callApi('settings');
          if (!settingsResponse?.error && settingsResponse?.status !== 'error') {
            setSettings(settingsResponse?.settings);
            await localStorage.setItem('settings', JSON.stringify(settingsResponse?.settings));
          } else {
            console.error('Error loading settings', settingsResponse?.error);
          }
        }
      } else {
        console.log('Remove user');
        await localStorage.clear();
        setUser({});
        setLoggedIn(false);
        await logout();
      }
    });

    const sensorsFetchInterval = setInterval(async () => {
      await loadSensors();
    }, 2 * 60 * 1000); // fetch every 2 minutes

    // Removing the timeout before unmounting the component
    return () => {
      sensorsFetchInterval && clearInterval(sensorsFetchInterval);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    async function checkBrowserNotifications() {
      setBrowserNotificationEnabled(await requestFirebaseNotificationPermission());
    }

    checkBrowserNotifications();
    let notificationsFetchInterval;
    if (!isBrowserNotificationEnabled) {
      notificationsFetchInterval = setInterval(async () => {
        await loadNotifications();
      }, 2 * 60 * 1000); // check every 2 minutes
    }

    // Removing the timeout before unmounting the component
    return () => {
      notificationsFetchInterval && clearInterval(notificationsFetchInterval);
    };
  }, [isBrowserNotificationEnabled]);

  useEffect(() => {
		async function loadUser() {
		  try {
        if (user?.userId) {
          const response = await callApi('user', { userId: user?.userId });
    
          if (!response?.error && response?.status !== 'error') {
            setUser({ ...response?.user, userId: user?.userId });
            const userData = { ...response?.user, userId: user?.userId };
            await localStorage.setItem('user', JSON.stringify(userData));
          } else {
            console.error('Error loading user data', response?.error);
          }
        }
		  } catch (err) {
			  console.error('Error while loading user data', err);
		  }
    }
    
    async function loadSites() {
		  try {
        const existingSites = await localStorage.getItem('sites');
        if (!existingSites) {
          if (user?.userId) {
            const locationsResponse = await callApi('locations');
            if (!locationsResponse?.error && locationsResponse?.status !== 'error') {
              const locations = locationsResponse?.locations;
              setSites(locations);
              await localStorage.setItem('sites', JSON.stringify(locations));
            } else {
              console.error('Error loading locations', locationsResponse?.error);
            }
          }
        } else {
          setSites(JSON.parse(existingSites));
        }
		  } catch (err) {
			  console.error('Error while loading sites data', err);
		  }
    }

    async function loadUsers() {
      try {
        if (user?.userId) {
          const responseUsers = await callApi('users');

          if (!responseUsers?.error && responseUsers?.status !== 'error') {
            setUsers(responseUsers?.users);
            await localStorage.setItem('users', JSON.stringify(responseUsers?.users));
          } else {
            console.error('Error loading users data', responseUsers?.error);
          }
        }
      } catch (err) {
        console.error('Error while loading users data', err);
      }
    }
		
    loadUser();
    loadSites();
    user?.userId && loadSensors();
    user?.userId && loadEquations();
    user?.userId && loadUsers();
    user?.userId && getDCFScore();
	}, [user?.userId]); // eslint-disable-line

  useEffect(() => {
    user?.userId && loadSensors();
    user?.userId && loadEquations();
    user?.userId && getDCFScore();
	}, [site?.id]); // eslint-disable-line

  onMessageListener()
    .then(payload => 
      setNotificationsCounter(notifications => [...notifications, payload?.data?.body]))
    .catch((err) => console.log(JSON.stringify(err)));


  const loadNotifications = async () => {
    try {
      const response = await callApi('notifications', { count: 20 });
      if (!response?.error && response?.status !== 'error') {
        const now = new Date();
        const newNotifications = response?.data?.filter(entry => 
          entry?.timestamp >= new Date(now.getTime() - 2 * 60 * 1000)
        );
        setNotificationsCounter(newNotifications || []);
      } else {
        console.error('Error loading notifications data', response?.error);
      }
    } catch (err) {
      console.error('Error while loading notifications data', err);
    }
  }

  async function loadSensors() {
    try {
      let existingUser = await localStorage.getItem('user');
      existingUser = existingUser && JSON.parse(existingUser);
      if (user?.userId || existingUser?.userId) {
        const sensorsResponse = await callApi('sensors');
        if (!sensorsResponse?.error && sensorsResponse?.status !== 'error') {
          const sensors = sensorsResponse?.sensors;
          setSensors(sensors);
          return sensors;
        } else {
          console.error('Error loading sensors', sensorsResponse?.error);
        }
      }
    } catch (err) {
      console.error('Error while loading equipment data', err);
    }
  }

	async function getDCFScore() {
		try {
			const sensorsArr = await loadSensors();
			const timestamp = subDays(new Date(), 30);
			const newDateRange = Math.round(getTime(timestamp) / 1000);

			const promises =
				sensorsArr?.length &&
				sensorsArr?.map(async sensor => {
					const promise = await callApi('data', {
						sensorId: sensor?.EquipmentUniqueIdentifier,
						dateRange: newDateRange
					});
					return { data: promise, ...sensor };
				});
			const sensorsData = await Promise.allSettled(promises);

			const DCFPromises = sensorsData?.map(async sensorData => {
				const DCF = await getDataConfidenceScore(sensorData.value?.data?.annotations);
				return { ...(DCF && { DCF: DCF }), ...sensorData?.value };
			});

			let DCF_Arr = await Promise.all(DCFPromises);
			setDCF(DCF_Arr);
		} catch (err) {
			console.error('Error while calculating data confidence score', err);
		}
	}

  async function loadEquations() {
    try {
      const equationsResponse = await callApi('equations');
      if (!equationsResponse?.error && equationsResponse?.status !== 'error') {
        setEquations(equationsResponse?.equations);
      } else {
        console.error('Error loading equations', equationsResponse?.error);
      }
    } catch (err) {
      console.error('Error while loading equations data', err);
    }
  }


  return (
    <AppContext.Provider value={{ 
      isLoggedIn, setLoggedIn,
      user,
      site, setSite,
      sites, setSites,
      notificationsCounter: notifications?.length, 
      notifications, setNotificationsCounter,
      dateRange, setDateRange,
      settings, sensors,
      equations, loadEquations,
      users,
      DCF, setDCF
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default GlobalState;