import React from 'react';
import { Link } from 'react-router-dom';
import poweredByLogo from '../assets/powered-by-logo.svg';

const Footer = () => {
  return (
    <div className='footer-wrapper'>
      <Link to='/overview'>
        <img src={poweredByLogo} alt='Logo' className='powered-by-logo' />
      </Link>
    </div>
  );
};

export default Footer;
