import React, { useState } from 'react';
import { Popover } from 'antd';
import { useParams } from 'react-router';
import { SensorMapPopup, SensorMapPopupMolina } from '.';

const EquipmentPopup = ({ marker, markerImg }) => {
  const [visible, setVisible] = useState(false);
  const { siteId } = useParams();

  return (
    <Popover
      content={
        siteId === 'molina' 
          ? <SensorMapPopupMolina callback={visible => setVisible(visible)} sensorId={marker?.[1]} payload={marker} />
          : <SensorMapPopup callback={visible => setVisible(visible)} sensorId={marker?.[1]} />
      }
      placement='right'
      trigger={['click']}
      visible={visible}
      onVisibleChange={() => setVisible(visible => !visible)}
      getPopupContainer={trigger => trigger.parentElement}>
      <img
        src={markerImg}
        alt='marker'
        className={`marker ${marker?.[0]}`}
        onClick={() => setVisible(false)}
      />
    </Popover>
  );
};

export default EquipmentPopup;
