import axios from "axios";
import { auth } from "./firebase";
import config from "../config.json";

const callApi = async (url, payload) => {
  try {
    const siteId = window.location.pathname?.split('/')?.[1];

    const headers = {
      "Content-Type": "application/json"
    };

    const token = await auth?.currentUser?.getIdToken(true);
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    if (siteId) {
      headers.Site = siteId;
    }

    const response = await axios.post(`${config?.serverAPI}/${url}`, payload, { headers });
    return response?.data;
  } catch (error) {
    return { error };
  }
};

export default callApi;