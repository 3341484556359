import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../context/globalState';
import { Layout, Loading, DataSourcesTable, SiteHeader } from '../../components';

const DataSources = () => {
	let history = useHistory();
	const { siteId } = useParams();
	const { sensors, DCF } = useContext(AppContext);
	const [searchQuery, setSearchQuery] = useState('');
	const [filteredDataSources, setFilteredDataSources] = useState([]);

	useEffect(() => {
		const filteredSources = DCF?.filter(
			source =>
				source?.EquipmentUniqueIdentifier !== 'Calculations' &&
				source?.EquipmentUniqueIdentifier !== 'Salida_Digestato_Liquido'
		);
		setFilteredDataSources(
			filteredSources?.filter(source =>
					source?.EquipmentUniqueIdentifier?.toLowerCase().includes(
						searchQuery.toLowerCase()
					)
				)
		);
	}, [searchQuery, sensors]); // eslint-disable-line

	const callback = sensor => {
		history.push(`/${siteId}/sensors/${sensor.EquipmentUniqueIdentifier}`, sensor);
	};

	return (
		<Layout>
			<SiteHeader search callback={value => setSearchQuery(value)} />
			<div className='site-sub-header-wrapper'>
				<h3> Data Sources </h3>
			</div>
			{!DCF?.length ? (
				<Loading />
			) : (
				<div className='site-info'>
					<div className='transactions-tab-wrapper ant-table-hover'>
						<DataSourcesTable data={filteredDataSources} callback={callback} />
					</div>
				</div>
			)}
		</Layout>
	);
};

export default DataSources;
