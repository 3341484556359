import React from 'react';
import { Card, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { SimulatedAreaAvgChart } from '.';

const Option1 = () => {};

const Option2 = () => {};

const handleMenuClick = e => {
  switch (e.key) {
    case 'option1':
      Option1();
      break;
    case 'option2':
      Option2();
      break;

    default:
      break;
  }
};

const menu = (
  <Menu className='documentation-menu' onClick={handleMenuClick}>
    <Menu.Item key='option1'>Option1</Menu.Item>
    <Menu.Item key='option2'>Option2</Menu.Item>
  </Menu>
);

const CustomDashCard2 = ({ title, sensor, color }) => {
  return (
    <Card bordered={false} className='site-info-card-2'>
      <div className='ant-card-title-wrapper-dashboard'>
        <h3 className='ant-card-title'>{title}</h3>
        <Dropdown overlay={menu}>
          <EllipsisOutlined className='ellipsis-icon' />
        </Dropdown>
      </div>
      <div className='chart-body-data'>
        <h1>{sensor?.value}</h1>
      </div>
      <SimulatedAreaAvgChart color={color} data={sensor} />
    </Card>
  );
};

export default CustomDashCard2;
