import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import { Footer, Sidebar } from '.';

const Layout = ({ children, match, site }) => {
  ReactGA.pageview(match.url);

  return (
    <div className='page-wrapper'>
      {site !== null ? <Sidebar match={match} site={site} /> : null}
      <div className='main-section'>
        <div className='content'>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default withRouter(Layout);
