import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Table, Space, Alert, Button } from 'antd';

const AnalyticsTable = ({ equations, callback }) => {
  const { siteId } = useParams();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedRecordsAll, setSelectedRecordsAll] = useState(false);

  const analyticsTableColumns = [
    {
      key: 'name',
      ellipsis: true,
      render: record => record?.name
    },
    {
      key: 'x',
      render: index => (
        <div>
          {selectedRecords &&
          selectedRecords.length &&
          selectedRecords.some(record => record?.name === index?.name) ? (
            <div className='nonanimating-button'>
              <Button onClick={() => callback(index)} className='edit-btn'>
                <Space size='small'>
                  <span className='analytics-table-actions-selected'>Edit</span>
                  <span className='arrow arrow-selected right right-outlined-icon'></span>
                </Space>
              </Button>
            </div>
          ) : (
            <div className='nonanimating-button'>
              <Button onClick={() => callback(index)} className='edit-btn'>
                <Space size='small'>
                  <span className='analytics-table-actions'>Set up parameters</span>
                  <span className='arrow right right-outlined-icon'></span>
                </Space>
              </Button>
            </div>
          )}
        </div>
      )
    }
  ];

  const selectRow = record => {
    const selectedRowKeys = selectedRecords;
    if (selectedRowKeys && selectedRowKeys.length) {
      if (selectedRowKeys.indexOf(record.name) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.name), 1);
      } else {
        selectedRowKeys.push(record.name);
      }
    }
    setSelectedRecords(selectedRowKeys);
  };

  const rowSelection = {
    hideSelectAll: true,
    checkStrictly: true,
    columnWidth: '70px',
    getCheckboxProps: record => ({
      name: record?.name
    }),
    onSelect: (record, selected, selectedRows) => {
      setSelectedRecords(selectedRows);
      selectedRows.length === equations.length ? setSelectedRecordsAll(true) : setSelectedRecordsAll(false);
    }
  };

  return (
    <React.Fragment>
      {!selectedRecordsAll ? (
        <Alert
          description='Set up monitoring parameters to activate Analytics'
          type='info'
          showIcon
          className='ant-alert-info'
        />
      ) : null}
      <br />
      <Table
        className='ant-table-analytics'
        rowSelection={rowSelection}
        onRow={record => ({
          onClick: () => {
            selectRow(record);
          }
        })}
        columns={analyticsTableColumns}
        dataSource={equations}
        rowKey={item => item?.name}
        pagination={{ hideOnSinglePage: true }}
        footer={() => (
          <div className='ant-table-footer-wrapper'>
            <Link to={`/${siteId}/analytics`}>
              <Button disabled={!selectedRecordsAll} className='primary-btn-lg'>
                Activate Analytics
              </Button>
            </Link>
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default AnalyticsTable;
