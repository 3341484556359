import React, { useEffect, useState } from 'react';
import ImageMap from 'image-map';
import { LeftOutlined } from '@ant-design/icons';
import { EquipmentPopup } from '..';
import VirtualTour from './VirtualTour';

import markerImg from '../../assets/Molina/3d/marker.svg';
import sensorCounterImg from '../../assets/Molina/3d/sensorCounter.png';
import rotateLeft from '../../assets/Molina/3d/arrow-left.svg';
import rotateRight from '../../assets/Molina/3d/arrow-right.svg';

// 0
import image0 from '../../assets/Molina/3d/0/V1_Default.jpg';
import image0SolidWaste from '../../assets/Molina/3d/0/V1_1_Overlay.jpg';
import image0LiquidWaste from '../../assets/Molina/3d/0/V1_2_Overlay.jpg';
import image0AgriculturalWaste from '../../assets/Molina/3d/0/V1_3_Overlay.jpg';

// 90
import image90 from '../../assets/Molina/3d/90/V2_Default.jpg';
import image90PressureSensorRight from '../../assets/Molina/3d/90/V2_1_Overlay.jpg';
import image90PressureSensorLeft from '../../assets/Molina/3d/90/V2_2_Overlay.jpg';
import image90FlowSensorLeft from '../../assets/Molina/3d/90/V2_3_Overlay.jpg';
import image90FlowSensorRight from '../../assets/Molina/3d/90/V2_4_Overlay.jpg';

// 180
import image180 from '../../assets/Molina/3d/180/V3_Default.jpg';
import image180SolidWaste from '../../assets/Molina/3d/180/V3_3_Overlay.jpg';
import image180LiquidWaste from '../../assets/Molina/3d/180/V3_2_Overlay.jpg';
import image180AgriculturalWaste from '../../assets/Molina/3d/180/V3_1_Overlay.jpg';
import image180PressureSensor from '../../assets/Molina/3d/180/V3_4_Overlay.jpg';

// Zoomed 0
import image0AgriculturalWasteZoom from '../../assets/Molina/3d/0/zoom/Z1_1_Overlay.jpg';
import image0LiquidWasteZoom from '../../assets/Molina/3d/0/zoom/Z1_2_Overlay.jpg';
import image0SolidWasteZoom from '../../assets/Molina/3d/0/zoom/Z1_3_Overlay.jpg';

// Zoomed 90
import image90FlowZoom from '../../assets/Molina/3d/90/zoom/Z2_1_Overlay.jpg';
import image90PressureZoom from '../../assets/Molina/3d/90/zoom/Z2_2_Overlay.jpg';


const images = {
  0: {
    Default: image0,
    AgriculturalWaste: image0AgriculturalWaste,
    LiquidWaste: image0LiquidWaste,
    SolidWaste: image0SolidWaste,
    zoom: {
      AgriculturalWaste: image0AgriculturalWasteZoom,
      LiquidWaste: image0LiquidWasteZoom,
      SolidWaste: image0SolidWasteZoom,
    }
  },
  90: {
    Default: image90,
    PressureSensorLeft: image90PressureSensorLeft,
    FlowSensorLeft: image90FlowSensorLeft,
    PressureSensorRight: image90PressureSensorRight,
    FlowSensorRight: image90FlowSensorRight,
    zoom: {
      PressureSensor: image90PressureZoom,
      FlowSensor: image90FlowZoom,
    }
  },
  180: {
    Default: image180,
    AgriculturalWaste: image180AgriculturalWaste,
    LiquidWaste: image180LiquidWaste,
    SolidWaste: image180SolidWaste,
    PressureSensor: image180PressureSensor,
    FlowSensor: image180,
    zoom: {
      AgriculturalWaste: image0AgriculturalWasteZoom,
      LiquidWaste: image0LiquidWasteZoom,
      SolidWaste: image0SolidWasteZoom,
      PressureSensor: image90PressureZoom,
      FlowSensor: image90FlowZoom,
    }
  }
}

const SensorMap3D = () => {
  const getImageMap = () => {
    if (isZoomed) return null;

    // https://www.image-map.net/
    switch (angle) {
      case 0:
        return (
          <map name="location-map-0">
            <area 
              alt="Agricultural Waste Depot, 0 degree view" 
              title="Agricultural Waste Depot" 
              data-coords="129,229,128,152,455,151,498,121,514,120,554,148,687,155,686,224,444,239,439,212,425,211,418,176,333,173,346,209,285,217,288,246,276,249"
              shape="poly" 
              onClick={() => changeImageZoom('AgriculturalWaste', 'Agricultural Waste')}
              onMouseEnter={() => changeImage('AgriculturalWaste')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Liquid Waste Depot, 0 degree view" 
              title="Liquid Waste Depot" 
              data-coords="209,295,349,298,474,263,441,260,440,227,431,227,421,193,337,192,346,227,315,232,288,234,289,275"
              shape="poly" 
              onClick={() => changeImageZoom('LiquidWaste', 'Liquid Waste')}
              onMouseEnter={() => changeImage('LiquidWaste')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Solid Waste Depot, 0 degree view" 
              title="Solid Waste Depot" 
              data-coords="675,272,676,334,864,369,949,348,950,284,763,258"
              shape="poly" 
              onClick={() => changeImageZoom('SolidWaste', 'Solid Waste')}
              onMouseEnter={() => changeImage('SolidWaste')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      case 90: 
        return (
          <map name="location-map-90">
            <area 
              alt="Pressure Sensor Right" 
              title="Pressure Sensors" 
              data-coords="786,103,834,103,833,171,785,171"
              shape="poly" 
              onClick={() => changeImageZoom('PressureSensor', 'Pressure Sensors')}
              onMouseEnter={() => changeImage('PressureSensorRight')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Pressure Sensor Left" 
              title="Pressure Sensors" 
              data-coords="599,107,629,108,637,178,598,177"
              shape="poly" 
              onClick={() => changeImageZoom('PressureSensor', 'Pressure Sensors')}
              onMouseEnter={() => changeImage('PressureSensorLeft')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flow Sensor Right" 
              title="Flow Sensors" 
              data-coords="756,485,786,485,787,526,752,529"
              shape="poly" 
              onClick={() => changeImageZoom('FlowSensor', 'Flow Sensors')}
              onMouseEnter={() => changeImage('FlowSensorRight')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flow Sensor Left" 
              title="Flow Sensors" 
              data-coords="626,490,667,491,668,536,632,537"
              shape="poly" 
              onClick={() => changeImageZoom('FlowSensor', 'Flow Sensors')}
              onMouseEnter={() => changeImage('FlowSensorLeft')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      case 180: 
        return (
          <map name="location-map-180">
            <area 
              alt="Agricultural Waste Depot, 0 degree view" 
              title="Agricultural Waste Depot" 
              data-coords="1184,191,1241,186,1256,115,1417,108,1417,261"
              shape="poly" 
              onClick={() => changeImageZoom('AgriculturalWaste', 'Agricultural Waste')}
              onMouseEnter={() => changeImage('AgriculturalWaste')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Liquid Waste Depot, 0 degree view" 
              title="Liquid Waste Depot" 
              data-coords="846,167,870,168,872,152,890,154,886,142,921,130,938,162,950,162,951,187,959,188,960,179,970,186,989,199,992,212,973,219,946,222,844,207"
              shape="poly" 
              onClick={() => changeImageZoom('LiquidWaste', 'Liquid Waste')}
              onMouseEnter={() => changeImage('LiquidWaste')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Solid Waste Depot, 0 degree view" 
              title="Solid Waste Depot" 
              data-coords="430,209,448,208,451,237,433,238"
              shape="poly" 
              onClick={() => changeImageZoom('SolidWaste', 'Solid Waste')}
              onMouseEnter={() => changeImage('SolidWaste')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Pressure Sensors" 
              title="Pressure Sensors" 
              data-coords="408,149,421,148,423,168,410,171"
              shape="poly" 
              onClick={() => changeImageZoom('PressureSensor', 'Pressure Sensors')}
              onMouseEnter={() => changeImage('PressureSensor')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flow Sensors" 
              title="Flow Sensors" 
              data-coords="423,290,442,290,441,305,425,305"
              shape="poly" 
              onClick={() => changeImageZoom('FlowSensor', 'Flow Sensors')}
              onMouseEnter={() => changeImage('FlowSensor')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      default:
        return null;
    }
  }

  const sensorHintPosition = device => {
    const viewAngle = angle === 180 ? 180 : 0;
    if (device !== 'Default') {
      switch (device) {
        case 'AgriculturalWaste':
          setMarkers([
            [`${device.toLowerCase()}`, 'Grape_Harvest', 'Agricultural Waste'], 
            null, null
          ]);
          break;
        case 'SolidWaste':
          setMarkers([
            [`${device.toLowerCase()}`, 'Feedstock', 'Solid Waste'], 
            null, null
          ]);
          break;
        case 'LiquidWaste':
          setMarkers([
            [`${device.toLowerCase()}`, 'Feedstock', 'Liquid Waste'], 
            null, null
          ]);
          break;
        case 'FlowSensor':
          setMarkers([
            [`${device.toLowerCase()}`, 'FIQ_4_3.Totalizador'], 
            [`${device.toLowerCase()}-2`, 'FIQ_4_4.Totalizador'], 
            null
          ]);
          break;
        case 'PressureSensor':
          setMarkers([
            [`${device.toLowerCase()}`, 'PIT_4_1.Presion_Simulada'], 
            [`${device.toLowerCase()}-2`, 'PIT_4_2.Presion_Simulada'], 
            null
          ]);
          break;
        default:
          setMarkers([null, null, null]);
          break;
      }
    }
  }

  const sensorCountPosition = () => {
    if (!isZoomed) {
      switch (angle) {
        case 180:
          setSensorCounters([
            'agricultural_180', 'liquid_180', 'solid_180', 'pressure_right_180', 'flow_right_180'
          ]);
          break;  
        case 90:
          setSensorCounters([
            'pressure_right_90', 'pressure_left_90', 'flow_right_90', 'flow_left_90'
          ]);
          break;  
        case 90:
        default:
          setSensorCounters([
            'agricultural_0', 'liquid_0', 'solid_0'
          ]);
          break;
      }
    }
  }

  const [isZoomed, setZoom] = useState(false);
  const [angle, setAngle] = useState(0);
  const [image, setImage] = useState(image0);
  const [imageMap, setImageMap] = useState(getImageMap());
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState('Site plan');
  const [markers, setMarkers] = useState([null, null, null]);
  const [sensorCounters, setSensorCounters] = useState([]);

  ImageMap('img[usemap]', 500);

  useEffect(() => {
    setTimeout(() => ImageMap('img[usemap]'), 1000);
  }, []);

  useEffect(() => {
    setImage(getImageByDevice(selected || 'Default', isZoomed));
    setImageMap(getImageMap());
    if (isZoomed) {
      sensorHintPosition(selected);
    } else {
      sensorCountPosition();
    }
  }, [angle, isZoomed, selected]); // eslint-disable-line

  const getImageByDevice = (device, zoomed = false) => {
    if (zoomed) {
      if (angle === 180) {
        return images[180]['zoom'][device];
      } else {
        return images[angle]['zoom'][device];
      }
    } else {
      return images[angle][device];
    }
  }

  const changeImage = (device = 'Default') => {
    if (!isZoomed) {
      setImage(getImageByDevice(device));
    }
  }

  const changeImageZoom = async (device = 'Default', title = 'Default') => {
    setZoom(true);
    setSelected(device);
    setImage(getImageByDevice(device, true));
    sensorHintPosition(device);

    if (device !== 'Default') {
      setTitle(title);
    }
  }

  const onRotateLeft = () => {
    if (!isZoomed) {
      switch (angle) {
        case 180:
          setAngle(0);
          break;  
        default:
          setAngle(angle => angle + 90);
          break;
      }
    } else {
      setAngle(angle => angle === 0 ? 0 : 90);
    }
  }

  const onRotateRight = () => {
    if (!isZoomed) {
      switch (angle) {
        case 0:
          setAngle(180);
          break; 
        default:
          setAngle(angle => angle - 90);
          break;
      }
    } else {
      setAngle(angle => angle === 0 ? 0 : 90);
    }
  }
  const onZoomOut = () => {
    setZoom(false);
    setSelected(null);
    setTitle('Site plan');
    setMarkers([null, null, null]);
    sensorCountPosition();
  }

  return (
    <div className='image-3d-wrapper'>
      {
        isZoomed ? (
          <div className='zoom-section-wrapper'>
            <div className='zoom-button-wrapper' onClick={onZoomOut}>
              <span>
                <LeftOutlined className='back-arrow' />
                Back
              </span>
            </div>
            <div className='title-wrapper'>
              <h3>{title}</h3>
            </div>
            <div className={`rotate-section-wrapper ${!isZoomed ? 'zoomed-out' : ''}`} />
          </div>
        ) : (
          <div className='zoom-section-wrapper'>
            <div className='title-wrapper'>
              <h3>{title}</h3>
            </div>
            <VirtualTour />
            <div className={`rotate-section-wrapper ${!isZoomed ? 'zoomed-out' : ''}`}>
              <div className='rotate-button-wrapper'>
                <div className='map-rotate-btn' onClick={onRotateLeft}>
                  <img src={rotateLeft} alt="rotate left" />
                </div>
                <div className='divider' />
                <div className='map-rotate-btn' onClick={onRotateRight}>
                  <img src={rotateRight} alt="rotate right" />
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div className='image-3d-inner'>
        <img src={image} alt='location-map' className='main-image' useMap={`#location-map-${angle}`} />

        {
          !isZoomed && sensorCounters?.map(sensorCounter => 
            <img
              key={`${sensorCounter}-${sensorCounterImg}`}
              src={sensorCounterImg}
              alt={sensorCounter}
              className={`sensorCounter ${sensorCounter}`}
            />
          )
        }
        {imageMap}

        {markers?.map(marker => {
          return marker?.length 
            ? <EquipmentPopup key={marker?.[0]} marker={marker} markerImg={markerImg} /> 
            : null;
        })}
      </div>
    </div>
  );
};

export default SensorMap3D;
