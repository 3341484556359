import { useParams } from 'react-router';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Form, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AppContext } from '../context/globalState';
import { getAverage } from '../utils/chartHelpers';
import {
  Loading,
  Layout,
  SiteHeader,
  CustomParameter,
  InfoCardArea,
  CH4DestPRImg,
  CH4DestiImg,
  EProjectImg,
  ESSRB6Img,
  ESSRP5Img,
  ESSRP8Img,
  QiImg,
} from '../components';

const AnalyticsExpanded = () => {
  const images = {
    'Total Methane emissions destroyed': CH4DestPRImg,
    'Net landfill Methane emissions destroyed by each eligible destruction device': CH4DestiImg,
    'Total Project GHG emissions': EProjectImg,
    'Baseline scenario GHG emissions': ESSRB6Img,
    'GHG emissions from electricity generation and delivery': ESSRP5Img,
    'GHG emissions from supplemental fossil fuel combustion, other than natural gas': ESSRP8Img,
    'Total quantity of Methane sent to each eligible device': QiImg
  };

  let history = useHistory();
  const { equationId } = useParams();
  const [equation, setEquation] = useState();
  const [loading, setLoading] = useState(false);
  const [currentParameter, setCurrentParameter] = useState([]);
  const { equations } = useContext(AppContext);
  const [unit, setUnit] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [dateTimestamp, setDateTimestamp] = useState([]);
  const [simulated, setSimulated] = useState(false);
  const [dataset, setDataset] = useState('');
  const EqName = history.location.state.EqName;
  const equationImg = images[EqName];

  useEffect(() => {
    async function loadEquation() {
      try {
        setLoading(true);
        const equation = equations.find(eq => eq.name === EqName);
        const items = Object.entries(equation?.current);
        setCurrentParameter(items);
        setEquation(equation);
        setLoading(false);
      } catch (err) {
        console.error('Error while loading equation data', err);
      }
      setUnit(history.location.state.unit);
      setData(history.location.state.data);
      setData2(history.location.state.data2);
      setDateTimestamp(history.location.state.dateTimestamp);
      setSimulated(history.location.state.simulated);
      setDataset(history.location.state.dataset);
    }

    equations?.length && loadEquation();
  }, [equations?.length]); // eslint-disable-line

  return (
    <Layout>
      <React.Fragment>
        <SiteHeader />
        <div className='site-info'>
          <div className='sub-header-wrapper'>
            <div className='notification-wrapper'>
              <Space size='large' direction='horizontal'>
                <button onClick={() => history.goBack()} className='back-btn-2'>
                  <ArrowLeftOutlined className="icon-arrow-bck" />
                </button>
                <h3 className='notification-wrapper'> {equation?.name} </h3>
              </Space>
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              <Row gutter={16}>
                <Col span={24}>
                  <InfoCardArea
                    title={equationId}
                    data={data}
                    data2={data2}
                    dateTimestamp={dateTimestamp}
                    unit={unit}
                    value={getAverage(data, dataset)}
                    color='blue'
                    extra={false}
                    simulated={simulated}
                    dataset={dataset}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Card bordered={false} className='analytics-parameters-card'>
                    <img className='parameters-image' alt='parameters' src={equationImg} />
                  </Card>
                </Col>
              </Row>
              <Form layout='inline' scrollToFirstError colon={false} name='analytics-form' hideRequiredMark>
                <div className='parameters-form'>
                  {currentParameter.map((item, i) => (
                    <CustomParameter key={i} disabledAll parameter={{ name: item[0], data: item[1] }} />
                  ))}
                </div>
              </Form>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default AnalyticsExpanded;
