import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { AppContext } from '../context/globalState';
import logo from '../assets/dmrv-logo.svg';
import {
  DashboardIcon,
  AnalyticsIcon,
  SensorsIcon,
  ActivityIcon,
  DocumentationIcon,
  UsersIcon,
  PinIcon,
  MenuIcon,
  ArchitectureIcon
} from '.';

const { SubMenu } = Menu;

const Sidebar = () => {
  let history = useHistory();

  const { siteId } = useParams();
  const [currentSite, setCurrentSite] = useState();
  const [selectedMenu, setSelectedMenu] = useState(history.location.pathname.split('/').pop(-1));
  const { sites, site, setSite } = useContext(AppContext);

  const handleClick = e => {
    setSelectedMenu(e.key);
  };

  useEffect(() => {
    async function loadSite() {
      try {
        let existingSite = await localStorage.getItem('site');
        if (existingSite?.id !== siteId && siteId !== undefined) {
          existingSite = sites?.find(({ id }) => id === selectedMenu || id === siteId);
          existingSite && setSite(existingSite);
          await localStorage.setItem('site', JSON.stringify(existingSite));
        } else {
          setSite(JSON.parse(existingSite));
        }
      } catch (err) {
        console.error('Error while loading site data', err);
      }
    }

    loadSite();
    setCurrentSite(siteId || site?.id);
  }, [siteId, site?.id, selectedMenu]); // eslint-disable-line

  return (
    <div className='site-layout-page-wrapper'>
      <div className='logo-wrapper'>
        <Link to='/'>
          <img src={logo} alt='Logo' className='site-header-logo' />
        </Link>
      </div>
      <Menu inlineIndent={18} onClick={handleClick} selectedKeys={[selectedMenu]} mode='inline'>
        <SubMenu 
          title={<span className='cut-text-submenu'>{site?.name}</span>} 
          key="submenu" 
          onClick={handleClick} 
          className='submenu'
          >
          {sites.map(site => (
            <Menu.Item key={site.id} icon={<PinIcon />}>
              <Link to={`/${site?.id}/dashboard`}>
                <span className='sidebar-items'>
                  <span className='cut-text-xlg'>{site?.name}</span>
                </span>
              </Link>
            </Menu.Item>
          ))}
          <Menu.Divider className='sidebar-divider' />
          <Menu.Item key='all-sites' icon={<MenuIcon />}>
            <Link to={'/'}>
              <span className='sidebar-items'>
                <span className='cut-text-xlg'>Sites</span>
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key='dashboard' icon={<DashboardIcon />}>
          <Link to={`/${currentSite}/dashboard`}>Dashboard</Link>
        </Menu.Item>
        <Menu.Item key='analytics' icon={<AnalyticsIcon />}>
          <Link to={`/${currentSite}/analytics`}>Analytics</Link>
        </Menu.Item>
        {currentSite === 'molina' ? (
          <Menu.Item key='data-sources' icon={<SensorsIcon />}>
						<Link to={`/${currentSite}/data-sources`}>Data Sources</Link>
					</Menu.Item>
				) : (
					<Menu.Item key='sensors' icon={<SensorsIcon />}>
						<Link to={`/${currentSite}/sensors`}>Sensors</Link>
					</Menu.Item>
				)}
        <Menu.Item key='activity' icon={<ActivityIcon />}>
          <Link to={`/${currentSite}/activity`}>Activity</Link>
        </Menu.Item>
        <Menu.Item key='documentation' icon={<DocumentationIcon />}>
          <Link to={`/${currentSite}/documentation`}>Documentation</Link>
        </Menu.Item>
        {currentSite === 'molina' && 
          <Menu.Item key='architecture' icon={<ArchitectureIcon />}>
            <Link to={`/${currentSite}/architecture`}>Solution Architecture</Link>
          </Menu.Item>
        }
        <Menu.Item key='users' icon={<UsersIcon />}>
          <Link to='/users'>Users</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;
