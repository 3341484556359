import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import Chart from 'react-apexcharts';
import { Loading } from '..';
import { loadChartData, getSeriesArr } from '../../utils/chartHelpers';

const AreaChart = ({
	data,
	data2,
	dataTimestamp,
	title,
	color,
	yaxis,
	simulated,
	dataset
}) => {
	const [series, setSeries] = useState([]);
	const [colorArr, setColorArr] = useState([]);
	const [categoriesArr, setCategoriesArr] = useState([]);
	const [loading, setLoading] = useState(true);
	const { siteId } = useParams();

	let mainColor = '#14c6bb';
	const simColor = '#fb8f67';
	let yaxisShow = '';

	if (color === 'blue') {
		mainColor = '#5f79ff';
	} else {
		mainColor = '#14c6bb';
	}

	if (yaxis === true) {
		yaxisShow = true;
	} else {
		yaxisShow = false;
	}

	useEffect(() => {
		async function loadData() {
			let seriesArr = data[0]?.value && data?.map(record => record?.value);
			const modifiedData = data?.flatMap(i => [i,i]); // Fix for when a connecting chart has one data point (needs min. of two)
			if (simulated) {
				const valuesArr = await loadChartData(modifiedData, dataset, mainColor, simColor, siteId);
				seriesArr = await getSeriesArr(
					valuesArr[0],
					valuesArr[1],
					simColor,
					dataset
				);
				setColorArr(valuesArr[1]);
				setSeries(seriesArr);
			} else {
				data2
					? setSeries([
						{
							name: title,
							data: data,
							type: 'area'
						},
						{
							name: 'Baseline',
							data: data2,
							type: 'line'
						}
					])
					: setSeries([
						{
							name: title,
							data: seriesArr ?? data,
							type: 'area'
						}
					]);
			}
			if (siteId === 'molina') {
				const categories = dataTimestamp && dataTimestamp?.map(record => {
					const [day, month, year] = record?.split('/');
					const date = new Date(+year, month - 1, +day);
					return date.getTime();
				})
				setCategoriesArr(categories);
			} else {
				setCategoriesArr(dataTimestamp);
			}
		}
		setLoading(false);

		loadData();
	}, [data, data2, dataTimestamp]); // eslint-disable-line

	const options = {
		chart: {
			height: 300,
			sparkline: {
				enabled: false
			},
			toolbar: {
				show: false
			}
		},
		dataLabels: {
			enabled: false
		},
		...(simulated && { colors: colorArr }),
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			width: 2.3,
			colors: [mainColor, '#14c6bb'],
			...(simulated && { colors: colorArr }),
			...(!simulated && { dashArray: [0, 6] })
		},
		...(!simulated && {
			fill: {
				type: ['gradient', 'solid'],
				colors: [mainColor, '#14c6bb'],
				opacity: [0.02, 1],
				gradient: {
					shade: 'light',
					type: 'vertical',
					shadeIntensity: 0.4,
					opacityFrom: 0.3,
					opacityTo: 0.1,
					stops: [0, 100]
				}
			}
		}),
    ...(simulated && {
			fill: {
				colors: colorArr,
				opacity: [0.1],
				gradient: {
					shade: 'light',
					type: 'vertical',
					shadeIntensity: 0.4,
					opacityFrom: 0.3,
					opacityTo: 0.1,
					stops: [0, 100]
				}
			}
		}),
		yaxis: {
			forceNiceScale: true,
			floating: true,
			labels: {
				show: false,
				style: {
					colors: '#7b94ba',
					fontWeight: 500,
          offsetX: -15,
          minWidth: 40,
				},
				offsetX: 5
			}
		},
		xaxis: {
			type: 'datetime',
			categories: categoriesArr,
			labels: {
				show: true,
				style: {
					colors: '#7b94ba',
					fontWeight: 500
				}
			}
		},
		grid: {
			show: yaxisShow,
			borderColor: '#eaf1ff',
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: yaxisShow
				}
			},
			padding: {
				top: 0,
				right: 20,
				bottom: 20,
				left: 20
			}
		},
		tooltip: {
			fillSeriesColor: false,
			x: {
				format: 'dddd, d MMMM yyyy'
			},
			y: {
				title: {
					formatter: seriesName => seriesName
				}
			},
			marker: {
				...(!simulated && { fillColors: [mainColor, '#14c6bb'] })
			}
		},
		markers: {
			colors: [mainColor, '#14c6bb'],
			...(simulated && { colors: colorArr })
		},
		legend: {
			show: false,
			position: 'top',
			horizontalAlign: 'right'
		}
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : (
				<div className='chart-body-no-padding'>
					<Chart
						height='100%'
						width='100%'
						options={options}
						series={series}
						type='area'
					/>
				</div>
			)}
		</Fragment>
	);
};

export default AreaChart;
