/* eslint-disable */
import { dailyAverage } from './chartHelpers';

// Wastewater (liquid industrial waste) of the given stream
const equation5 = async (feedstockData, CODLabSheet) => {
	// Methane producing capacity of the wastewater stream ‘S’
	const B_OWW_S = 0.21; // Const val, CH4/tonnes COD

	// Methane conversion factor of the anaerobic treatment lagoon, pond, or tank
	const MCF_ATS = 0.03; // Const val

	// Global Warming Potential of Methane
	const GWP_CH4 = 28; // Const val, tonnes CO2e/tonnes CH4

	// Baseline uncertainty factor to account for model uncertainties
	const uncertaintyFactor = 0.89; // Const val

	// Volume of wastewater from stream ‘S’ in month i
	const Q_WW_S_i = feedstockData?.filter(
		e => e['Residuo'] == 'Lodo' || e['Residuo'] === 'Lodo Liquido'
	);

	const dailyFeedstock = await dailyAverage(Q_WW_S_i, 'Toneladas ', true);

	// Chemical oxygen demand of the untreated wastewater stream ‘S’ for month i
	const COD_WW_S_i = CODLabSheet;

	const result =
		dailyFeedstock?.[0] &&
		dailyFeedstock?.[0]?.length &&
		dailyFeedstock?.[0]?.map(record => ({
			value: (
				(record?.sum + COD_WW_S_i) *
				B_OWW_S *
				MCF_ATS *
				GWP_CH4 *
				uncertaintyFactor
			).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp
		}));

	const dataTimestamp = dailyFeedstock?.[1]?.length && dailyFeedstock?.[1];

	return [result, dataTimestamp];
};

// Methane emissions from solid waste disposal sites (using first order decay method)
const equation6 = async (feedstockData, feedstockTypes) => {
	// Model correction factor to account for model uncertainties for year y
	const ϕ_y = 0.85 || 0.8; // default value: 0.85 for humid/wet conditions or 0.80 for dry conditions

	// Fraction of methane captured at the SWDS and flared, combusted or used in another manner that prevents the emissions of methane to the atmosphere in year y
	// Región de Valparaíso: 0.27, Región Metropolitana: 0.26, Región de Los Lagos: 0.14, other: 0
	const f_y = 0.26 || 0.27 || 0.14 || 0;

	// Global Warming Potential of Methane
	const GWP_CH4 = 28; // default value, tonnes CO2e/tonnes CH4

	// Oxidation factor (reflecting the amount of methane from SWDS that is oxidized in the soil or other material covering the waste)
	const OX = 0.1; // default value

	// Fraction of methane in the SWDS gas (volume fraction)
	const F = 0.5; // default value

	// Fraction of degradable organic carbon (DOC) that decomposes under the specific conditions occurring in the SWDS for year y
	let DOC_f_y = feedstockTypes?.['DOC'];

	// Methane correction factor for year y
	const MCF_y = 1; // default value

	// Decay rate for the residue type j
	// Variable feedstock: 0.185, Grape Harvest: 0.1
	const K = 0.1 || 0.185;

	// Years in the time period in which residue is disposed at the landfill, extending from the first year in the time period to year y
	const x = 1; // default value

	// Year of the crediting period for which methane emissions are calculated (y represents year 10)
	const y = 10; // default value (the crediting period for the project is usually 10 years)

	const dailyFeedstock = await dailyAverage(feedstockData, 'Toneladas ', 1, true);

	const result =
		dailyFeedstock?.[0]?.length &&
		dailyFeedstock?.[0]?.map(record => {
			const COD_WW_S_i = 1; // (arbitrary test value)
			const type = feedstockTypes?.find(
				element =>
					element?.['Company'] === record?.['Empresa'] ||
					element?.['Company'].includes(record?.['Empresa'])
			);
			if (type?.['Type of Feedstock'] === 'Manure') {
				return {
					value: (0.21 * 0.03 * 28 * 0.89 * (record?.sum + COD_WW_S_i)).toFixed(2),
					simulated: record?.simulated,
					dataTimestamp: record?.dataTimestamp
				};
			} else {
				return {
					value: (
						Number(type?.['ϕ']) *
						(1 - Number(type?.f_y)) *
						GWP_CH4 *
						(1 - OX) *
						(16 / 12) *
						F *
						Number(type?.DOC) *
						MCF_y *
						record?.sum *
						Number(type?.DOC) *
						Math.E ** (-Number(type?.K) * y - x) *
						(1 - Math.E ** -Number(type?.K))
					).toFixed(2),
					simulated: record?.simulated,
					dataTimestamp: record?.dataTimestamp
				};
			}
		});

	const dataTimestamp = dailyFeedstock?.[1]?.length && dailyFeedstock?.[1];

	return [result, dataTimestamp];
};

// Emissions for the Reporting Period
const equation7 = async (eq8, eq9, eq10, calcData, eq12, eq15) => {
	const dailyBiogas = await dailyAverage(calcData, 'Biogás Generado (Nm3)', true);
	const dailyBiogasNoFlare = await dailyAverage(
		calcData,
		'Biogás Generado sin antorcha (Nm3)',
		1,
		true
	);
	const flareE =
		dailyBiogas?.[0]?.length &&
		dailyBiogasNoFlare?.[0]?.length &&
		dailyBiogas?.[0]?.map(
			(record, i) => record?.sum - (dailyBiogasNoFlare?.[0]?.[i]?.sum ?? 0)
		);

	const result =
		eq12?.length &&
		eq12?.map((record, i) => ({
			value: (
				Number(record?.value ?? 0) +
				Number(eq8?.[i]?.value ?? 0) +
				Number(eq9?.[i]?.value ?? 0) +
				Number(eq10?.[i]?.value ?? 0) +
				Number(flareE?.[i]?.value ?? 0) +
				Number(eq15?.[i]?.value ?? 0)
			).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp
		}));

	return result;
};

// Electricity Generation and Transmission
const equation8 = async () => {
	// Electricity Generation and Delivery emission factor (kg CO2e/MWh)
	const EF_elec = 0.4137;

	// Total electricity consumed by project activities during the reporting period (MWh)
	const EL_PR = 0; // Zero as electricity is basically zero consumption from the grid so zero emissions.

	const result = (EL_PR * EF_elec) / 1000;
	return result;
};

// Fossil Fuel Use for AD Project (yearly)
const equation9 = async () => {
	// Total Fossil Fuel consumed to operated biodigester during the reporting period, by fuel type i
	const FF_PR_i = 10200; // (L /year)

	// Emission factors for CO2
	const EF_i_CO2 = 69300; // (arbitrary test value)

	// Emission factors for N20
	const EF_i_N20 = 0.6; // (arbitrary test value)

	// Emission factors for CH4
	const EF_i_CH4 = 3; // (arbitrary test value)

	// Global Warming Potential of Nitrous oxide
	const GWP_N20 = 265; // (arbitrary test value)

	// Global Warming Potential of Methane
	const GWP_CH4 = 28; // Const val, tonnes CO2e/tonnes CH4

	const result = 23.533; // tonnes CO2e /year - given calculated value
	const dailyResult = result / 365;
	const dailyResultM3 = dailyResult * 2.8316846592; // tonnes to m^3

	return dailyResultM3.toFixed(4);
};

// Anaerobic Digestor
const equation10 = async (BDE, CH4) => {
	// Global Warming Potential of Methane
	const GWP_CH4 = 28; // Const val, tonnes CO2e/tonnes CH4

	// Total quantity of methane collected and metered
	const CH4_meter = ['CH4']; // example - (tonnes CH4 /month)

	// Methane collection efficiency of the Anaerobic Digester
	const AD = 0.98; // (constant value)

	// Monthly weighted methane destruction efficiency of the combustion device(s)
	const BDE_weighted = ['BDE']; // example

	// Monthly quantity of methane that is vented to the atmosphere due to AD system venting events
	const CH4_vent = 0; // (value of 0 as there are no venting events for the Project)

	const result =
		BDE[0]?.length &&
		CH4[0]?.length &&
		BDE[0]?.map((record, i) => ({
			value: (
				GWP_CH4 *
				(CH4?.[0]?.[i]?.value * (!isNaN(1 / (AD - record?.value)) ?? 0) + CH4_vent)
			).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp	
		}));

	const dataTimestamp = BDE?.[1]?.length && BDE?.[1];

	return [result, dataTimestamp];
};

// Quantity of Methane Collected and Metered
const equation11 = async calcData => {
	// Density of methane gas at STP [1 atm, 60°F]
	const methaneDensity = 0.0423; // lbs CH4/scf

	// Methane conversion factor
	const conversionFactor = 0.000454; // lbs to tonnes

	// Total monthly measured volumetric flow of biogas to all destruction devices
	const F_mo = 1; // (arbitrary test value)

	// Monthly measured methane concentration of the biogas.
	const CH4_conc_mo = 1; // (arbitrary test value)

	const daily_F_mo = await dailyAverage(calcData, 'Biogás Generado (Nm3)', true);
	const daily_CH4_conc_mo = await dailyAverage(calcData, '%CH4 DF', true);

	const result =
		daily_F_mo?.[0]?.length &&
		daily_CH4_conc_mo?.[0]?.length &&
		daily_F_mo?.[0]?.map((record, i) => ({
			value: (
				record?.sum *
				daily_CH4_conc_mo?.[0]?.[i]?.sum *
				methaneDensity *
				conversionFactor
			).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp
		}));

	const dataTimestamp = daily_F_mo?.[1]?.length && daily_F_mo?.[1];

	return [result, dataTimestamp];
};

// Weighted average of all destruction devices used (fraction)
const equation12 = async calcData => {
	// Default methane destruction efficiency of a particular destruction device ‘DD’ (fraction)
	const BDE_DD = 0.995; // constant value

	// Flow of biogas to a particular destruction device ‘DD’
	const F_mo_DD = 1; // (arbitrary test value)

	const dailyCalcData = await dailyAverage(calcData, 'Biogás Generado (Nm3)', true);

	const result =
		dailyCalcData?.[0]?.length &&
		dailyCalcData?.[0]?.map(record => ({
			value: (record?.sum * BDE_DD).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp
		}));

	const dataTimestamp = dailyCalcData?.[1]?.length && dailyCalcData?.[1];

	return [result, dataTimestamp];
};

// Volume of biogas collected for the given time interval
const equation14 = async calcData => {
	// Unadjusted volume of biogas collected for the given time interval
	const F_unadjusted = ['F'];

	// Measured temperature of the biogas for the given time period [°R = °F + 459.67]
	const T = 32; // constant value

	// Measured pressure of the biogas in for the given time interval
	const P = 1; // constant dailyFeedstock

	const dailyCalcData = await dailyAverage(calcData, 'Biogás Generado (Nm3)', true);

	const result =
		dailyCalcData?.[0]?.length &&
		dailyCalcData?.[0]?.map(record => ({
			value: (record?.sum * (520 / T) * P).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp
		}));

	const dataTimestamp = dailyCalcData?.[1]?.length && dailyCalcData?.[1];

	return [result, dataTimestamp];
};

// Total GHG Emissions for Effluent Storage for the Reporting Period
const equation15 = async calcData => {
	// Methane producing capacity of the effluent stream
	const B_0_EF = 0.21; // constant value for all effluent

	// Methane conversion factor of the effluent storage pond
	const methaneConversionFactor = 0.3; // constant value

	// Global Warming Potential of Methane
	const GWP_CH4 = 28; // Const val, tonnes CO2e/tonnes CH4

	// Project uncertainty factor to account for model uncertainties
	const uncertaintyFactor = 1.12; // constant value

	// Volume of effluent discharged into the effluent storage pond in month i
	const Q_EF_i = 1; // (arbitrary test value - Salida Digestato)

	// Chemical oxygen demand of the effluent discharged into the storage pond in month i
	const COD_EF_i = 1; // (arbitrary test value)

	const dailyCalcData = await dailyAverage(calcData, 'Salida Digestato [m3]', true);

	const result =
		dailyCalcData?.[0] &&
		dailyCalcData?.[0]?.length &&
		dailyCalcData?.[0]?.map(record => ({
			value: (
				record?.sum *
				B_0_EF *
				methaneConversionFactor *
				GWP_CH4 *
				uncertaintyFactor *
				COD_EF_i
			).toFixed(2),
			simulated: record?.simulated,
			dataTimestamp: record?.dataTimestamp
		}));

	const dataTimestamp = dailyCalcData?.[1]?.length && dailyCalcData?.[1];

	return [result, dataTimestamp];
};

// Total Metered Quantity of Methane Captured and Destroyed by the AD Project
const equation18 = async calcData => {
	// Global Warming Potential of Methane
	const GWP_CH4 = 28; // Const val, tonnes CO2e/tonnes CH4

	// Total quantity of methane collected and metered
	const CH4_meter = ['CH4']; // example - (tonnes CH4 /month)

	// Average methane destruction efficiency of the combustion device (incase of many devices).
	const BDE_weighted = ['BDE']; // example

	const dailyBiogas = await dailyAverage(calcData, 'Biogás Generado (Nm3)', true);
	const dailyBiogasNoFlare = await dailyAverage(
		calcData,
		'Biogás Generado sin antorcha (Nm3)',
		1,
		true
	);
	const dailyCH4_meter = await dailyAverage(calcData, '%CH4 DF', true);

	const result =
		dailyBiogas?.[0]?.length &&
		dailyBiogas?.[0]?.map((record, i) => {
			const n = !record?.sum || !dailyBiogasNoFlare?.[0]?.[i]?.sum ? 1 : 2;
			return {
				value: (
					((record?.sum + (dailyBiogasNoFlare?.[0]?.[i]?.sum ?? 0)) / n) *
					dailyCH4_meter?.[0]?.[i]?.sum *
					GWP_CH4
				).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			};
		});

	const dataTimestamp = dailyBiogas?.[1]?.length && dailyBiogas?.[1];

	return [result, dataTimestamp];
};

export {
	equation5,
	equation6,
	equation7,
	equation8,
	equation9,
	equation10,
	equation11,
	equation12,
	equation14,
	equation15,
	equation18
};
