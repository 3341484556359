import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getTime, subDays } from 'date-fns';
import zipcelx from 'zipcelx';
import { Button, Space } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import { AppContext } from '../context/globalState';
import { dailyAverage } from '../utils/chartHelpers';
import { feedstockTypes } from '../assets/FeedstockTypes';
import callApi from '../utils/callApi';
import {
	equation5,
	equation6,
	equation7,
	equation8,
	equation9,
	equation10,
	equation11,
	equation12,
	equation14,
	equation15,
	equation18
} from '../utils/AnalyticsMolina';
import {
	Loading,
	Layout,
	SiteHeader,
	DateSelector,
	downloadIcon,
	AnalyticsDashboard
} from '../components';

const Analytics = () => {
	const { siteId } = useParams();
	const [loading, setLoading] = useState(false);
	const { dateRange, equations } = useContext(AppContext);
	const [QiGenset, setQiGenset] = useState([]);
	const [QiFlare, setQiFlare] = useState([]);
	const [CH4DestIGenset, setCH4DestIGenset] = useState([]);
	const [CH4DestIFlare, setCH4DestIFlare] = useState([]);
	const [CH4DestPR, setCH4DestPR] = useState([]);
	const [BaseGHGEmission, setBaseGHGEmission] = useState([]);
	const [periodTimestamp, setPeriodTimestamp] = useState([]);
	const [P5, setP5] = useState([]);
	const [P8, setP8] = useState([]);
	const [EProject, setEProject] = useState([]);
	const [WWS, setWWS] = useState([]);
	const [CH4Emission, setCH4Emission] = useState([]);
	const [fossilFuel, setFossilFuel] = useState([]);
	const [BDE, setBDE] = useState([]);
	const [CH4, setCH4] = useState([]);
	const [CH4Destroyed, setCH4Destroyed] = useState([]);
	const [anDig, setAnDig] = useState([]);
	const [elecProd, setElecProd] = useState([]);
	const [biogasAdjusted, setBiogasAdjusted] = useState([]);
	const [effluentStorage, setEffluentStorage] = useState([]);
	const [analytics, setAnalytics] = useState({});

	useEffect(() => {
		siteId === 'copiulemu' && EquationsCalcCopiulemu('Caudalímetro0021', 'Caudalímetro0022', 'Analisador0012');
		siteId === 'molina' && EquationsCalcMolina('Calculations', 'Feedstock', 'Salida_Digestato_Liquido');
	}, [equations, dateRange]); // eslint-disable-line

	const EquationsCalcMolina = async (sourceId, sourceId2, sourceId3) => {
		setLoading(true);
		let timestamp;
		switch (dateRange) {
			case '3D':
				timestamp = subDays(new Date(), 3);
				break;
			case '30D':
				timestamp = subDays(new Date(), 30);
				break;
			case '7D':
			default:
				timestamp = subDays(new Date(), 7);
				break;
		}

		const newDateRange = Math.round(getTime(timestamp) / 1000);
		let calcData = [];
		let feedstockData = [];
		let SalidaDigestatoLiquido = [];

		const [dataSourceResponse, dataSourceResponse2, dataSourceResponse3] = await Promise.all([
			callApi('data', { sensorId: sourceId, dateRange: newDateRange }),
			callApi('data', { sensorId: sourceId2, dateRange: newDateRange }),
			callApi('data', { sensorId: sourceId3, dateRange: newDateRange })
		]);
		if (
			!dataSourceResponse?.error &&
			dataSourceResponse?.status !== 'error' &&
			!dataSourceResponse2?.error &&
			dataSourceResponse2?.status !== 'error' &&
			!dataSourceResponse3?.error &&
			dataSourceResponse3?.status !== 'error'
		) {
			calcData = dataSourceResponse?.data.reverse();
			feedstockData = dataSourceResponse2?.data.reverse();
			SalidaDigestatoLiquido = dataSourceResponse3?.data.reverse();
			// flatten the data
			feedstockData = feedstockData
				?.map(Object.values)
				?.flat(2)
				?.filter(obj => typeof obj === 'object');
			// map type of feedstock to data
			feedstockData = feedstockData?.map(obj => {
				const found = feedstockTypes?.find(
					record =>
						record?.Company?.replace(/\s+/g, '') === obj?.Empresa?.replace(/\s+/g, '')
				)?.['Type of Feedstock'];
				return { ...(found && { 'Type of Feedstock': found }), ...obj };
			});

			// flatten the data
			SalidaDigestatoLiquido = SalidaDigestatoLiquido
				?.map(Object.values)
				?.flat(2)
				?.filter(obj => typeof obj === 'object');

			const eq5 = await equation5(feedstockData, 1);
			setWWS(eq5);

			const eq6 = await equation6(feedstockData, feedstockTypes);
			setCH4Emission(eq6);

			const eq8 = await equation8();
			const elecProd = [[], []];
			eq6?.[1] && eq6?.[1]?.length &&
				eq6[1].forEach(element => {
					elecProd[0].push(eq8);
					elecProd[1].push(element);
				});
			setElecProd(elecProd);

			const eq9 = await equation9();
			const FFArr = [[], []];
			eq6?.[1] && eq6?.[1]?.length &&
				eq6[1].forEach(element => {
					FFArr[0].push(eq9);
					FFArr[1].push(element);
				});
			setFossilFuel(FFArr);

			const eq11 = await equation11(calcData);
			setCH4(eq11);

			const eq12 = await equation12(calcData);
			setBDE(eq12);

			const eq10 = await equation10(eq12, eq11);
			setAnDig(eq10);

			const eq14 = await equation14(calcData);
			setBiogasAdjusted(eq14);

			const eq15 = await equation15(SalidaDigestatoLiquido);
			setEffluentStorage(eq15);

			const eq18 = await equation18(calcData);
			setCH4Destroyed(eq18);

			const eq7 = await equation7(elecProd?.[0], FFArr?.[0], eq10?.[0], calcData, eq12?.[0], eq15?.[0]);
			const EProject = [[], []];
      eq12[1] && eq12[1]?.length && eq12[1].forEach((element, i) => {
				EProject[0].push(eq7?.[i]);
				EProject[1].push(element);
			});
			setEProject(EProject);

			const analyticsMap = {
				'Total Project GHG emissions': EProject?.[0],
				'Wastewater (liquid industrial waste)': eq5?.[0],
				'Methane emissions from solid waste disposal sites': eq6?.[0],
				'Electricity Generation and Transmission': elecProd?.[0],
				'Fossil fuel use': FFArr?.[0],
				'Anaerobic Digestor': eq10?.[0],
				'Quantity of Methane Collected and Metered': eq11?.[0],
				'Weighted Biogas average of all destruction devices used': eq12?.[0],
				'Volume of biogas collected (adjusted to 15°C and 1 atm)': eq14?.[0],
				'Total GHG Emissions for Effluent Storage': eq15?.[0],
				'Total Metered Quantity of Methane Captured and Destroyed': eq18?.[0]
			};
			setAnalytics(analyticsMap);
			setPeriodTimestamp(EProject?.[1]);
		} else {
			console.error('Error loading data', dataSourceResponse?.error);
			console.error('Error loading data', dataSourceResponse2?.error);
			console.error('Error loading data', dataSourceResponse3?.error);
		}
		setLoading(false);
	};

	const EquationsCalcCopiulemu = async (sensorId, sensorId2, sensorId3) => {
		setLoading(true);
		let timestamp;
		switch (dateRange) {
			case '3D':
				timestamp = subDays(new Date(), 3);
				break;
			case '30D':
				timestamp = subDays(new Date(), 30);
				break;
			case '7D':
			default:
				timestamp = subDays(new Date(), 7);
				break;
		}

		const newDateRange = Math.round(getTime(timestamp) / 1000);
		let sensorData = [];
		let sensorData2 = [];
		let sensorData3 = [];
		let LFGGensetDaily, LFGFlareDaily, PRCH4Daily;
		let periodTimestampArr;

		const [sensorDataResponse, sensorDataResponse2, sensorDataResponse3] =
			await Promise.all([
				callApi('data', { sensorId, dateRange: newDateRange }),
				callApi('data', { sensorId: sensorId2, dateRange: newDateRange }),
				callApi('data', { sensorId: sensorId3, dateRange: newDateRange })
			]);
		if (
			!sensorDataResponse?.error &&
			sensorDataResponse?.status !== 'error' &&
			!sensorDataResponse2?.error &&
			sensorDataResponse2?.status !== 'error' &&
			!sensorDataResponse3?.error &&
			sensorDataResponse3?.status !== 'error'
		) {
			sensorData = sensorDataResponse?.data.reverse();
			sensorData2 = sensorDataResponse2?.data.reverse();
			sensorData3 = sensorDataResponse3?.data.reverse();

			LFGGensetDaily = await dailyAverage(sensorData, 'Real Time Flow');
			LFGFlareDaily = await dailyAverage(sensorData2, 'Real Time Flow');
			PRCH4Daily = await dailyAverage(sensorData3, 'CH4');
			periodTimestampArr = PRCH4Daily?.[1];
			setPeriodTimestamp(periodTimestampArr);

			// Total quantity of Methane sent to each eligible device (Genset)
			const QiGensetEq = LFGGensetDaily?.[0]?.map((record, i) => ({
				value: (Number(record?.avgValue) * Number(PRCH4Daily?.[0]?.[i]?.avgValue)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
			const QiGensetEqArr = QiGensetEq && QiGensetEq?.length && QiGensetEq?.map(record => record?.value);
			setQiGenset(QiGensetEq);
		
			// Total quantity of Methane sent to each eligible device (Flare)
			const QiFlareEq = LFGFlareDaily?.[0]?.map((record, i) => ({
				value: (Number(record?.avgValue) * Number(PRCH4Daily?.[0]?.[i]?.avgValue)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
			const QiFlareEqArr = QiFlareEq && QiFlareEq?.length && QiFlareEq?.map(record => record?.value);
			setQiFlare(QiFlareEq);
		
			// Net Landfill Methane Emissions Destroyed by each Eligible Destruction device (CH4 Dest i - Genset)
			const CH4DestIGensetEq = QiGensetEq.map(record => ({
				value: (record?.value * Number(equations?.[3]?.current?.['DE']?.value)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
			const CH4DestIGensetEqArr = CH4DestIGensetEq && CH4DestIGensetEq?.length && CH4DestIGensetEq?.map(record => record?.value);
			setCH4DestIGenset(CH4DestIGensetEq);
		
			const CH4DestIFlareEq = QiFlareEq.map(record => ({
				value: (record?.value * Number(equations?.[3]?.current?.['DE']?.value)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
			const CH4DestIFlareEqArr = CH4DestIFlareEq && CH4DestIFlareEq?.length && CH4DestIFlareEq?.map(record => record?.value);
			setCH4DestIFlare(CH4DestIFlareEq);
		
			// Total Methane Emissions Destroyed (CH4 Dest PR - Genset)
			const CH4DestPRGensetEq = CH4DestIGensetEq.map(record => ({
				value: (record?.value * Number(equations?.[4]?.current?.['pCH4']?.value * 0.001)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
		
			// Total Methane Emissions Destroyed (CH4 Dest PR - Flare)
			const CH4DestPRFlareEq = CH4DestIFlareEq.map(record => ({
				value: (record?.value * Number(equations?.[4]?.current?.['pCH4']?.value * 0.001)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
		
			// Total Methane Emissions Destroyed
			const CH4DestPREq = CH4DestPRGensetEq.map((record, i) => ({
				value: (Number(record?.value) + Number(CH4DestPRFlareEq?.[i]?.value)).toFixed(2),
				simulated: record?.simulated,
				dataTimestamp: record?.dataTimestamp
			}));
			const CH4DestPREqArr = CH4DestPREq && CH4DestPREq?.length && CH4DestPREq?.map(record => record?.value);
			setCH4DestPR(CH4DestPREq);

			// Baseline scenario GHG emissions
			const BaseGHGEmissionEq = CH4DestPREq.map(record =>
				(
					record?.value *
					Number(equations?.[0]?.current?.['GWP CH4']?.value) *
					(1 - Number(equations?.[0]?.current?.['OX']?.value))
				).toFixed(2)
			);
			setBaseGHGEmission(BaseGHGEmissionEq);

			// GHG emissions from electricity generation and delivery (P5)
			const P5Eq =
				(Number(equations?.[1]?.current?.['EF Elec gen']?.value) *
					Number(equations?.[1]?.current?.['EL use']?.value)) /
				1000;
			const P5EqArr = QiGensetEq.map(() => P5Eq);
			setP5(P5EqArr);

			// GHG emissions from supplemental fossil fuel combustion, other than natural gas parameters (P8)
			const P8Eq = (
				(Number(equations?.[2]?.current?.['FF PR,i']?.value) *
				Number(equations?.[2]?.current?.['EF FF,I,CO2']?.value) +
				Number(equations?.[2]?.current?.['FF PR,i']?.value) *
				Number(equations?.[2]?.current?.['EF FF,I,N2O']?.value) *
				Number(equations?.[2]?.current?.['GWP N20']?.value) +
				Number(equations?.[2]?.current?.['FF PR,i']?.value) *
				Number(equations?.[2]?.current?.['EF FF,I,CH4']?.value) *
				Number(equations?.[2]?.current?.['GWP CH4']?.value)) /
				1000
			).toFixed(2);
			const P8EqArr = QiGensetEq.map(() => P8Eq);
			setP8(P8EqArr);

			// Total Project GHG emissions
			const EProjectEq = P5EqArr.map((record, i) => Number(record) + Number(P8EqArr?.[i]));
			setEProject(EProjectEq);

			const analyticsMap = {
				'Total Project GHG emissions': EProjectEq,
				'Total Project GHG emissions (Baseline)': BaseGHGEmissionEq,
				'Total methane emissions destroyed': CH4DestPREqArr,
				'Net methane destroyed by flare': CH4DestIFlareEqArr,
				'Net methane destroyed by genset': CH4DestIGensetEqArr,
				'Total methane sent to flare': QiFlareEqArr,
				'Total methane sent to genset': QiGensetEqArr,
				'Total GHG emissions from electricity generation and delivery': P5EqArr,
				'Total GHG emissions from supplemental fossil fuel': P8EqArr
			};
			setAnalytics(analyticsMap);
		} else {
			console.error('Error loading data', sensorDataResponse?.error);
			console.error('Error loading data', sensorDataResponse2?.error);
			console.error('Error loading data', sensorDataResponse3?.error);
		}
		setLoading(false);
	};

	const handleDownloadXLSX = () => {
		try {
			let headers = Object.keys(analytics).map(entry => ({
				value: entry,
				type: 'string'
			}));

			const firstHeader = {
				value: 'Timestamp',
				type: 'string'
			};
			headers = [firstHeader].concat(headers);

			const firstArr = analytics['Total Project GHG emissions'];
			const rows = [];

			for (let index = 0; index < firstArr?.length - 1; index++) {
				const rowArr = [];

				const rowDataDate = {
					value: periodTimestamp?.[index],
					type: 'date'
				};
				rowArr.push(rowDataDate);

				for (let arr in analytics) {
          let rowData = {};
          if (siteId === 'molina') {
            rowData = {
              value: analytics?.[arr]?.[index]?.value ?? analytics?.[arr]?.[index],
            };
          } else {
            rowData = {
              value: isNaN(Number(analytics?.[arr]?.[index]))
                ? analytics?.[arr]?.[index]
                : Number(analytics?.[arr]?.[index]),
              type: isNaN(Number(analytics?.[arr]?.[index])) ? 'string' : 'number'
            };
          }
					rowArr.push(rowData);
				}
				rows.push(rowArr);
			}

			const config = {
				filename: 'LFG Protocol Calculations',
				sheet: {
					data: [headers, ...rows]
				}
			};

			zipcelx(config); // Invoking zipcelx will automatically download the generated xlsx file
		} catch (error) {
			console.log('Error generating XLSX file', error);
		}
	};

	return (
		<Layout>
			<React.Fragment>
				<SiteHeader search={false} />
				<div className='site-info'>
					<div className='sub-header-wrapper'>
						<div className='notification-wrapper'>
							<h3> Analytics </h3>
						</div>
						<Space size='large'>
							<DateSelector bordered={false} analytics />
							<button
								className='primary-btn-white-3'
								onClick={() => handleDownloadXLSX()}>
								<img src={downloadIcon} alt='download' className='icon-export' />
								Export formulae
							</button>
							<Link to={`/${siteId}/analytics-setup`}>
								<Button disabled={siteId === 'molina'} className='primary-btn-white'>
									<SettingFilled className='icon-add' />
									Settings
								</Button>
							</Link>
						</Space>
					</div>
					{loading ? (
						<Loading />
					) : (
						<AnalyticsDashboard
							EProject={EProject}
							QiGenset={QiGenset}
							QiFlare={QiFlare}
							CH4DestIGenset={CH4DestIGenset}
							CH4DestIFlare={CH4DestIFlare}
							CH4DestPR={CH4DestPR}
							BaseGHGEmission={BaseGHGEmission}
							periodTimestamp={periodTimestamp}
							P5={P5}
							P8={P8}
							WWS={WWS}
							CH4Emission={CH4Emission}
							BDE={BDE}
							CH4={CH4}
							anDig={anDig}
							CH4Destroyed={CH4Destroyed}
							biogasAdjusted={biogasAdjusted}
							fossilFuel={fossilFuel}
							elecProd={elecProd}
							effluentStorage={effluentStorage}
						/>
					)}
				</div>
			</React.Fragment>
		</Layout>
	);
};

export default Analytics;
