import React from 'react';
import { Table, Space, Avatar } from 'antd';
import { format, compareAsc, isWithinInterval, subDays, startOfDay } from 'date-fns';
import '../utils/avatarColorHelper';

const ActivityTable = ({ activity, users }) => {
  const activityTableColumns = [
    {
      title: 'Timestamp',
      key: 'timestamp',
      width: '30%',
      sorter: (a, b) => compareAsc(a.timestamp, b.timestamp),
      render: record => format(record.timestamp, 'MMM d, yyyy HH:mm:ss'),
      filters: [
        { text: 'Today', value: startOfDay(new Date()) },
        { text: 'Last 3 days', value: subDays(new Date(), 3) },
        { text: 'Last 7 days', value: subDays(new Date(), 7) },
        { text: 'Last 30 days', value: subDays(new Date(), 30) }
      ],
      onFilter: (value, record) =>
        isWithinInterval(record?.timestamp, {
          start: value,
          end: new Date()
        })
    },
    {
      title: 'Activity',
      key: 'activity',
      ellipsis: true,
      render: record => {
        const user = users.find(user => record?.name === user?.email || record?.name === user?.displayName);
        return (
          <Space size='middle'>
            <span className='avatar'>
              <Avatar
                gap={10}
                size='large'
                className='nav-avatar'
                src={user?.photoURL || null}
                style={{ backgroundColor: record?.name.replace(/\s/g, '').toLowerCase().toColor() }}>
                {(record?.name?.split(' ')?.[0]?.charAt(0) || '') +
                  (record?.name?.split(' ')?.[1]?.charAt(0) || '')}
              </Avatar>
            </span>
            <div>
              <span className='users-table-name'>{record?.name}</span>
              <span className='ant-card-title'> {record?.activity} </span>
              <span className='users-table-name'>{record?.item}</span>
            </div>
          </Space>
        );
      }
    }
  ];

  return (
    <Table
      className={activity?.length > 10 ? 'ant-table-cell-pagination' : 'ant-table-cell'}
      columns={activityTableColumns}
      dataSource={activity}
      rowKey={item => item.timestamp}
      pagination={{
        hideOnSinglePage: true,
        showTotal: (total, range) => (
          <span className='table-total'>
            Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
          </span>
        )
      }}
      scroll={{ y: 800 }}
    />
  );
};

export default ActivityTable;
