import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { AppContext } from '../context/globalState';
import { Avatar, Layout, Loading, SiteCard } from '../components';
import logo from '../assets/dmrv-logo.svg';

const Overview = () => {
  const history = useHistory();
  const { notifications, sites, user } = useContext(AppContext);

  const selectSite = async site => {
    history.push(`/${site?.id}/dashboard`);
  };

  return (
    <Layout site={null}>
      <div className='login-header-wrapper'>
        <Link to='/'>
          <img src={logo} alt='Logo' className='login-logo' />
        </Link>
        <Avatar user={user} />
      </div>
      <div className='overview-page-wrapper'>
        <div className='sub-header-wrapper'>
          <h3> All projects </h3>
        </div>
        {sites.length === 0 ? (
          <Loading />
        ) : (
          sites.map((site, i) => (
            <SiteCard
              key={i}
              notifications={notifications.length}
              sensors={site.sensors}
              name={site.name}
              address={site.location.address}
              latitude={site.location.latitude}
              longitude={site.location.longitude}
              callback={() => selectSite(site)}
            />
          ))
        )}
      </div>
    </Layout>
  );
};

export default Overview;
