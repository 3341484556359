import React from 'react';
import { Card, Space, Row, Col, Menu, Dropdown } from 'antd';
import { CSVLink } from 'react-csv';
import zipcelx from 'zipcelx';
import { CaretDownOutlined } from '@ant-design/icons';
import callApi from '../utils/callApi';
import {
	RangeBarChart,
	DateSelector,
	SensorLogTable,
	SpreadsheetLogTable,
	downloadIcon
} from '.';

const DataSourceLog = ({ data, sensor, annotations = null }) => {
  const getDataForExport = (exportAnnotations = false) => {
    if (exportAnnotations) {
      return annotations?.map(Object.values)?.flat(2)?.filter(obj => typeof obj === 'object');
    } else if (!sensor?.hasOwnProperty('EquipmentGroup')) {
      return data?.map(Object.values)?.flat(2)?.filter(obj => typeof obj === 'object');
    }
    return data;
  }

  const handleDownloadXLSX = (exportAnnotations = false) => {
    try {
      let flattenedData = data;
      let firstRow = data?.[0];
      if (exportAnnotations) {
        flattenedData = annotations?.map(Object.values)?.flat(2)?.filter(obj => typeof obj === 'object');
        firstRow = annotations?.[0]?.annotations?.[0];
      } else if (!sensor?.hasOwnProperty('EquipmentGroup')) {
        flattenedData = sensor?.Data && data?.map(Object.values)?.flat(2)?.filter(obj => typeof obj === 'object');
        firstRow = data?.[0]?.Data?.[0];
      }

      const headers = Object.keys(firstRow).map(entry => ({
        value: entry,
        type: 'string'
      }));

      const rows = flattenedData?.map(row => {
        const rowData = Object.keys(firstRow).map(entry => ({
          value: typeof row[entry] === 'boolean' || isNaN(Number(row[entry])) ? row[entry] : Number(row[entry]),
          type: typeof row[entry] === 'boolean' || isNaN(Number(row[entry])) ? 'string' : 'number'
        }));
        return rowData;
      });

      const config = {
        filename: exportAnnotations ? `${sensor?.EquipmentName}_annotations` : sensor?.EquipmentName,
        sheet: {
          data: [headers, ...rows]
        }
      };

      zipcelx(config); // Invoking zipcelx will automatically download the generated xlsx file
    } catch (error) {
      console.log('Error generating XLSX file', error);
    }
  };

  const addToAssets = () => {};

  const logActivity = async (source = 'data') => {
    await callApi('newactivity', {
      activity: `exported sensor ${source} for`,
      item: sensor?.EquipmentName
    });
  };

  const handleMenuClick = async e => {
    switch (e.key) {
      case 'download-XLSX':
        handleDownloadXLSX();
        await logActivity();
        break;
      case 'download-CSV':
        await logActivity();
        break;
      case 'download-annotations-XLSX':
        handleDownloadXLSX(true);
        await logActivity('annotations');
        break;
      case 'download-annotations-CSV':
        await logActivity('annotations');
        break;
      case 'add-assets':
        addToAssets();
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu className='avatar-menu' onClick={handleMenuClick}>
      <Menu.Item
        icon={<img src={downloadIcon} alt='download-icon' className='export-menu-icons' />}
        key='download-CSV'>
        <span className='log-menu-items'>
          <CSVLink data={getDataForExport()} filename={`${sensor?.EquipmentName}.csv`} target='_blank'>
            Download as a CSV file
          </CSVLink>
        </span>
      </Menu.Item>
      <Menu.Item
        icon={<img src={downloadIcon} alt='download-icon' className='export-menu-icons' />}
        key='download-XLSX'>
        Download as a XLSX file
      </Menu.Item>
      {
        annotations && annotations?.length > 0 && (
          <React.Fragment>
            <Menu.Item
              icon={<img src={downloadIcon} alt='download-icon' className='export-menu-icons' />}
              key='download-annotations-CSV'>
              <span className='log-menu-items'>
                <CSVLink data={getDataForExport(true)} filename={`${sensor?.EquipmentName}_annotations.csv`} target='_blank'>
                  Download annotations as a CSV file
                </CSVLink>
              </span>
            </Menu.Item>
            <Menu.Item
              icon={<img src={downloadIcon} alt='download-icon' className='export-menu-icons' />}
              key='download-annotations-XLSX'>
              Download annotations as a XLSX file
            </Menu.Item>
          </React.Fragment>
        )
      }
    </Menu>
  );

  const getDataCount = data => {
    let count = 0;
    data?.forEach(element => {
      if ('Data' in element) {
        count += element?.Data?.length;
      } else {
        count++;
      }
    });
    return count;
  }

  return (
    <div>
      {sensor?.EquipmentGroup === 'Caudalimetro' ? (
        <Row gutter={20}>
          <Col span={24}>
            <Card className='site-info-card-2'>
              <div className='ant-card-title-wrapper-dashboard'>
                <Space>
                  <span className='ant-card-title-activity'>Gas flowrate</span>
                  <span className='ant-card-title'>(Nm3/h)</span>
                </Space>
                <DateSelector />
              </div>
              <div className='chart-body-wrapper'>
                <RangeBarChart data={data} />
              </div>
            </Card>
          </Col>
        </Row>
      ) : null}
      <div className='transactions-tab-wrapper'>
        <div className='log-table-header'>
          <Space size='large'>
            <span className='table-header-title'>{getDataCount(data)} records</span>
          </Space>
          <Dropdown placement='bottomRight' overlay={menu}>
            <button className='primary-btn-lg'>
              Export to a file
              <CaretDownOutlined className='icon-arrow' />
            </button>
          </Dropdown>
        </div>
        {sensor?.hasOwnProperty('EquipmentGroup') ? (
          <SensorLogTable sensor={sensor} data={data} />
        ) : (
          <SpreadsheetLogTable sensor={sensor} data={data} />
        )}
      </div>
    </div>
  );
};

export default DataSourceLog;
