import React, { useEffect, useState } from 'react';
import ImageMap from 'image-map';
import { LeftOutlined } from '@ant-design/icons';
import { EquipmentPopup } from '.';

import markerImg from '../assets/3d/marker.svg';
import rotateLeft from '../assets/3d/arrow-left.svg';
import rotateRight from '../assets/3d/arrow-right.svg';

// 0
import image0 from '../assets/3d/0/V1_Default.png';
import image0Flare from '../assets/3d/0/V1_1_Overlay.png';
import image0UAD from '../assets/3d/0/V1_2_Overlay.png';
import image0UCG from '../assets/3d/0/V1_3_Overlay.png';
import image0Motor from '../assets/3d/0/V1_5_Overlay.png';
// 90
import image90 from '../assets/3d/90/V4_Default.png';
import image90Flare from '../assets/3d/90/V4_1_Overlay.png';
import image90UAD from '../assets/3d/90/V4_2_Overlay.png';
import image90UCG from '../assets/3d/90/V4_3_Overlay.png';
import image90Motor from '../assets/3d/90/V4_5_Overlay.png';
// 180
import image180 from '../assets/3d/180/V3_Default.png';
import image180Flare from '../assets/3d/180/V3_1_Overlay.png';
import image180UAD from '../assets/3d/180/V3_2_Overlay.png';
import image180UCG from '../assets/3d/180/V3_3_Overlay.png';
import image180Motor from '../assets/3d/180/V3_5_Overlay.png';
// 270
import image270 from '../assets/3d/270/V2_Default.png';
import image270Flare from '../assets/3d/270/V2_1_Overlay.png';
import image270UAD from '../assets/3d/270/V2_2_Overlay.png';
import image270UCG from '../assets/3d/270/V2_3_Overlay.png';
import image270Motor from '../assets/3d/270/V2_5_Overlay.png';
// Zoomed 0
import image0FlareZoom from '../assets/3d/0/zoom/Z1_1_Overlay.png';
import image0UADZoom from '../assets/3d/0/zoom/Z1_2_Overlay.png';
import image0UCGZoom from '../assets/3d/0/zoom/Z1_3_Overlay.png';
import image0MotorZoom from '../assets/3d/0/zoom/Z1_5_Overlay.png';
// Zoomed 180
import image180FlareZoom from '../assets/3d/180/zoom/Z2_1_Overlay.png';
import image180UADZoom from '../assets/3d/180/zoom/Z2_2_Overlay.png';
import image180UCGZoom from '../assets/3d/180/zoom/Z2_3_Overlay.png';
import image180MotorZoom from '../assets/3d/180/zoom/Z2_5_Overlay.png';


const images = {
  0: {
    Default: image0,
    Flare: image0Flare,
    Motor: image0Motor,
    UAD: image0UAD,
    UCG: image0UCG,
    zoom: {
      Default: image0UADZoom,
      Flare: image0FlareZoom,
      Motor: image0MotorZoom,
      UAD: image0UADZoom,
      UCG: image0UCGZoom,
    }
  },
  90: {
    Default: image90,
    Flare: image90Flare,
    Motor: image90Motor,
    UAD: image90UAD,
    UCG: image90UCG,
  },
  180: {
    Default: image180,
    Flare: image180Flare,
    Motor: image180Motor,
    UAD: image180UAD,
    UCG: image180UCG,
    zoom: {
      Default: image180UADZoom,
      Flare: image180FlareZoom,
      Motor: image180MotorZoom,
      UAD: image180UADZoom,
      UCG: image180UCGZoom,
    }
  },
  270: {
    Default: image270,
    Flare: image270Flare,
    Motor: image270Motor,
    UAD: image270UAD,
    UCG: image270UCG,
  }
}

const SensorMap3D = () => {
  const getImageMap = () => {
    if (isZoomed) return null;

    switch (angle) {
      case 0:
        return (
          <map name="location-map-0">
            <area 
              alt="CHP unit - Genset, 0 degree view" 
              title="CHP unit - Genset" 
              data-coords="1133,774,1614,532,1616,394,1603,390,1600,292,1511,247,1188,406,1183,459,1132,477,1123,214,1084,214,1075,562,1038,585,1036,728"
              shape="poly" 
              onClick={() => changeImageZoom('Motor')}
              onMouseEnter={() => changeImage('Motor')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UCG - Gas conditioning unit, 0 degree view" 
              title="UCG - Gas conditioning unit" 
              data-coords="598,1053,757,972,757,869,672,829,543,892,534,880,522,899,510,910,511,952,564,979,564,1039"
              shape="poly" 
              onClick={() => changeImageZoom('UCG')}
              onMouseEnter={() => changeImage('UCG')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UAD - Cooling and drying unit, 0 degree view" 
              title="UAD - Cooling and drying unit" 
              data-coords="428,1138,566,1071,564,977,513,952,457,977,464,963,457,947,448,936,444,905,462,896,458,882,443,880,331,949,322,1014,315,1023,321,1039,338,1034,333,1078,375,1069,374,1113"
              shape="poly" 
              onClick={() => changeImageZoom('UAD')}
              onMouseEnter={() => changeImage('UAD')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flare, 0 degree view" 
              title="Flare" 
              data-coords="234,1046,277,1069,319,1049,319,1012,322,989,319,975,321,945,294,933,294,857,307,832,296,825,292,795,259,799,257,928,225,951,225,970,218,996,229,1025"
              shape="poly" 
              onClick={() => changeImageZoom('Flare')}
              onMouseEnter={() => changeImage('Flare')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      case 90: 
        return (
          <map name="location-map-90">
            <area 
              alt="CHP unit - Genset, 90 degree view" 
              title="CHP unit - Genset" 
              data-coords="941,811,1421,1051,1519,1004,1520,862,1506,853,1506,753,1183,587,1126,611,1064,580,1066,405,1054,382,1059,279,1022,281,1026,382,1015,406,1015,634,943,670"
              shape="poly" 
              onClick={() => changeImageZoom('Motor')}
              onMouseEnter={() => changeImage('Motor')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UCG - Gas conditioning unit, 90 degree view" 
              title="UCG - Gas conditioning unit" 
              data-coords="384,442,383,542,542,625,630,580,614,564,616,521,593,504,626,484,471,403,428,415"
              shape="poly" 
              onClick={() => changeImageZoom('UCG')}
              onMouseEnter={() => changeImage('UCG')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UAD - Cooling and drying unit, 90 degree view" 
              title="UAD - Cooling and drying unit" 
              data-coords="213,364,213,458,347,527,383,519,384,442,464,398,467,343,444,330,432,380,352,350,349,302,437,343,434,323,349,286,337,292,331,341,324,355,266,339"
              shape="poly" 
              onClick={() => changeImageZoom('UAD')}
              onMouseEnter={() => changeImage('UAD')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flare, 90 degree view" 
              title="Flare" 
              data-coords="381,131,381,163,370,166,381,196,381,235,367,261,370,274,354,284,450,332,451,292,476,276,473,246,453,231,418,228,416,133"
              shape="poly" 
              onClick={() => changeImageZoom('Flare')}
              onMouseEnter={() => changeImage('Flare')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flare, 90 degree view" 
              title="Flare" 
              data-coords="356,306,356,352,427,383,437,346"
              shape="poly" 
              onClick={() => changeImageZoom('Flare')}
              onMouseEnter={() => changeImage('Flare')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      case 180: 
        return (
          <map name="location-map-180">
            <area 
              alt="CHP unit - Genset, 180 degree view" 
              title="CHP unit - Genset" 
              data-coords="400,705,402,807,388,814,386,928,485,977,969,735,971,622,927,601,927,371,914,350,920,247,884,249,888,348,875,373,877,542,815,580,727,537"
              shape="poly" 
              onClick={() => changeImageZoom('Motor')}
              onMouseEnter={() => changeImage('Motor')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UCG - Gas conditioning unit, 180 degree view" 
              title="UCG - Gas conditioning unit" 
              data-coords="1273,399,1273,426,1273,530,1330,558,1490,479,1490,470,1460,454,1460,419,1469,412,1469,392,1483,385,1485,459,1492,458,1490,378,1458,359,1476,339,1474,323,1462,337,1464,348,1448,353,1402,334"
              shape="poly" 
              onClick={() => changeImageZoom('UCG')}
              onMouseEnter={() => changeImage('UCG')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UAD - Cooling and drying unit, 180 degree view" 
              title="UAD - Cooling and drying unit" 
              data-coords="1437,325,1437,346,1453,355,1460,348,1457,337,1464,323,1478,337,1471,346,1464,362,1489,378,1471,392,1471,410,1462,417,1460,436,1483,451,1522,436,1543,445,1543,459,1534,466,1564,482,1596,466,1669,419,1672,375,1681,367,1681,339,1674,288,1626,306,1625,283,1572,256,1549,265,1508,270,1492,281,1490,300"
              shape="poly" 
              onClick={() => changeImageZoom('UAD')}
              onMouseEnter={() => changeImage('UAD')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flare, 180 degree view" 
              title="Flare" 
              data-coords="1695,210,1708,254,1706,325,1688,334,1678,350,1676,369,1683,382,1683,436,1663,445,1662,436,1653,440,1649,452,1651,463,1665,459,1692,447,1727,466,1769,445,1769,431,1778,424,1778,375,1782,357,1766,332,1745,325,1743,196,1722,193"
              shape="poly" 
              onClick={() => changeImageZoom('Flare')}
              onMouseEnter={() => changeImage('Flare')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      case 270: 
        return (
          <map name="location-map-270">
            <area 
              alt="CHP unit - Genset, 270 degree view" 
              title="CHP unit - Genset" 
              data-coords="494,242,494,337,480,343,480,459,955,696,957,724,973,724,1063,678,1061,539,987,500,987,302,976,279,981,178,946,178,950,281,937,300,936,449,914,438,913,357,584,198"
              shape="poly" 
              onClick={() => changeImageZoom('Motor')}
              onMouseEnter={() => changeImage('Motor')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UCG - Gas conditioning unit, 270 degree view" 
              title="UCG - Gas conditioning unit" 
              data-coords="1372,807,1368,906,1531,989,1527,963,1536,961,1536,949,1547,936,1549,908,1527,898,1538,885,1552,899,1582,910,1586,860,1614,859,1617,843,1458,761,1407,784"
              shape="poly" 
              onClick={() => changeImageZoom('UCG')}
              onMouseEnter={() => changeImage('UCG')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UAD - Cooling and drying unit, 270 degree view" 
              title="UAD - Cooling and drying unit" 
              data-coords="1653,867,1630,880,1621,906,1617,920,1619,984,1644,996,1649,1019,1646,1035,1646,1078,1658,1074,1658,1039,1670,1042,1679,1030,1734,1057,1789,1030,1787,981,1799,956,1787,935"
              shape="poly" 
              onClick={() => changeImageZoom('UAD')}
              onMouseEnter={() => changeImage('UAD')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="UAD - Cooling and drying unit, 270 degree view" 
              title="UAD - Cooling and drying unit" 
              data-coords="1542,883,1529,896,1547,910,1545,936,1534,952,1529,966,1531,977,1526,1025,1550,1039,1545,1009,1561,989,1584,984,1580,952,1566,936,1564,919,1584,926,1580,910"
              shape="poly" 
              onClick={() => changeImageZoom('UAD')}
              onMouseEnter={() => changeImage('UAD')}
              onMouseLeave={() => changeImage()}
            />
            <area 
              alt="Flare, 270 degree view" 
              title="Flare" 
              data-coords="1550,1108,1600,1131,1646,1106,1646,1041,1649,1011,1639,993,1621,986,1619,903,1632,876,1605,857,1582,864,1584,986,1554,991,1547,1011,1547,1028,1552,1039,1550,1053,1538,1044,1538,1037,1529,1032,1526,1049,1536,1060,1549,1062"
              shape="poly" 
              onClick={() => changeImageZoom('Flare')}
              onMouseEnter={() => changeImage('Flare')}
              onMouseLeave={() => changeImage()}
            />
          </map>
        );

      default:
        return null;
    }
  }

  const sensorHintPosition = device => {
    const viewAngle = angle === 180 ? 180 : 0;
    if (device !== 'Default') {
      switch (device) {
        case 'UCG':
          setMarkers([
            [`${device.toLowerCase()}-${viewAngle}`, 'UCG0013'], 
            [`${device.toLowerCase()}-2-${viewAngle}`, 'Caudalímetro0021'],
            [`${device.toLowerCase()}-3-${viewAngle}`, 'Analisador0012']
          ]);
          break;
        case 'Flare':
          setMarkers([
            [`${device.toLowerCase()}-${viewAngle}`, 'Flare0010'], 
            [`${device.toLowerCase()}-2-${viewAngle}`, 'Caudalímetro0022'], 
            null
          ]);
          break;
        case 'Motor':
          setMarkers([
            [`${device.toLowerCase()}-${viewAngle}`, '6392511'], 
            null, null
          ]);
          break;
        case 'UAD':
          setMarkers([
            [`${device.toLowerCase()}-${viewAngle}`, 'UAD0012'], 
            null, null
          ]);
          break;
        default:
          setMarkers([null, null, null]);
          break;
      }
    }
  }

  const [isZoomed, setZoom] = useState(false);
  const [angle, setAngle] = useState(0);
  const [image, setImage] = useState(image0);
  const [imageMap, setImageMap] = useState(getImageMap());
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState('Site plan');
  const [markers, setMarkers] = useState([null, null, null]);

  ImageMap('img[usemap]', 500);

  useEffect(() => {
    setTimeout(() => ImageMap('img[usemap]'), 1000);
  }, []);

  useEffect(() => {
    setImage(getImageByDevice(selected || 'Default', isZoomed));
    setImageMap(getImageMap());
    isZoomed && sensorHintPosition(selected);
  }, [angle, isZoomed, selected]); // eslint-disable-line

  const getImageByDevice = (device, zoomed = false) => {
    if (zoomed) {
      if (angle === 180) {
        return images[180]['zoom'][device];
      } else {
        return images[0]['zoom'][device];
      }
    } else {
      return images[angle][device];
    }
  }

  const changeImage = (device = 'Default') => {
    if (!isZoomed) {
      setImage(getImageByDevice(device));
    }
  }

  const changeImageZoom = async (device = 'Default') => {
    setZoom(true);
    setSelected(device);
    setImage(getImageByDevice(device, true));
    sensorHintPosition(device);

    if (device !== 'Default') {
      setTitle(device);
    }
  }

  const onRotateLeft = () => {
    if (isZoomed) {
      switch (angle) {
        case 0:
          setAngle(180);
          break;
        case 180:
          setAngle(0);
          break;  
        default:
          setAngle(angle => angle === 0 ? 270 : angle - 90);
          break;
      }
    } else {
      setAngle(angle => angle === 0 ? 270 : angle - 90);
    }
  }

  const onRotateRight = () => {
    if (isZoomed) {
      switch (angle) {
        case 0:
          setAngle(180);
          break;
        case 180:
          setAngle(0);
          break;  
        default:
          setAngle(angle => angle === 270 ? 0 : angle + 90);
          break;
      }
    } else {
      setAngle(angle => angle === 270 ? 0 : angle + 90);
    }
  }
  const onZoomOut = () => {
    setZoom(false);
    setSelected(null);
    setTitle('Site plan');
    setMarkers([null, null, null]);
  }

  return (
    <div className='image-3d-wrapper'>
      {isZoomed ? (
        <div className='zoom-section-wrapper'>
          <div className='zoom-button-wrapper' onClick={onZoomOut}>
            <span>
              <LeftOutlined className='back-arrow' />
              Back
            </span>
          </div>
          <div className='title-wrapper'>
            <h3>{title}</h3>
          </div>
          <div className={`rotate-section-wrapper ${!isZoomed ? 'zoomed-out' : ''}`}>
            <div className='rotate-button-wrapper'>
              <div className='map-rotate-btn' onClick={onRotateLeft}>
                Front
              </div>
              <div className='divider' />
              <div className='map-rotate-btn' onClick={onRotateRight}>
                Back
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='zoom-section-wrapper'>
          <div className='title-wrapper'>
            <h3>{title}</h3>
          </div>
          <div className={`rotate-section-wrapper ${!isZoomed ? 'zoomed-out' : ''}`}>
            <div className='rotate-button-wrapper'>
              <div className='map-rotate-btn' onClick={onRotateLeft}>
                <img src={rotateLeft} alt="rotate left" />
              </div>
              <div className='divider' />
              <div className='map-rotate-btn' onClick={onRotateRight}>
                <img src={rotateRight} alt="rotate right" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='image-3d-inner'>
        <img src={image} alt='location-map' className='main-image' useMap={`#location-map-${angle}`} />

        {imageMap}

        {markers.map(marker => {
          return marker?.length 
            ? <EquipmentPopup key={marker?.[0]} marker={marker} markerImg={markerImg} /> 
            : null;
        })}
      </div>
    </div>
  );
};

export default SensorMap3D;
