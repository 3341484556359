import React from 'react';
import { Card, Space } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { SimulatedAreaChart } from '.';

const InfoCardSimulated = ({ title, legend1, legend2, sensorData, type, dataset, color }) => {
  return (
    <Card bordered={false} className='site-info-card-2'>
      <div className='ant-card-title-wrapper-dashboard'>
        <span className='ant-card-title-activity'>{title}</span>
        <Space size='middle' direction='horizontal'>
          <Space>
            <MinusOutlined className={color === 'blue' ? 'icon-minus-blue' : 'icon-minus-green'} />
            <h3 className='ant-card-title-2'>{legend1}</h3>
          </Space>
          {!legend2 ? null : (
            <Space>
              <MinusOutlined className='icon-minus-green' />
              <h3 className='ant-card-title-2'>{legend2}</h3>
            </Space>
          )}
          <Space>
            <MinusOutlined className='icon-minus-orange' />
            <h3 className='ant-card-title-2'>Simulated</h3>
          </Space>
        </Space>
      </div>
      <SimulatedAreaChart data={sensorData} type={type} dataset={dataset} color={color} />
    </Card>
  );
};

export default InfoCardSimulated;
