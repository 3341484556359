import React, { useState, useEffect } from "react";
import { Row, Col } from 'antd';
import {
	InfoCardSimulatedAvg,
	InfoCardSimulated,
	InfoCardStackedLine,
	InfoCardStackedBar,
	DateSelector
} from '.';
import { getAverage } from '../utils/chartHelpers';

const SensorDashboard = ({ sensorData, sensorData2, type }) => {
  const [lastURLSegment, setLastURLSegment] = useState('');

  useEffect(() => {
    const currentUrl = window.location.href;
    setLastURLSegment(currentUrl.split("/").pop());
  }, []);  // eslint-disable-line

  return (
    <React.Fragment>
      {lastURLSegment === 'Feedstock' || lastURLSegment === 'Grape_Harvest' ? null : (
				<div className='selector-wrapper'>
					<DateSelector bordered={false} />
				</div>
			)}
      {(() => {
        switch (type) {
          case 'Caudalimetro':
            return (
              <div>
                <Row justify='space-between' gutter={16}>
                  <Col span={12}>
                    <InfoCardSimulatedAvg
                      title='Gas flow FT1'
                      unit='(Nm3/h)'
                      value={getAverage(sensorData, 'Real Time Flow')}
                      sensorData={sensorData}
                      dataset='Real Time Flow'
                      color='blue'
                    />
                  </Col>
                  <Col span={12}>
                    <InfoCardSimulatedAvg
                      title='Gas flow FT2'
                      unit='(Nm3/h)'
                      value={Number(getAverage(sensorData, 'Total Flow')).toLocaleString()}
                      sensorData={sensorData}
                      dataset='Total Flow'
                      color='green'
                    />
                  </Col>
                </Row>
              </div>
            );
          case 'Motor':
            return (
              <div>
                <Row justify='space-between' gutter={16}>
                  <Col span={12}>
                    <InfoCardSimulated
                      title='Engine Speed'
                      legend1='Engine Speed'
                      sensorData={sensorData}
                      type={type}
                      dataset='Engine Speed'
                      color='blue'
                    />
                  </Col>
                  <Col span={12}>
                    <InfoCardSimulated
                      title='Avg Temperature Exhaust Gas'
                      legend1='Avg Temp'
                      sensorData={sensorData}
                      type={type}
                      dataset='Average Temperature Exhaust Gas'
                      color='green'
                    />
                  </Col>
                </Row>
                <Row justify='space-between' gutter={16}>
                  <Col span={12}>
                    <InfoCardSimulatedAvg
                      title='Gas flow FT1'
                      unit='(Nm3/h)'
                      value={getAverage(sensorData2, 'Real Time Flow')}
                      sensorData={sensorData2}
                      dataset='Real Time Flow'
                      color='blue'
                    />
                  </Col>
                </Row>
              </div>
            );
          case 'Flares':
            return (
              <div>
                <Row justify='space-between' gutter={16}>
                  <Col span={12}>
                    <InfoCardSimulated
                      title='Total UV'
                      legend1='Total UV'
                      legend2='Parcial Total UV'
                      sensorData={sensorData}
                      dataset='Total UV'
                      type={type}
                      color='blue'
                    />
                  </Col>
                  <Col span={12}>
                    <InfoCardSimulated
                      title='Flare Running (1 = True, 0 = False)'
                      legend1='Flare Running'
                      sensorData={sensorData}
                      dataset='Flare Running'
                      type={type}
                      color='blue'
                    />
                  </Col>
                </Row>
              </div>
            );
          case 'Analisadores':
            return (
              <div>
                <Row justify='space-between' gutter={20}>
                  <Col span={24}>
                    <InfoCardStackedLine
                      title='Gas analysis'
                      legend1='CO2'
                      legend2='O2'
                      legend3='CH4'
                      legend4='H2S'
                      data={sensorData}
                    />
                  </Col>
                </Row>
                <Row justify='space-between' gutter={16}>
                  <Col span={12}>
                    <InfoCardSimulated
                      title='Gross Calorific value'
                      legend1='Gross Calorific value'
                      sensorData={sensorData}
                      type={type}
                      dataset='Gross Calorific Value'
                      color='blue'
                    />
                  </Col>
                  <Col span={12}>
                    <InfoCardSimulatedAvg
                      title='Gas flow FT1'
                      unit='(Nm3/h)'
                      value={getAverage(sensorData2, 'Real Time Flow')}
                      sensorData={sensorData2}
                      dataset='Real Time Flow'
                      color='blue'
                    />
                  </Col>
                </Row>
              </div>
            );
          case 'Flowmeters':
          case 'Pressure sensors':
            return (
							<Row gutter={20}>
								<Col span={24}>
									<InfoCardStackedBar
										title='Flowrate'
										unit='(Nm3/h)'
										data={sensorData}
										dataset='OpcQuality'
										color='blue'
									/>
								</Col>
							</Row>
						);
          default:
            return <div />;
        }
      })()}
    </React.Fragment>
  );
};

export default SensorDashboard;
