import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/globalState';
import { Tabs } from 'antd';
import { Layout, Loading, SensorsTable, SiteHeader, SensorMap3D, SensorMap3DMolina } from '../components';

const { TabPane } = Tabs;

const Sensors = () => {
  let history = useHistory();
  const { siteId } = useParams();
  const { sensors } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSensors, setFilteredSensors] = useState([]);

  useEffect(() => {
    setFilteredSensors(
      sensors.filter(sensor =>
        sensor?.EquipmentUniqueIdentifier.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, sensors]);

  const callback = sensor => {
    history.push(`/${siteId}/sensors/${sensor.EquipmentUniqueIdentifier}`, sensor);
  };

  return (
    <Layout>
      <SiteHeader search callback={value => setSearchQuery(value)} />
      <div className='site-sub-header-wrapper'>
        <h3> Sensors </h3>
      </div>
      {!sensors?.length ? (
        <Loading />
      ) : (
        <div className='site-info'>
          <Tabs tabBarGutter={50} defaultActiveKey='1'>
            <TabPane tab='Sensor list' key='1'>
              <div className='transactions-tab-wrapper ant-table-hover'>
                <SensorsTable data={filteredSensors} callback={callback} />
              </div>
            </TabPane>
            <TabPane tab='Sensor map' key='2'>
              {
                siteId === 'molina' ? <SensorMap3DMolina /> : <SensorMap3D />
              }
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  );
};

export default Sensors;
