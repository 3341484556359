import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/storage";
import firebaseConfig from "../firebase.config";
import callApi from "./callApi";

!firebase.apps.length && firebase.initializeApp(firebaseConfig);
firebase.auth().useDeviceLanguage();

const auth = firebase.auth();
const storage = firebase.storage();

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
};

const signInWithCredentials = (action, email, password, callback, errorCallback) => {
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      auth[`${action}WithEmailAndPassword`](email, password)
        .then(res => res.user && callback(!!res.user))
        .catch(error => errorCallback(error.message));
    });
};

async function logout(callback) {
  auth.signOut()
    .then(callback)
    .catch(error => console.error("An error happened", error));
}

const signInWithGoogle = (callback, errorCallback) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/documents');
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => 
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => callback(!!result))
        .catch(error => errorCallback(error.message))
    );
};

const checkToken = async (token, callback, errorCallback) => {
  const verification = await callApi('verify', { token });
  if (verification?.status === 'success') {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(async result => {
        const endpoint = result?.additionalUserInfo?.isNewUser ? 'create' : 'update';
        const userResult = await callApi(endpoint, { token });
        userResult?.status === 'success' ? callback() : errorCallback(userResult?.error);
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(555, error);
        if (errorCode === 'auth/invalid-custom-token') {
          console.log('The token you provided is not valid.', errorMessage);
        }
      })
  } else {
    errorCallback(verification?.error);
  }
}

const requestFirebaseNotificationPermission = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const token = await messaging.getToken({ vapidKey: firebaseConfig?.webPushKey });
      await callApi('messaging', { token });
      return true;
    }
    return false;
  } catch (error) {
    console.log(error?.message);
    return false;
  }
}

const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      if (firebase.messaging.isSupported()) {
        messaging.onMessage(resolve);
      } else {
        reject('Push API not supported in this browser');
      }
    } catch (error) {
      reject(error);
    }
  });

const listFiles = (callback, path) => {
  try {
    storage.ref(path)
      .listAll()
      .then(async ({ items }) => {
        items.forEach((itemRef) => {
          callback({ name: itemRef?.name });
        });

        for await (const fileRef of items) {
          const metadata = await fileRef.getMetadata();
          const downloadUrl = await fileRef.getDownloadURL()  

          callback({
            name: metadata?.name,
            type: metadata?.contentType.replace('text/','').replace('image/','').replace('application/','').replace('vnd.ms-','').replace('vnd.openxmlformats-officedocument.spreadsheetml.sheet','MSEXCEL').toUpperCase(),
            updated: new Date(metadata?.updated).toLocaleDateString(),
            size: metadata?.size,
            url: downloadUrl
          });
        };
      });
  } catch (error) {
    console.log(error);
  }
};

const uploadFile = async (file, callback, path = 'dmrv/general/') => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      if (file) {
        storage
          .ref(path + file?.name)
          .put(file)
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            callback(file?.name);
            resolve();
          })
          .catch(error => {
            console.log("uploadFile error", error);
            reject();
          });
      } else {
        reject();
      }
    } catch (error) {
      console.log('uploadFile error', error);
      reject();
    }
  });

  return promise;
};

const deleteFile = async (file, callback, path = 'dmrv/general/') => {
  try {
    storage.ref(path + file)
      .delete()
      .then(() => callback())
      .catch(error => console.log('Delete file error', error))
  } catch (error) {
    console.log(error);
  }
}

export {
  auth,
  logout,
  signInWithGoogle,
  signInWithCredentials,
  storage,
  checkToken,
  deleteFile,
  listFiles,
  uploadFile,
  requestFirebaseNotificationPermission,
  onMessageListener
};
