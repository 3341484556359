import React from 'react';
import { useParams } from 'react-router';
import { Row, Col } from 'antd';
import { getAverage, getCumulative } from '../utils/chartHelpers';
import { InfoCardArea } from '../components';

const AnalyticsDashboard = props => {
	const { siteId } = useParams();

	return (
		<React.Fragment>
			{(() => {
				switch (siteId) {
					case 'copiulemu':
						return (
							<div>
								<Row gutter={16}>
									<Col span={24}>
										<InfoCardArea
											title='Total Project GHG emissions'
											EqName='Total Project GHG emissions'
											data={props?.EProject}
											data2={props?.BaseGHGEmission}
											dateTimestamp={props?.periodTimestamp}
											unit='t CO2e'
											avgValue={getAverage(props?.EProject)}
											cumulativeValue={getCumulative(props?.EProject)}
											color='blue'
											extraInfo			
										/>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={24}>
										<InfoCardArea
											title='Total methane emissions destroyed'
											EqName='Total Methane emissions destroyed'
											data={props?.CH4DestPR}
											dateTimestamp={props?.periodTimestamp}
											unit='t CO2e'
											avgValue={getAverage(props?.CH4DestPR, 'value')}
											cumulativeValue={getCumulative(props?.CH4DestPR, 'value')}
											color='blue'
											extraInfo
											simulated
											dataset= 'value'
										/>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										<InfoCardArea
											title='Net methane destroyed by flare'
											EqName='Net landfill Methane emissions destroyed by each eligible destruction device'
											data={props?.CH4DestIFlare}
											dateTimestamp={props?.periodTimestamp}
											unit='m3 CH4'
											avgValue={getAverage(props?.CH4DestIFlare, 'value')}
											cumulativeValue={getCumulative(props?.CH4DestIFlare, 'value')}
											color='blue'
											extraInfo
											simulated
											dataset= 'value'
										/>
									</Col>
									<Col span={12}>
										<InfoCardArea
											title='Net methane destroyed by genset'
											EqName='Net landfill Methane emissions destroyed by each eligible destruction device'
											data={props?.CH4DestIGenset}
											dateTimestamp={props?.periodTimestamp}
											unit='m3 CH4'
											avgValue={getAverage(props?.CH4DestIGenset, 'value')}
											cumulativeValue={getCumulative(props?.CH4DestIGenset, 'value')}
											color='blue'
											extraInfo
											simulated
											dataset= 'value'
										/>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										<InfoCardArea
											title='Total methane sent to flare'
											EqName='Total quantity of Methane sent to each eligible device'
											data={props?.QiFlare}
											dateTimestamp={props?.periodTimestamp}
											unit='m3 CH4'
											avgValue={getAverage(props?.QiFlare, 'value')}
											cumulativeValue={getCumulative(props?.QiFlare, 'value')}
											color='blue'
											extraInfo
											simulated
											dataset= 'value'
										/>
									</Col>
									<Col span={12}>
										<InfoCardArea
											title='Total methane sent to genset'
											EqName='Total quantity of Methane sent to each eligible device'
											data={props?.QiGenset}
											dateTimestamp={props?.periodTimestamp}
											unit='m3 CH4'
											avgValue={getAverage(props?.QiGenset, 'value')}
											cumulativeValue={getCumulative(props?.QiGenset, 'value')}
											color='blue'
											extraInfo
											simulated
											dataset= 'value'
										/>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										<InfoCardArea
											title='Total GHG emissions from electricity generation and delivery'
											EqName='GHG emissions from electricity generation and delivery'
											data={props?.P5}
											dateTimestamp={props?.periodTimestamp}
											unit='t CO2e'
											avgValue={getAverage(props?.P5)}
											cumulativeValue={getCumulative(props?.P5)}
											color='blue'
											extraInfo
										/>
									</Col>
									<Col span={12}>
										<InfoCardArea
											title='Total GHG emissions from supplemental fossil fuel'
											EqName='GHG emissions from supplemental fossil fuel combustion, other than natural gas'
											data={props?.P8}
											dateTimestamp={props?.periodTimestamp}
											unit='t CO2e'
											avgValue={getAverage(props?.P8)}
											cumulativeValue={getCumulative(props?.P8)}
											color='blue'
											extraInfo
										/>
									</Col>
								</Row>
							</div>
						);
					case 'molina':
						return (
							<div>
								<Row gutter={16}>
									<Col span={24}>
										{(props?.EProject[0] &&
											props?.EProject[1] &&
											props?.EProject[0]?.length &&
											props?.EProject[1]?.length) ? (
												<InfoCardArea
													title='Total Project GHG emissions'
													data={props?.EProject[0]}
													dateTimestamp={props?.EProject[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.EProject[0], 'value')}
													cumulativeValue={getCumulative(props?.EProject[0], 'value')}
													color='blue'
                          simulated
													dataset= 'value'
												/>		
											) : null}
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										{(props?.WWS[0] &&
											props?.WWS[1] &&
											props?.WWS[0]?.length &&
											props?.WWS[1]?.length) ? (
												<InfoCardArea
													title='Wastewater (liquid industrial waste)'
													data={props?.WWS[0]}
													dateTimestamp={props?.WWS[1]}
													unit='Tonnes'
													avgValue={getAverage(props?.WWS[0], 'value')}
													cumulativeValue={getCumulative(props?.WWS[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
									<Col span={12}>
										{(props?.CH4Emission[0] &&
											props?.CH4Emission[1] &&
											props?.CH4Emission[0]?.length &&
											props?.CH4Emission[1]?.length) ? (
												<InfoCardArea
													title='Methane emissions from solid waste disposal sites'
													data={props?.CH4Emission[0]}
													dateTimestamp={props?.CH4Emission[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.CH4Emission[0], 'value')}
													cumulativeValue={getCumulative(props?.CH4Emission[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										{(props?.elecProd[0] &&
											props?.elecProd[1] &&
											props?.elecProd[0]?.length &&
											props?.elecProd[1]?.length) ? (
												<InfoCardArea
													title='Electricity Generation and Transmission'
													data={props?.elecProd[0]}
													dateTimestamp={props?.elecProd[1]}
													unit='kg CO2e/MWh'
													avgValue={getAverage(props?.elecProd[0], 'value')}
													cumulativeValue={getCumulative(props?.elecProd[0], 'value')}
													color='blue'
												/>
											) : null}
									</Col>
									<Col span={12}>
										{(props?.fossilFuel[0] &&
											props?.fossilFuel[1] &&
											props?.fossilFuel[0]?.length &&
											props?.fossilFuel[1]?.length) ? (
												<InfoCardArea
													title='Fossil fuel use'
													data={props?.fossilFuel[0]}
													dateTimestamp={props?.fossilFuel[1]}
													unit={
														<span className='ant-card-title'>
															m<sup className='ant-card-title'>3</sup>
														</span>
													}
													avgValue={getAverage(props?.fossilFuel[0], 'value')}
													cumulativeValue={getCumulative(props?.fossilFuel[0], 'value')}
													color='blue'
												/>
											) : null}
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										{(props?.anDig[0] &&
											props?.anDig[1] &&
											props?.anDig[0]?.length &&
											props?.anDig[1]?.length) ? (
												<InfoCardArea
													title='Anaerobic Digestor'
													data={props?.anDig?.[0]}
													dateTimestamp={props?.anDig[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.anDig[0], 'value')}
													cumulativeValue={getCumulative(props?.anDig[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
									<Col span={12}>
										{(props?.CH4[0] &&
											props?.CH4[1] &&
											props?.CH4[0]?.length &&
											props?.CH4[1]?.length) ? (
												<InfoCardArea
													title='Quantity of Methane Collected and Metered'
													data={props?.CH4?.[0]}
													dateTimestamp={props?.CH4[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.CH4[0], 'value')}
													cumulativeValue={getCumulative(props?.CH4[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										{(props?.BDE[0] &&
											props?.BDE[1] &&
											props?.BDE[0]?.length &&
											props?.BDE[1]?.length) ? (
												<InfoCardArea
													title='Weighted Biogas average of all destruction devices used'
													data={props?.BDE[0]}
													dateTimestamp={props?.BDE[1]}
													unit='Nm3'
													avgValue={getAverage(props?.BDE[0], 'value')}
													cumulativeValue={getCumulative(props?.BDE[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
									<Col span={12}>
										{(props?.biogasAdjusted[0] &&
											props?.biogasAdjusted[1] &&
											props?.biogasAdjusted[0]?.length &&
											props?.biogasAdjusted[1]?.length) ? (
												<InfoCardArea
													title='Volume of biogas collected (adjusted to 15°C and 1 atm)'
													data={props?.biogasAdjusted[0]}
													dateTimestamp={props?.biogasAdjusted[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.biogasAdjusted[0], 'value')}
													cumulativeValue={getCumulative(props?.biogasAdjusted[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										{(props?.effluentStorage[0] &&
											props?.effluentStorage[1] &&
											props?.effluentStorage[0]?.length &&
											props?.effluentStorage[1]?.length) ? (
												<InfoCardArea
													title='Total GHG Emissions for Effluent Storage'
													data={props?.effluentStorage[0]}
													dateTimestamp={props?.effluentStorage[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.effluentStorage[0], 'value')}
													cumulativeValue={getCumulative(props?.effluentStorage[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
									<Col span={12}>
										{(props?.CH4Destroyed[0] &&
											props?.CH4Destroyed[1] &&
											props?.CH4Destroyed[0]?.length &&
											props?.CH4Destroyed[1]?.length) ? (
												<InfoCardArea
													title='Total Metered Quantity of Methane Captured and Destroyed'
													data={props?.CH4Destroyed[0]}
													dateTimestamp={props?.CH4Destroyed[1]}
													unit='t CO2e'
													avgValue={getAverage(props?.CH4Destroyed[0], 'value')}
													cumulativeValue={getCumulative(props?.CH4Destroyed[0], 'value')}
													color='blue'
													simulated
													dataset= 'value'
												/>
											) : null}
									</Col>
								</Row>
							</div>
						);
					default:
						return <div />;
				}
			})()}
		</React.Fragment>
	);
};

export default AnalyticsDashboard;
