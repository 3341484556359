import React, { useContext } from 'react';
import { Select } from 'antd';
import { AppContext } from '../context/globalState';

const { Option } = Select;

const DateSelector = ({ analytics, bordered }) => {
  const { dateRange, setDateRange } = useContext(AppContext);

  return (
    <Select
      value={dateRange}
      className={!bordered ? 'select-day-rounded-analytics' : 'select-day-rounded-dashboard'}
      onChange={setDateRange}>
      {analytics ? null : <Option value='Today'>Today</Option>}
      <Option value='3D'>Last 3 days</Option>
      <Option value='7D'>Last week</Option>
      <Option value='30D'>Last 30 days</Option>
    </Select>
  );
};

export default DateSelector;
