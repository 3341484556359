import React from 'react';
import { Card, Space } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { SimulatedAreaAvgChart } from '.';

const InfoCardSimulatedAvg = ({ title, unit, value, sensorData, dataset, color }) => {
  return (
    <Card bordered={false} className='site-info-card-2'>
      <div className='ant-card-title-wrapper-dashboard'>
        <Space>
          <span className='ant-card-title-activity'>{title}</span>
          <span className='ant-card-title-2'>{unit}</span>
        </Space>
        <Space>
        <Space>
          <MinusOutlined className='icon-minus-blue' />
          <h3 className='ant-card-title-2'>{dataset}</h3>
        </Space>
        <Space>
          <MinusOutlined className='icon-minus-orange' />
          <h3 className='ant-card-title-2'>Simulated</h3>
        </Space>
        </Space>
      </div>
      <div className='chart-body-data'>
        <h1>{value}</h1>
      </div>
      <SimulatedAreaAvgChart dataset={dataset} color={color} data={sensorData} />
    </Card>
  );
};

export default InfoCardSimulatedAvg;
