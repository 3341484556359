import React, { useState, useEffect, useContext } from 'react';
import { Avatar, List } from 'antd';
import { formatDistance } from 'date-fns';
import { AppContext } from '../context/globalState';
import { Loading, ActivityTable, Layout, SiteHeader } from '../components';
import callApi from '../utils/callApi';
import '../utils/avatarColorHelper';

const Activity = ({ mini = false }) => {
  const { site } = useContext(AppContext);
  const [activity, setActivity] = useState([]);
  const [filteredActivity, setFilteredActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    async function loadUsers() {
      try {
        const storedUsers = await localStorage.getItem('users');
        storedUsers && setUsers(JSON.parse(storedUsers));
      } catch (err) {
        console.error('Error while loading users data', err);
      }
    }

    loadActivity();
    loadUsers();
  }, []);

  useEffect(() => {
    mini && loadActivity();
  }, [site?.id]); // eslint-disable-line

  useEffect(() => {
    setFilteredActivity(
      activity.filter(entry => {
        const activityMatch = entry?.activity?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase());
        const userMatch = entry?.name?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase());
        const itemMatch = entry?.item?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase());
        return activityMatch || userMatch || itemMatch;
      })
    );
  }, [searchQuery, activity]);

  async function loadActivity() {
    try {
      const response = await callApi('activity');

      if (!response?.error && response?.status !== 'error') {
        setActivity(response?.data);
        setLoading(false);
      } else {
        console.error('Error loading activity data', response?.error);
      }
    } catch (err) {
      console.error('Error while loading activity data', err);
    }
  }

  if (mini) {
    return (
      <div className={ site?.id === 'molina' ? "ant-card-body-wrapper-list-molina" : "ant-card-body-wrapper-list" }>
        {loading ? (
            <Loading />
          ) : (
            <List
              itemLayout='horizontal'
              dataSource={filteredActivity.slice(0, 6)}
              split={false}
              renderItem={entry => {
                const user = users.find(user => entry?.name === user?.email || entry?.name === user?.displayName);

                return (
                  <List.Item key={entry.timestamp}>
                    <List.Item.Meta
                      avatar={
                        <span className='avatar'>
                          <Avatar
                            gap={10}
                            size='large'
                            className="nav-avatar"
                            src={user?.photoURL || null}
                            style={{backgroundColor: entry?.name?.replace(/\s/g, '').toLowerCase().toColor()}}
                            >
                            {entry?.name?.charAt(0)}
                          </Avatar>
                        </span>
                      }
                      title={<span className='ant-card-activity-title'>{entry?.name}</span>}
                      description={
                        <div className="activity-wrapper">
                          <span className="activity-padding">{entry?.activity}</span>
                          <span className='ant-card-activity-title cut-text-lg'> {entry?.item}</span>
                        </div>
                      }
                    />
                    <div className="activity-date">
                    {formatDistance(entry?.timestamp, new Date())}
                    </div>
                  </List.Item>
              )}}
            />
        )}
      </div>
    )
  } else {
    return (
      <Layout>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <SiteHeader search callback={value => setSearchQuery(value)} />
            <div className='site-info'>
              <div className='sub-header-wrapper'>
                <div className='notification-wrapper'>
                  <h3> Activity </h3>
                </div>
              </div>
              <div className='transactions-tab-wrapper'>
                <ActivityTable activity={filteredActivity} users={users} />
              </div>
            </div>
          </React.Fragment>
        )}
      </Layout>
    );
  }
};

export default Activity;
