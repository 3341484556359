import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context/globalState';
import { Loading, NotificationsTable, Layout, SiteHeader } from '../components';
import callApi from '../utils/callApi';

const Notifications = () => {
  const { notificationsCounter, setNotificationsCounter } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  useEffect(() => {
    async function loadNotifications() {
      try {
        const response = await callApi('notifications');

        if (!response?.error && response?.status !== 'error') {
          setNotifications(response?.data);
          setLoading(false);
        } else {
          console.error('Error loading notifications data', response?.error);
        }
      } catch (err) {
        console.error('Error while loading notifications data', err);
      }
    }

    loadNotifications();

    // Removing the timeout before unmounting the component
    return () => {
      clearTimeout(timeout);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredNotifications(
      notifications.filter(entry => {
        const notificationMatch = entry?.value?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase());
        const userMatch = entry?.name?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase());
        const timeMatch = entry?.timestampString?.split(' ')?.[0].toLowerCase().includes(searchQuery.toLowerCase());
        return notificationMatch || userMatch || timeMatch;
      })
    );
  }, [searchQuery, notifications]);

  const timeout = setTimeout(async () => {
    notificationsCounter > 0 && await setNotificationsCounter([]);
  }, 10000);

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <SiteHeader search callback={value => setSearchQuery(value)} />
          <div className='site-info'>
            <div className='sub-header-wrapper'>
              <div className='notification-wrapper'>
                <h3> Notifications </h3>
              </div>
            </div>
            <div className='transactions-tab-wrapper'>
              <NotificationsTable data={filteredNotifications} counter={notificationsCounter} />
            </div>
          </div>
        </React.Fragment>
      )}
    </Layout>
  );
};
 
export default Notifications;
