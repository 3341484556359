import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Card, Space, Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { AreaChart } from '.';

const InfoCardArea = ({
	title,
	EqName,
	unit,
	avgValue,
	cumulativeValue,
	data,
	data2,
	dateTimestamp,
	color,
	extraInfo,
	simulated,
  dataset
}) => {
	let history = useHistory();
	const { siteId } = useParams();

	const handleMenuClick = () => {
		history.push({
			pathname: `/${siteId}/analytics/${title}`,
			state: {
				unit: unit,
				data: data,
				data2: data2,
				dateTimestamp: dateTimestamp,
				EqName: EqName,
				simulated: simulated,
				dataset: dataset
			}
		});
	};

	const menu = () => (
		<Menu onClick={() => handleMenuClick()} className='documentation-menu'>
			<Menu.Item key='more'>View more</Menu.Item>
		</Menu>
	);

	return (
		<Card bordered={false} className='dashboard-info-card-chart-analytics'>
			<div className='ant-card-title-wrapper-analytics'>
				<Space size='middle' direction='vertical'>
					<span className='ant-card-title-analytics'>
						{title}
					</span>
					<div className='ant-card-body-info-3'>
						<div>
							<span className='ant-card-title-activity'>Average: </span>
							<span className='value-wrapper'>{isNaN(Number(avgValue)) ? avgValue : Number(avgValue).toLocaleString()}</span>
							<span className='ant-card-title'> {unit}</span>
						</div>
						<div>
						  <span className='ant-card-title-activity'>Cumulative: </span>
						  <span className='value-wrapper'>{isNaN(Number(cumulativeValue)) ? cumulativeValue : Number(cumulativeValue).toLocaleString()}</span>
						  <span className='ant-card-title'> {unit}</span>
						</div>
					</div>
				</Space>
				<div>
					{extraInfo ? (
						<Dropdown placement='bottomRight' overlay={() => menu()}>
							<EllipsisOutlined className='ellipsis-icon' />
						</Dropdown>
					) : null}
				</div>
			</div>
			<div className='chart-body-wrapper-analytics'>
					<AreaChart
						dataTimestamp={dateTimestamp}
						title={title}
						data2={data2}
						data={data}
						color={color}
						yaxis
            simulated={simulated}
            dataset={dataset}
					/>
			</div>
		</Card>
	);
};

export default InfoCardArea;
