import React from 'react';
import { Card, Space } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { StackedLineChart } from '.';

const InfoCardStackedLine = ({ title, data, legend1, legend2, legend3, legend4 }) => {
  return (
    <Card bordered={false} className='site-info-card-gas'>
      <div className='ant-card-title-wrapper-dashboard'>
        <span className='ant-card-title-activity'>{title}</span>
        <Space size='middle' direction='horizontal'>
          <Space>
            <MinusOutlined className='icon-minus-blue' />
            <h3 className='ant-card-title-2'>{legend1}</h3>
          </Space>
          <Space>
            <MinusOutlined className='icon-minus-green' />
            <h3 className='ant-card-title-2'>{legend2}</h3>
          </Space>
          <Space>
            <MinusOutlined className='icon-minus-blue-light' />
            <h3 className='ant-card-title-2'>{legend3}</h3>
          </Space>
          <Space>
            <MinusOutlined className='icon-minus-yellow' />
            <h3 className='ant-card-title-2'>{legend4}</h3>
          </Space>
          <Space>
            <MinusOutlined className='icon-minus-orange' />
            <h3 className='ant-card-title-2'>Simulated</h3>
          </Space>
        </Space>
      </div>
      <StackedLineChart data={data} />
    </Card>
  );
};

export default InfoCardStackedLine;
