import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/globalState';
import { CloseOutlined } from '@ant-design/icons';

const SensorMapPopup = ({ sensorId, callback }) => {
  let history = useHistory();
  const { siteId } = useParams();
  const { sensors } = useContext(AppContext);
  const [data, setData] = useState({ title1: '', title2: '', data1: null, data2: null });
  const [sensor] = useState(sensors.find(sensor => sensor?.EquipmentUniqueIdentifier === sensorId));

  useEffect(() => {
    if (sensor && sensorId) {
      switch (sensorId) {
        case '6392511':
          setData({
            title1: 'Active Power',
            data1: sensor?.Data?.['Active Power'],
          });
          break;
        case 'Analisador0012':
          setData({
            title1: 'CO2',
            title2: 'CH4',
            data1: sensor?.Data?.['CO2'],
            data2: sensor?.Data?.['CH4']
          });
          break;  

        case 'Flare0010':
          setData({
            title1: 'Flare Operation',
            data1: sensor?.Data?.['Flare Running'] === 'True' ? 'On' : 'Off',
          });
          break; 

        case 'Caudalímetro0021':
          setData({
            title1: 'Real Time Flow',
            title2: 'Total Flow',
            data1: `${sensor?.Data?.['Real Time Flow']} Nm3/h`,
            data2: `${sensor?.Data?.['Total Flow']} Nm3/h`
          });
          break; 

        case 'Caudalímetro0022':
          setData({
            title1: 'Real Time Flow',
            title2: 'Total Flow',
            data1: `${sensor?.Data?.['Real Time Flow']} Nm3/h`,
            data2: `${sensor?.Data?.['Total Flow']} Nm3/h`
          });
          break; 
        default:
          break;
      }
    }
  }, []); // eslint-disable-line

  const redirect = () => {
    history.push(`/${siteId}/sensors/${sensor.EquipmentUniqueIdentifier}`, sensor);
  };

  return (
    <div className="sensor-map-popup-wrapper">
      <div className='sensor-map-popup-header'>
        {sensor?.EquipmentName}
        <button className='popup-close' onClick={() => callback(false)}>
          <CloseOutlined />
        </button>
      </div>
      <div className="sensor-map-popup-body">
        <div className="sensor-map-popup-entry">
          <div className="sensor-map-popup-entry-name">
            Sensor ID
          </div>
          <div className="sensor-map-popup-entry-value">
            {sensor?.EquipmentUniqueIdentifier}
          </div>
        </div>

        {
          data?.data1 && (
            <div className="sensor-map-popup-entry">
              <div className="sensor-map-popup-entry-name">
                {data?.title1}
              </div>
              <div className="sensor-map-popup-entry-value">
                {data?.data1}
              </div>
            </div>
          )
        }

        <div className="sensor-map-popup-entry">
          <div className="sensor-map-popup-entry-name">
            Sensor Model
          </div>
          <div className="sensor-map-popup-entry-value">
            {sensor?.Model || sensor?.EquipmentType}
          </div>
        </div>

        {
          data?.data2 && (
            <div className="sensor-map-popup-entry">
              <div className="sensor-map-popup-entry-name">
                {data?.title2}
              </div>
              <div className="sensor-map-popup-entry-value">
                {data?.data2}
              </div>
            </div>
          )
        }
      </div>
      <div className="sensor-map-popup-footer">
        <button onClick={redirect} className='primary-btn-md'>
          View sensor
        </button>
      </div>
    </div>
  );
}

export default SensorMapPopup;
