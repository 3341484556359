import React from 'react';
import { Card, Space } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { StackedBarChart, DateSelector } from '..';

const InfoCardStackedBar = ({ title, data, unit, dataset, color, dateSelector }) => (
	<Card bordered={false} className='site-info-card-2'>
		<div className='ant-card-title-wrapper-dashboard'>
			<Space>
				<span className='ant-card-title-activity'>{title}</span>
				<span className='ant-card-title'>{unit}</span>
			</Space>
			<Space>
				<Space>
					<MinusOutlined className='icon-minus-blue' />
					<h3 className='ant-card-title-2'>{dataset}</h3>
				</Space>
				<Space>
					<MinusOutlined className='icon-minus-orange' />
					<h3 className='ant-card-title-2'>Simulated</h3>
				</Space>
        {dateSelector &&
          <div className='selector-bordered'>
            <DateSelector bordered={false} />
          </div>
        }
			</Space>
		</div>
		<div className='chart-body-wrapper'>
			<StackedBarChart data={data} dataset={dataset} color={color} />
		</div>
	</Card>
);

export default InfoCardStackedBar;
