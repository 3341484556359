import React from 'react';
import { Card, Space } from 'antd';

const SiteInfoCard = ({ value, unit, label, icon }) => (
	<Card bordered={false} className='dashboard-info-card'>
		<div className='ant-card-body-wrapper-2'>
			<Space size='middle'>
				{icon && <img className='GHG-image' alt={icon} src={icon} />}
				<Space align='center' size='middle' direction='horizontal'>
					<Space direction='vertical'>
						<span className='ant-card-body-info'>
							{Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 })}
							<span className='ant-card-body-info-2'> {unit}</span>
						</span>
						<span className='ant-card-title'>{label}</span>
					</Space>
				</Space>
			</Space>
		</div>
	</Card>
);

export default SiteInfoCard;
