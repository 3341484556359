import React, { useState, useEffect } from 'react';
import GoogleMap from 'google-map-react';
import { Marker } from '.';

const GoogleMapContainer = ({ latitude, longitude }) => {
  const [settings, setSettings] = useState({});
  const location = {
    lat: Number(latitude),
    lng: Number(longitude)
  };

  useEffect(() => {
    async function loadSettings() {
      const settings = await localStorage.getItem('settings');
      const googleMaps = JSON.parse(settings)?.googleMaps;
      setSettings(googleMaps);
    }
    loadSettings();
  }, []);

  return (
    <React.Fragment>
      {settings?.apiKey && (
        <GoogleMap
          options={map => ({ mapTypeId: map.MapTypeId.HYBRID })}
          bootstrapURLKeys={{ key: settings?.apiKey }}
          defaultCenter={location}
          defaultZoom={14}>
          <Marker lat={location.lat} lng={location.lng} />
        </GoogleMap>
      )}
    </React.Fragment>
  );
};

export default GoogleMapContainer;
