import React, { lazy, Suspense } from 'react';
import { Card, Space, Badge } from 'antd';
import { Loading, SensorsIcon, PinIcon, BellIcon } from '.';

const GoogleMapContainer = lazy(() => import('./GoogleMapContainer'));

const SiteCard = ({ name, address, sensors, notifications, latitude, longitude, callback }) => (
  <React.Fragment>
    <Card className='site-overview-card' bordered={false}>
      <div className='site-map'>
        <Suspense fallback={<Loading />}>
          <GoogleMapContainer latitude={latitude} longitude={longitude} />
        </Suspense>
      </div>

      <div className='description'>
        <Space size={110} direction='vertical'>
          <Space size='middle' direction='vertical'>
            <h3 className='cut-text-overview'>{name}</h3>
            <Space direction='horizontal'>
              <div className='site-card-info-icon '>
                <PinIcon />
              </div>
              {address}
            </Space>
            <Space direction='horizontal'>
              <div className='site-card-info-icon '>
                <SensorsIcon />
              </div>
              {`${sensors?.online}/${sensors?.total} sensors connected`}
            </Space>
            <Space direction='horizontal'>
              <Badge count={notifications} dot offset={[-7, 3]}>
                <BellIcon />
              </Badge>
              {notifications} new notifications
            </Space>
          </Space>
          <button onClick={callback} className='primary-btn-md'>
            View dashboard
          </button>
        </Space>
      </div>
    </Card>
  </React.Fragment>
);

export default SiteCard;
