import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { compareDesc, getTime, startOfDay, subDays } from 'date-fns';
import { Tabs, Upload, Space } from 'antd';
import { VerticalAlignTopOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { AppContext } from '../context/globalState';
import callApi from '../utils/callApi';
import { deleteFile, listFiles, uploadFile } from '../utils/firebase';
import { everyNth } from '../utils/chartHelpers';
import { getSensorStatus } from '../utils/dateTimeHelper';
import {
  Layout,
  Loading,
  SensorInfo,
  SensorDashboard,
  SiteHeader,
  DataSourceLog,
  DocumentationTable,
  SpreadsheetInfo,
} from '../components';

const { TabPane } = Tabs;

const DataSource = () => {
  let history = useHistory();
  const { dateRange } = useContext(AppContext);
  const { sensorId, siteId } = useParams();
  const [sensorAnnotations, setSensorAnnotations] = useState();
  const [sensorData, setSensorData] = useState();
  const [sensorData2, setSensorData2] = useState();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [sensorStatus, setSensorStatus] = useState('Connected');
  const [activeTab, setActiveTab] = useState('1');
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  let sensor = history.location.state;
  const sensorId2 = 'Caudalímetro0021';

  useEffect(() => {
    loadSensorData(sensorId, sensorId2);

    const sensorFetchInterval = setInterval(async () => {
      await loadSensorData(sensorId, sensorId2);
    }, 2 * 60 * 1000); // fetch every 2 minutes

    // Removing the timeout before unmounting the component
    return () => {
      sensorFetchInterval && clearInterval(sensorFetchInterval);
    };
  }, [sensorId, dateRange]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === '2') {
      loadDocuments();
    }
  }, [activeTab]); // eslint-disable-line

  useEffect(() => {
    setFilteredDocuments(
      documents.filter(document => document.name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [searchQuery, documents]);

  const loadSensorData = async (sensorId, sensorId2) => {
    let timestamp;
    let nth;
    switch (dateRange) {
      case 'Today':
        timestamp = startOfDay(new Date());
        break;
      case '3D':
        timestamp = subDays(new Date(), 3);
        nth = 6;
        break;
      case '30D':
        timestamp = subDays(new Date(), 30);
        nth = 63;
        break;
      case '7D':
      default:
        timestamp = subDays(new Date(), 7);
        nth = 15;
        break;
    }

    const newDateRange = Math.round(getTime(timestamp) / 1000);
    let sensorData = [];
    const promises = [callApi('data', { sensorId, dateRange: newDateRange })];
    
    if (sensorId === '6392511' || sensorId === 'Analisador0012') {
      promises.push(callApi('data', { sensorId: sensorId2, dateRange: newDateRange }));
    }

    const [sensorDataResponse, sensorDataResponse2 = null] = await Promise.all(promises);
    
    if (!sensorDataResponse?.error && sensorDataResponse?.status !== 'error') {
      sensorData = sensorDataResponse?.data;
      const filteredData = nth && siteId === 'copiulemu' && everyNth(sensorData, nth);
      setSensorData(filteredData || sensorData);
      if ('annotations' in sensorDataResponse) {
        setSensorAnnotations(sensorDataResponse?.annotations);
      }
    } else {
      console.error('Error loading data', sensorDataResponse?.error);
    }

    const sorted = sensorData.sort((a, b) => compareDesc(a?.backendTimestamp, b?.backendTimestamp));
    const lastTimestamp = sorted?.[0]?.dataTimestamp;
    const sensorStatus = getSensorStatus(lastTimestamp, ['Feedstock', 'Grape_Harvest'].includes(sensorId));

    setSensorStatus(sensorStatus);
    if (sensorStatus === 'Offline') {
      callApi('offline', { sensorId });
    }

    if (sensorId === '6392511' || sensorId === 'Analisador0012') {
      let sensorData2 = [];

      if (!sensorDataResponse2?.error && sensorDataResponse2?.status !== 'error') {
        sensorData2 = sensorDataResponse2?.data;
        const filteredData2 = nth && everyNth(sensorData2, nth);
        setSensorData2(filteredData2 ?? sensorData2);
      } else {
        console.error('Error loading data', sensorDataResponse2?.error);
      }
  
      const lastTimestamp2 = sensorData2.reverse()?.[0]?.dataTimestamp;
      const sensor2Status = getSensorStatus(lastTimestamp2);
      if (sensor2Status === 'Offline') {
        callApi('offline', { sensorId2 });
      }
    }
    setLoading(false);
  };

  const handleUpload = async ({ file }) => {
    await uploadFile(file, uploadSuccessCallback, `dmrv/equipment/${sensorId}/`);
  };

  const handleDeletion = async file => {
    await deleteFile(file?.name, loadDocuments, `dmrv/equipment/${sensorId}/`);
  };

  const uploadSuccessCallback = async file => {
    try {
      const response = await callApi('newactivity', {
        activity: `uploaded file ${file.trim()} for sensor`,
        item: sensorId
      });

      if (!response?.error && response?.status !== 'error') {
        loadDocuments();
      } else {
        console.error('Error registering activity', response?.error);
      }
    } catch (err) {
      console.error('Error while registering activity', err);
    }
  };

  const loadDocuments = async () => {
    try {
      const callback = file => {
        setDocuments(documents => [...documents.filter(doc => doc?.name !== file?.name), file]);
      };
      setDocuments([]);
      await listFiles(callback, `dmrv/equipment/${siteId}/${sensorId}`);
    } catch (err) {
      console.error('Error while loading files', err);
    }
  };

  return (
    <Layout>
      <SiteHeader search callback={value => setSearchQuery(value)} />
      <div className='site-sub-header-wrapper'>
        <Space size='large' direction='horizontal'>
          <button onClick={() => history.goBack()} className='back-btn-2'>
            <ArrowLeftOutlined className="icon-arrow-bck" />
          </button>
          <h3 className='notification-wrapper'> {sensor?.EquipmentName} </h3>
        </Space>
        {activeTab === '2' ? (
          <Upload name='file' customRequest={handleUpload} onRemove={handleDeletion}>
            <button className='primary-btn-inverse'>
              <VerticalAlignTopOutlined className='icon-add' />
              Upload
            </button>
          </Upload>
        ) : null}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className='site-info'>
          <Tabs tabBarGutter={50} defaultActiveKey='1' onChange={setActiveTab}>
            <TabPane tab='Overview' key='1'>
              <Fragment>
                {siteId === 'molina' ? (
                  <SpreadsheetInfo data={sensorData?.reverse()} annotations={sensorAnnotations} source={sensor} status={sensorStatus} />
                ) : (
                  <SensorInfo data={sensorData?.reverse()} annotations={sensorAnnotations} sensor={sensor} status={sensorStatus} />
                )}
                {sensor?.EquipmentGroup !== 'UAD' && sensor?.EquipmentGroup !== 'UCG' && sensorData?.length ? (
                  <SensorDashboard
                    type={sensor?.EquipmentGroup}
                    sensorData={sensorData?.reverse()}
                    sensorData2={sensorData2}
                  />
                ) : null}
              </Fragment>
            </TabPane>
            {siteId !== 'molina' &&
              <TabPane tab='Attachments' key='2'>
                <DocumentationTable data={filteredDocuments} onDelete={handleDeletion} />
              </TabPane>
            }
            <TabPane tab='Log' key='3'>
              <DataSourceLog sensor={sensor} data={sensorData.reverse()} annotations={sensorAnnotations} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  );
};

export default DataSource;
