import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Chart from 'react-apexcharts';
import { Loading } from '..';
import { loadChartData, getSeriesArr } from '../../utils/chartHelpers';

const SimulatedAreaAvgChart = ({ color, data, dataset }) => {
  const [seriesArr, setSeriesArr] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [loading, setLoading] = useState(true);

  let mainColor = '#5f79ff';
  const simColor = '#fb8f67';

  if (color === 'blue') {
    mainColor = '#5f79ff';
  } else {
    mainColor = '#14c6bb';
  }

  useEffect(() => {
    async function loadData() {
      try {
        const dataArr = await loadChartData(data, dataset, mainColor, simColor);
        const seriesArr = await getSeriesArr(dataArr[0], dataArr[1], simColor, dataset);
        const categories = data?.map(record => format(record?.dataTimestamp * 1000, 'MMM d, HH:mm'));

        setCategoriesArr(categories);
        setColorArr(dataArr[1]);
        setSeriesArr(seriesArr);
        
        setLoading(false);
      } catch (error) {
        console.log('Error loading chart data', error);
        setLoading(false);
      }
    }

    loadData();
  }, [data]); // eslint-disable-line

  const options = {
    chart: {
      height: 260,
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: colorArr,
    stroke: {
      show: true,
      width: 2.3
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        opacityFrom: 0.3,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    yaxis: {
      forceNiceScale: true,
      floating: false,
      labels: {
        show: false,
        offsetX: -15,
        minWidth: 40,
      }
    },
    xaxis: {
      type: 'datetime',
      categories: categoriesArr,
      labels: {
        show: false
      }
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: -5
      }
    },
    tooltip: {
      fillSeriesColor: false,
      x: {
        format: 'MMM d, yyyy HH:mm'
      },
      y: {
        formatter: seriesName => seriesName.toLocaleString(),
        title: {
        formatter: seriesName => seriesName
        }
      },
      marker: {
        show: false
      }
    },
    markers: {
      colors: colorArr
    },
    legend: {
      show: false,
    },
    animations: {
      enabled: false
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div className='chart-body-no-padding'>
          <Chart height='100%' options={options} series={seriesArr} type='area' />
        </div>
      )}
    </React.Fragment>
  );
};

export default SimulatedAreaAvgChart;
