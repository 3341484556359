import React from 'react';
import { Table, Space, Menu, Dropdown, Spin } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { compareAsc } from 'date-fns';
import { formatBytes } from '../utils/amountConverter';

const DocumentationTable = ({ data, onDelete }) => {
  const handleDownload = record => {
    const a = document.createElement('a');
    a.download = record.name;
    a.href = record.url;
    a.target = '_blank';
    a.setAttribute('download', record.name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleMenuClick = (event, record) => {
    switch (event.key) {
      case 'download-file':
        handleDownload(record);
        break;
      case 'delete-file':
        onDelete(record);
        break;
      default:
        break;
    }
  };

  const menu = record => (
    <Menu className='documentation-menu' onClick={event => handleMenuClick(event, record)}>
      <Menu.Item key='download-file'>Download file</Menu.Item>
      <Menu.Divider />
      <Menu.Item className='ant-menu-delete' key='delete-file'>
        Delete file
      </Menu.Item>
    </Menu>
  );

  const documentationTableColumns = [
    {
      title: 'Name',
      key: 'name',
      ellipsis: true,
      width: '30%',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: record => (
        <Space size='middle'>
          <a className='download' download href={record.url} target='_blank' rel="noopener noreferrer">
            {record.name}
          </a>
        </Space>
      )
    },
    {
      title: 'Type',
      key: 'type',
      width: '15%',
      render: record => record?.type || <Spin />,
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: [
        { text: 'PDF', value: 'PDF' },
        { text: 'CSV', value: 'CSV' },
        { text: 'Word', value: 'MSWORD' },
        { text: 'Excel', value: 'MSEXCEL' },
        { text: 'PNG', value: 'PNG' },
        { text: 'JPG', value: 'JPG' }
      ],
      onFilter: (value, record) => record.type.includes(value)
    },
    {
      title: 'Uploaded on',
      key: 'updated',
      width: '20%',
      sorter: (a, b) => compareAsc(a.updated, b.updated),
      render: record => record?.updated || <Spin />
    },
    {
      title: 'Size',
      key: 'size',
      width: '15%',
      render: record => <span>{record?.size ? formatBytes(record?.size) : <Spin />}</span>,
      sorter: (a, b) => Number(a.size) - Number(b.size)
    },
    {
      key: 'x',
      width: 80,
      render: record => (
        <div>
          <Dropdown overlay={() => menu(record)}>
            <EllipsisOutlined className='ellipsis-icon' />
          </Dropdown>
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <Table
        className={data?.length > 10 ? 'ant-table-cell-pagination ant-table-hover' : 'ant-table-cell ant-table-hover'}
        columns={documentationTableColumns}
        dataSource={data}
        rowKey={item => item.name}
        pagination={{
          hideOnSinglePage: true,
          total: data.length,
          showTotal: (total, range) => (
            <span className='table-total'>
              Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
            </span>
          )
        }}
        scroll={{ y: 800 }}
      />
    </React.Fragment>
  );
};

export default DocumentationTable;
