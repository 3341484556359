import React, { useState, useEffect, Fragment } from 'react';
import Chart from 'react-apexcharts';
import { Loading } from '..';
import { loadStackedChartData } from '../../utils/chartHelpers';

const StackedBarChart = ({ color, dataset, data }) => {
	const [seriesArr, setSeriesArr] = useState([]);
	const [colorArr, setColorArr] = useState([]);
	const [loading, setLoading] = useState(true);

	let mainColor = '#5f79ff';
	const simColor = '#fb8f67';

	if (color === 'blue') {
		mainColor = '#5f79ff';
	} else {
		mainColor = '#14c6bb';
	}

	useEffect(() => {
		async function loadData() {
			try {
				const dataArr = await loadStackedChartData(data, dataset, mainColor, simColor);
				dataArr?.[0] && dataArr[0]?.length && setSeriesArr(dataArr[0]);
				dataArr?.[1] && dataArr[1]?.length && setColorArr(dataArr[1]);
				setLoading(false);
			} catch (error) {
				console.log('Error loading chart data', error);
				setLoading(false);
			}
		}

		loadData();
	}, [data]); // eslint-disable-line

	const options = {
		chart: {
			type: 'bar',
			height: 260,
			stacked: true,
			toolbar: {
				show: false
			}
		},
		dataLabels: {
			enabled: false
		},
		colors: colorArr,
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '20%'
			}
		},
		xaxis: {
			...(dataset === 'OpcQuality' && { type: 'datetime' }),
			labels: {
				show: true,
				...(dataset === 'Toneladas ' && { formatter: function (timestamp) {
					return new Date(timestamp).toDateString()
				}}),
				style: {
					colors: '#7b94ba',
					fontWeight: 500
				},
				offsetX: 5,
			}
		},
		yaxis: {
			forceNiceScale: true,
			floating: false,
			labels: {
				show: true,
				style: {
					colors: '#7b94ba',
					fontWeight: 500
				},
				offsetX: 5
			},
			tickAmount: 3
		},
		grid: {
			show: true,
			xaxis: {
				lines: {
					show: false
				}
			},
			yaxis: {
				lines: {
					show: true
				}
			},
			padding: {
				top: 0,
				right: 25,
				bottom: 10,
				left: 25
			}
		},
		tooltip: {
			fillSeriesColor: false,
			x: {
				show: true,
				formatter: timestamp => new Date(timestamp).toDateString(),
			},
			y: {
				formatter: seriesName => seriesName.toLocaleString(),
				title: {
					formatter: seriesName => seriesName
				}
			},
			marker: {
				show: false
			}
		},
		markers: {
			colors: colorArr
		},
		legend: {
			show: false
		},
		fill: {
			opacity: 1
		},
		animations: {
			enabled: false
		}
	};

	return (
		<Fragment>
			{loading ? (
				<Loading />
			) : (
				<div className='chart-body-wrapper'>
					<Chart
						height='100%'
						width='100%'
						options={options}
						series={seriesArr}
						type='bar'
					/>
				</div>
			)}
		</Fragment>
	);
};

export default StackedBarChart;
