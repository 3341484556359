import React, { useState } from 'react';
import ModalVideo from 'react-modal-video'

const VirtualTour = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="virtual-tour">
      <div className="virtual-tour-player" onClick={() => setShowVideo(true)}>
        <h3>Virtual Tour</h3>
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        allowFullScreen
        isOpen={showVideo}
        url="https://firebasestorage.googleapis.com/v0/b/digital-mrv.appspot.com/o/dmrv%2Fmolina%2FMolina%20Virtual%20Tour.mp4?alt=media&token=1f89123e-3e75-442c-9d10-8e221607a136"
        onClose={() => setShowVideo(false)}
      />
    </div>
  )
};

export default VirtualTour;
