import React, { useRef } from 'react';
import { Table, Input, Button, Space, Badge } from 'antd';
import { format, compareAsc, isWithinInterval, subDays, startOfDay } from 'date-fns';
import { SearchOutlined } from '@ant-design/icons';

const NotificationsTable = ({ data, counter = 0 }) => {
  const searchInput = useRef(null);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ width: 200, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    }
  });

  const notificationsTableColumns = [
    {
      title: () => (
        <span className="notification-badge-wrapper">
          Sensor
        </span>
      ),
      key: 'name',
      width: '25%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: [...new Set(data.map(({ name }) => name))].map(name => ({
        text: name,
        value: name
      })),
      onFilter: (value, record) => record.name.includes(value),
      render: (text, record, index) => (
        <React.Fragment>
          {
            index < counter 
            ? (
              <Space size='middle'>
                <Badge dot offset={[0, 4]} count={1} />
                {record.name}
              </Space>
            ) : (
              <Space size='middle'>
                <span className="notification-badge-wrapper">
                  {record.name}
                </span>
              </Space>
            )
          }
        </React.Fragment>
      )
    },
    {
      title: 'Message',
      key: 'value',
      render: record => record.value,
      width: '40%',
      ...getColumnSearchProps('value')
    },
    {
      title: 'Timestamp',
      key: 'timestamp',
      sorter: (a, b) => compareAsc(a.timestamp, b.timestamp),
      render: record => format(record.timestamp, 'MMM d, yyyy HH:mm:ss'),
      filters: [
        { text: 'Today', value: startOfDay(new Date()) },
        { text: 'Last 3 days', value: subDays(new Date(), 3) },
        { text: 'Last 7 days', value: subDays(new Date(), 7) },
        { text: 'Last 30 days', value: subDays(new Date(), 30) },
      ],
      onFilter: (value, record) => 
        isWithinInterval(record?.timestamp, {
          start: value,
          end: new Date()
        }),
    }
  ];

  const handleSearch = confirm => confirm();
  const handleReset = clearFilters => clearFilters();

  return (
    <Table
      className={data?.length > 10 ? 'ant-table-cell-pagination' : 'ant-table-cell'}
      columns={notificationsTableColumns}
      dataSource={data}
      rowKey={item => `${item?.timestamp}-${item?.name}-${item?.value}`}
      pagination={{
        hideOnSinglePage: true,
        showTotal: (total, range) => (
          <span className='table-total'>
            Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
          </span>
        )
      }}
      scroll={{ y: 800 }}
    />
  );
};

export default NotificationsTable;
