import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { Layout, SiteHeader, AnalyticsTable } from '../components';

import { AppContext } from '../context/globalState';

const AnalyticsSetup = () => {
  let history = useHistory();
  const { siteId } = useParams();
  const { equations, loadEquations } = useContext(AppContext);
  notification.config({
    top: 75,
    duration: 7,
  });

  useEffect(() => {
    if (history?.location?.state?.name) {
      notification.success({ 
        message: 'Updated', 
        description: `Analytics equation "${history?.location?.state?.name}" was successfully updated`
      });
      loadEquations();
    }
  }, [history?.location?.state?.name]); // eslint-disable-line

  const callback = index => {
    history.push(`/${siteId}/analytics-settings/${index?.name}`);
  };

  return (
    <Layout>
      <React.Fragment>
        <SiteHeader />
        <div className='site-info'>
          <div className='sub-header-wrapper'>
            <div className='notification-wrapper'>
              <h3> Set up Analytics </h3>
            </div>
          </div>
          <div className='transactions-tab-wrapper'>
            <AnalyticsTable equations={equations} callback={callback} />
          </div>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default AnalyticsSetup;
