import { useParams } from 'react-router';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Card, Row, Col, Form } from 'antd';
import { AppContext } from '../context/globalState';
import callApi from '../utils/callApi';
import {
  Loading,
  Layout,
  SiteHeader,
  CustomModal,
  CustomParameter,
  CH4DestPRImg,
  CH4DestiImg,
  EProjectImg,
  ESSRB6Img,
  ESSRP5Img,
  ESSRP8Img,
  QiImg
} from '../components';

const AnalyticsSettings = () => {
  const images = {
    'Total Methane emissions destroyed': CH4DestPRImg,
    'Net landfill Methane emissions destroyed by each eligible destruction device': CH4DestiImg,
    'Total Project GHG emissions': EProjectImg,
    'Baseline scenario GHG emissions': ESSRB6Img,
    'GHG emissions from electricity generation and delivery': ESSRP5Img,
    'GHG emissions from supplemental fossil fuel combustion, other than natural gas': ESSRP8Img,
    'Total quantity of Methane sent to each eligible device': QiImg
  };

  let history = useHistory();
  const { equationId, siteId } = useParams();
  const equationImg = images[equationId];
  const [equation, setEquation] = useState();
  const [loading, setLoading] = useState(false);
  const [currentParameter, setCurrentParameter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState();
  const { equations } = useContext(AppContext);

  useEffect(() => {
    async function loadEquation() {
      try {
        const equation = equations.find(eq => eq.name === equationId);
        const items = Object.entries(equation?.current);
        setCurrentParameter(items);
        setEquation(equation);
      } catch (err) {
        console.error('Error while loading equation data', err);
      }
    }

    equations?.length && loadEquation();
  }, [equations?.length]); // eslint-disable-line

  const onSubmit = async values => {
    try {
      setLoading(true);
      const equation = equations.find(eq => eq.name === equationId);
      const payload = { ...equation?.current };
      Object.keys(values).forEach(key => (payload[key].value = Number(values[key])));

      const response = await callApi('equation', {
        equation: {
          name: equation?.name,
          current: payload
        }
      });

      if (response?.error) {
        setLoading(false);
        setError(response?.error);
        setShowModal(true);
      } else {
        await callApi('newactivity', {
          activity: 'updated analytics equation',
          item: equation?.name
        });
        history.push(`/${siteId}/analytics-setup`, { name: equation?.name });
      }
    } catch (err) {
      console.error('Error while updating analytics data', err);
    }
  };

  const reset = async e => {
    e.preventDefault();
    setLoading(true);
    const response = await callApi('reset', { equation: equation?.name });

    if (response?.error) {
      setLoading(false);
      setError(response?.error);
      setShowModal(true);
    } else {
      await callApi('newactivity', {
        activity: 'reset analytics equation',
        item: equation?.name
      });
      history.push(`/${siteId}/analytics-setup`, { name: equation?.name });
    }
  };

  return (
    <Layout>
      <React.Fragment>
        <SiteHeader />
        <div className='site-info'>
          <div className='sub-header-wrapper'>
            <div className='notification-wrapper'>
              <h3> {`Customize ${equation?.name} parameters`} </h3>
            </div>
          </div>
          <Row gutter={16}>
            <Col span={24}>
              <Card bordered={false} className='analytics-parameters-card'>
                <img className='parameters-image' alt='parameters' src={equationImg} />
              </Card>
            </Col>
          </Row>
          <br />
          {loading ? (
            <Loading />
          ) : (
            <Form
              layout='inline'
              scrollToFirstError
              colon={false}
              name='analytics-form'
              onFinish={onSubmit}
              validateTrigger='onSubmit'
              hideRequiredMark>
              <div className='parameters-form'>
                {currentParameter.map((item, i) => (
                  <CustomParameter key={i} disabledAll={false} parameter={{ name: item[0], data: item[1] }} />
                ))}
                <div className='analytics-form-footer'>
                  <Space size='middle'>
                    <button onClick={reset} className='primary-btn-lg'>
                      Reset to default
                    </button>
                  </Space>
                  <Space size='middle'>
                    <button
                      onClick={() => history.push(`/${siteId}/analytics-setup`)}
                      className='delete-button'>
                      Cancel
                    </button>
                    <button className='primary-btn-sm' type='submit'>
                      Save
                    </button>
                  </Space>
                </div>
              </div>

              {showModal && (
                <CustomModal error={error} callback={() => setShowModal(false)} show={showModal} />
              )}
            </Form>
          )}
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default AnalyticsSettings;
