import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Chart from 'react-apexcharts';
import { everyNth, eachCons } from '../../utils/chartHelpers';

const RangeBarChart = ({ data }) => {
  const [seriesArr, setSeriesArr] = useState([]);

  useEffect(() => {
    function loadChartData() {
      const seriesArr = data?.map(record => record?.['Real Time Flow']);
      const firstValue = [seriesArr[0]];
      const everySixthValue = firstValue.concat(everyNth(seriesArr, 6));
      const everySixthValuePairs = eachCons(everySixthValue, 2);

      const categoriesArr = data?.map(record => format(record?.dataTimestamp * 1000, 'MMM d, HH:mm'));
      const everyDateSixthValue = everyNth(categoriesArr, 6);

      const gasFlowrateArr = everySixthValuePairs.map((pair, i) => {
        return {
          x: everyDateSixthValue[i],
          y: pair
        };
      });

      setSeriesArr(gasFlowrateArr);
    }

    loadChartData();
  }, [data]);

  const series = [
    {
      name: 'Gas flow',
      data: seriesArr
    }
  ];

  const options = {
    chart: {
      height: 300,
      type: 'rangeBar',
      toolbar: {
        show: false
      }
    },
    colors: ["#14c6bb"],
    plotOptions: {
      bar: {
        horizontal: false,
        startingShape: 'rounded',
        endingShape: 'rounded',
        columnWidth: '15%',
        distributed: false
      }
    },
    stroke: {
      width: 1,
      colors: ['#14c6bb'],
    },
    fill: {
      type: 'solid',
      colors: ['#14c6bb'],
      opacity: 1
    },
    xaxis: {
      labels: {
        style: {
          colors: '#7b94ba',
          fontWeight: 500
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#7b94ba',
          fontWeight: 500
        }
      }
    },
    grid: {
      show: true,
      borderColor: '#eaf1ff',
      xaxis: {
        lines: {
          show: false,
        },
        labels: {
          show: true
        }
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        min: 0,
        labels: {
          show: true,
          style: {
            colors: '#7b94ba',
            fontWeight: 500
          },
          offsetX: 5
        }
      },
      padding: {
        top: 0,
        right: 20,
        bottom: 30,
        left: 20
      }
    },
    markers: {
      colors: '#7b94ba'
    },
    tooltip: {
      fillSeriesColor: false,
      marker: {
        show: false
      }
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'right'
    }
  };

  return (
    <div className='chart-body-no-padding'>
      <Chart height='100%' options={options} series={series} type='rangeBar' />
    </div>
  );
};

export default RangeBarChart;
