import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Chart from 'react-apexcharts';
import { Loading } from '..';
import { loadChartData, getSeriesArr } from '../../utils/chartHelpers';

const SimulatedAreaChart = ({ data, type, dataset, color }) => {
  const [seriesArr, setSeriesArr] = useState([]);
  const [seriesArr2, setSeriesArr2] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [colorArr2, setColorArr2] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartType, setChartType] = useState('');

  const simColor = '#fb8f67';
  let mainColor = '#14c6bb';

  if (color === 'blue') {
    mainColor = '#5f79ff';
  }

  useEffect(() => {
    async function loadData() {
      try {
        let gasPressureArr = [];
        let setpointGasPressureArr = [];
        let totalUVArr = [];
        let parcialTotalUVArr = [];
        let flareRunningArr = [];
        let motorDatasetArr = [];
        let analyzerArr = [];
        let seriesArr = [];
        let seriesArr2 = [];

        switch (type) {
          case 'UCG':
            gasPressureArr = await loadChartData(data, ['Gas Pressure'], mainColor, simColor);
            seriesArr = await getSeriesArr(gasPressureArr[0], gasPressureArr[1], simColor, 'Gas Pressure');
            setColorArr(gasPressureArr[1]);
            setSeriesArr(seriesArr);
            setpointGasPressureArr = await loadChartData(data, ['Setpoint Gas Pressure'], mainColor, simColor);
            seriesArr2 = await getSeriesArr(setpointGasPressureArr[0], setpointGasPressureArr[1], simColor, 'Setpoint Gas Pressure');
            setColorArr2(setpointGasPressureArr[1]);
            setSeriesArr2(seriesArr2);
            break;
          case 'Motor':
            motorDatasetArr = await loadChartData(data, dataset, mainColor, simColor);
            seriesArr = await getSeriesArr(motorDatasetArr[0], motorDatasetArr[1], simColor, dataset);
            setColorArr(motorDatasetArr[1]);
            setSeriesArr(seriesArr);
            break;
          case 'Flares':
            if (dataset === 'Flare Running') {
              flareRunningArr = data?.map(record => {
                if (record?.['Flare Running'].toLowerCase() === 'true') {
                  return {...record, 'Flare Running': 1};
                } else {
                  return {...record, 'Flare Running': 0};
                }
              });
              flareRunningArr = await loadChartData(flareRunningArr, ['Flare Running'], mainColor, simColor);
              seriesArr = await getSeriesArr(flareRunningArr[0], flareRunningArr[1], simColor, 'Flare Running');
              setColorArr(flareRunningArr[1]);
              setSeriesArr(seriesArr);
            } else {
              totalUVArr = await loadChartData(data, ['Total UV'], mainColor, simColor);
              seriesArr = await getSeriesArr(totalUVArr[0], totalUVArr[1], simColor, 'Total UV');
              setColorArr(totalUVArr[1]);
              setSeriesArr(seriesArr);
              parcialTotalUVArr = await loadChartData(data, ['Parcial Total UV'], '#14c6bb', simColor);
              seriesArr2 = await getSeriesArr(parcialTotalUVArr[0], parcialTotalUVArr[1], simColor, 'Parcial Total UV');
              setColorArr2(parcialTotalUVArr[1]);
              setSeriesArr2(seriesArr2);
            }
            break;
          case 'Analisadores':
            analyzerArr = await loadChartData(data, dataset, mainColor, simColor);
            seriesArr = await getSeriesArr(analyzerArr[0], analyzerArr[1], simColor, dataset);
            setColorArr(analyzerArr[1]);
            setSeriesArr(seriesArr);
            break;

          default:
            break;
        }

        const categories = data?.map(record => format(record?.dataTimestamp * 1000, 'MMM d, HH:mm'));
        setCategoriesArr(categories);

        seriesArr2?.length ? setChartType('line') : setChartType('area');

        setLoading(false);
      } catch (error) {
        console.log('Error loading chart data', error);
        setLoading(false);
      }
  }

    loadData();
  }, [data, dataset]); // eslint-disable-line

  const chartOptions = (id, seriesArr2, colorArr) => {
    return {
      chart: {
        id: id,
        // group: seriesArr2.length ? 'tank' : null,
        height: seriesArr2.length ? 150 : 260,
        type: chartType,
        sparkline: {
          enabled: true
        },
        stacked: seriesArr2.length ? false : null,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: colorArr,
      stroke: {
        show: true,
        width: 2.3,
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: false,
          offsetX: -15,
          minWidth: 40,
        }
      },
      xaxis: {
        type: 'datetime',
        categories: categoriesArr,
        labels: {
          show: false
        }
      },
      fill: chartType === 'area' ? {
        opacity: 0.3,
        type: 'solid',
        gradient: {
            shade: 'dark',
            type: "horizontal",
            shadeIntensity: 0.2,
            inverseColors: true,
            opacityFrom: 0.6,
            opacityTo: 0.7,
            stops: [0, 50, 100],
            colorStops: []
        }
      } : {},
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: -5
        }
      },
      tooltip: {
        fillSeriesColor: false,
        x: {
          format: 'MMM d, yyyy HH:mm'
        },
        y: {
          formatter: seriesName => seriesName.toLocaleString(),
          title: {
            formatter: seriesName => seriesName
          }
        },
        marker: {
          show: false
        }
      },
      markers: {
        colors: colorArr
      },
      legend: {
        show: false
      },
      animations: {
        enabled: false
      }
    }
  }

  const options1 = chartOptions('tank-1', seriesArr2, colorArr);
  const options2 = chartOptions('tank-2', seriesArr, colorArr2);

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        seriesArr2.length ? (
          <div style={{ width: "100%" }}>
            <Chart height={150} width="100%" options={options1} series={seriesArr} />
            <Chart height={150} width="100%" options={options2} series={seriesArr2} />
          </div>
        ) : (
          <div className='chart-body-no-padding'>
            <Chart height='100%' options={options1} series={seriesArr} type='area' />
          </div>
        )
      )}
    </React.Fragment>
  );
};

export default SimulatedAreaChart;