/* 
	DCF - 5 annotations to look for, each missing annotation is 10% decrease in confidence score, additional 30% decrease if source annotation is manual (or missing) 
*/
const getDataConfidenceScore = async annotations => {
	const DCF_Arr =
		annotations &&
		annotations?.length &&
		annotations?.map(record => {
			let DCF = 100;
			const missingAnnotations = 5 - record?.annotations?.length;
			const sourceAnnotation = record?.annotations?.find(
				annotation => annotation?.kind === 'auto'
			);
			if (!sourceAnnotation?.isSatisfied || !sourceAnnotation) {
				DCF -= 30;
			}
			DCF -= 10 * missingAnnotations;
			return DCF;
		});

	const DCF = DCF_Arr?.length && (DCF_Arr?.reduce((a, b) => a + b, 0) / DCF_Arr?.length).toFixed(2);
	return DCF;
};

/* 
DCF - 5 annotations to look for, each missing annotation is 10% decrease in confidence score, additional 30% decrease if source annotation is manual (or missing) 

Measurement uncertainty = 5%(default value) + (100% - DCF) 

Emission Factor = 10% 

Total Uncertainty = SQRT(Measurement Uncertainty ^ 2 + Emission Factor ^2)  
*/
const getTotalUncertainty = async annotations => {
	const DCF_Arr =
		annotations &&
		annotations?.length &&
		annotations?.map(record => {
			let DCF = 100;
			const missingAnnotations = 5 - record?.annotations?.length;
			const sourceAnnotation = record?.annotations?.find(
				annotation => annotation?.kind === 'auto'
			);
			if (!sourceAnnotation?.isSatisfied || !sourceAnnotation) {
				DCF -= 30;
			}
			DCF -= 10 * missingAnnotations;
			return DCF;
		});

	const measurementUncertainty = DCF_Arr?.map(record => 5 + (100 - record));
	const emissionFactor = 10;
	const totalUncertainty = measurementUncertainty?.map(record =>
		Math.sqrt(record ** 2 + emissionFactor ** 2)
	);

	return totalUncertainty;
};

/*
Aggregated Total Uncertainty / No. Measurements over time span 

Missing/simulated value Uncertainty = [(No of measurements since last confirmed / No of confirmed measurements since last outage) ^ 2 * 95%] + 5% 
*/
const getAggregatedUncertainty = async uncertainties => {
	let sum = 0;
	let AggUn = 0;
	if (uncertainties && uncertainties?.length) {
		sum = uncertainties?.reduce((a, b) => a + b, 0);
		AggUn = sum / uncertainties?.length;
	}
	return AggUn;
};

export { getTotalUncertainty, getAggregatedUncertainty, getDataConfidenceScore };
