import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { useParams } from 'react-router';
import { format, compareAsc } from 'date-fns';

const SensorLogTable = ({ data, sensor }) => {
  const { siteId } = useParams();
  const [columns, setColumns] = useState([]);

  const LogTableColumns = [
    {
      title: 'Timestamp',
      key: 'dataTimestamp',
      width: 280,
      sorter: (a, b) => compareAsc(a?.dataTimestamp, b?.dataTimestamp),
      render: record => (
        <span className='table-cell-primary'>
          {format(
						String(record?.dataTimestamp).length === 13 ? record?.dataTimestamp : record?.dataTimestamp * 1000,
						'MMM d, yyyy HH:mm:ss'
					)}
        </span>
      ),
      fixed: 'left',
      defaultSortOrder: 'descend'
    },
  ];

  useEffect(() => {
    function tableColumns() {
      for (const record in sensor?.Data) {
        if (!['dataTimestamp', 'backendTimestamp', 'simulated'].includes(record)) {
          let newColumn = {
            title: record,
            dataIndex: record,
            width: 150
          };
          LogTableColumns.push(newColumn);
        }
      }
      setColumns(LogTableColumns);
    }
    
    sensor?.hasOwnProperty('EquipmentGroup') && tableColumns();
  }, [sensor]); // eslint-disable-line

  return (
    <Table
      className={data?.length > 10 ? 'ant-table-cell-pagination-log' : 'ant-table-cell-log'}
      columns={columns}
      dataSource={data}
      rowKey={item => item?.backendTimestamp}
      pagination={{
        hideOnSinglePage: true,
        showTotal: (total, range) => (
          <span className='table-total'>
            Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
          </span>
        )
      }}
      scroll={{ y: 800, x: 'max-content' }}
    />
  );
};

export default SensorLogTable;
