import React, { useContext } from 'react';
import { Avatar as AvatarComponent, Menu, Dropdown } from 'antd';
import { useHistory } from 'react-router';
import { logout } from '../utils/firebase';
import { AppContext } from '../context/globalState';
import { signOutIcon } from '.';

const Avatar = ({ user }) => {
  const { setLoggedIn } = useContext(AppContext);
  const history = useHistory();

  const completeLogout = async () => {
    setLoggedIn(false);
    await localStorage.clear();
    await logout();
    history.push('/');
  };

  const handleMenuClick = e => {
    console.log('click', e);
    switch (e.key) {
      case 'help-support':   
        history.push(`/help`);
        break;
      case 'profile-settings':
        history.push(`/settings`);
        break;
      case 'sign-out':
        completeLogout();
        break;

      default:
        break;
    }
  };

  const userMenu = (
    <Menu className='avatar-menu' onClick={handleMenuClick}>
      <Menu.Item key={"user-info"} className='avatar-menu-title-wrapper'>
        <h3>
          {user?.displayName?.split(' ')?.[0]} {user?.displayName?.split(' ')?.[1]}
        </h3>
        <span>
          {user?.customClaims?.role
            ? user?.customClaims?.role?.charAt(0).toUpperCase() + user?.customClaims?.role?.slice(1)
            : null}
        </span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key='sign-out'
        icon={<img src={signOutIcon} alt='signout-icon' className='avatar-menu-icons' />}>
        Sign out
      </Menu.Item>
    </Menu>
  );

  return (
    <span className='avatar'>
      {user?.uid && (
        <Dropdown overlay={userMenu}>
          <AvatarComponent 
            gap={10} 
            size='large' 
            src={user?.photoURL || null}
            className='nav-avatar'>
            {(user?.displayName?.split(' ')?.[0]?.charAt(0) || '') +
              (user?.displayName?.split(' ')?.[1]?.charAt(0) || '')}
          </AvatarComponent>
        </Dropdown>
      )}
    </span>
  );
};

export default Avatar;
