import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Chart from 'react-apexcharts';
import { Loading } from '..';
import { loadChartData, getSeriesArr } from '../../utils/chartHelpers';

const StackedLineChart = ({ data }) => {
  const [seriesArr, setSeriesArr] = useState([]);
  const [seriesArr2, setSeriesArr2] = useState([]);
  const [seriesArr3, setSeriesArr3] = useState([]);
  const [seriesArr4, setSeriesArr4] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [colorArr2, setColorArr2] = useState([]);
  const [colorArr3, setColorArr3] = useState([]);
  const [colorArr4, setColorArr4] = useState([]);
  const [categoriesArr, setCategoriesArr] = useState([]);
  const [loading, setLoading] = useState(true);

  const simColor = '#fb8f67';

  useEffect(() => {
    async function loadData() {
      try {
        let seriesArr = [];
        let seriesArr2 = [];
        let seriesArr3 = [];
        let seriesArr4 = [];

        const CO2Arr = await loadChartData(data, 'CO2', '#5f79ff', simColor);
        seriesArr = await getSeriesArr(CO2Arr[0], CO2Arr[1], simColor, 'CO2');
        setColorArr(CO2Arr[1]);
        setSeriesArr(seriesArr);

        const O2Arr = await loadChartData(data, 'O2', '#14c6bb', simColor);
        seriesArr2 = await getSeriesArr(O2Arr[0], O2Arr[1], simColor, 'O2');
        setColorArr2(O2Arr[1]);
        setSeriesArr2(seriesArr2);

        const CH4Arr = await loadChartData(data, 'CH4', '#31c0fd', simColor);
        seriesArr3 = await getSeriesArr(CH4Arr[0], CH4Arr[1], simColor, 'CH4');
        setColorArr3(CH4Arr[1]);
        setSeriesArr3(seriesArr3);

        const H2SArr = await loadChartData(data, 'H2S', '#f25c9b', simColor);
        seriesArr4 = await getSeriesArr(H2SArr[0], H2SArr[1], simColor, 'H2S');
        setColorArr4(H2SArr[1]);
        setSeriesArr4(seriesArr4);

        const categories = data?.map(record => format(record?.dataTimestamp * 1000, 'MMM d, HH:mm'));
        setCategoriesArr(categories);

        setLoading(false);
      } catch (error) {
        console.log('Error loading chart data', error);
        setLoading(false);
      }
    }

    loadData();
  }, [data]);

  const chartOptions = (id, colorArrIn) => {
    return {
      chart: {
        id: id,
        // group: 'gas',
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
          selection: false,
          pan: false,
          reset: true,
          offsetX: -10,
          offsetY: 5
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: colorArrIn,
      stroke: {
        show: true,
        width: 2.3
      },
      yaxis: {
        forceNiceScale: true,
        floating: false,
        labels: {
          show: true,
          style: {
            colors: '#7b94ba',
            fontWeight: 500
          },
          offsetX: 5
        },
        tickAmount: 3
      },
      xaxis: {
        type: 'datetime',
        categories: categoriesArr,
        labels: {
          show: true,
          style: {
            colors: '#7b94ba',
            fontWeight: 500
          },
          offsetX: 5
        }
      },
      grid: {
        borderColor: '#eaf1ff',
        padding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 20
        }
      },
      tooltip: {
        enabled: true,
        x: {
          format: 'MMM d, yyyy HH:mm'
        },
        y: {
          formatter: seriesName => seriesName.toLocaleString(),
          title: {
          formatter: seriesName => seriesName
          }
        },
        marker: {
          show: false
        }
      },
      markers: {
        colors: colorArrIn
      },
      legend: {
        show: false
      },
      animations: {
        enabled: false
      }
    }
  }

  const options1 = chartOptions('gas-1', colorArr);
  const options2 = chartOptions('gas-2', colorArr2);
  const options3 = chartOptions('gas-3', colorArr3);
  const options4 = chartOptions('gas-4', colorArr4);

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ width: '100%' }}>
          <Chart height={110} width="100%" options={options1} series={seriesArr} />
          <Chart height={110} width="100%" options={options2} series={seriesArr2} />
          <Chart height={110} width="100%" options={options3} series={seriesArr3} />
          <Chart height={110} width="100%" options={options4} series={seriesArr4} />
        </div>
      )}
    </React.Fragment>
  );
};

export default StackedLineChart;