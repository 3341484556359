import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Badge } from 'antd';
import { BellOutlined, SearchOutlined } from '@ant-design/icons';
import { AppContext } from '../context/globalState';
import { Avatar } from '.';

const SiteHeader = ({ callback, search }) => {
  let history = useHistory();
  const { user, notifications, site } = useContext(AppContext);

  return (
    <div className='site-header-wrapper'>
      {search ? (
        <div className='input-search'>
          <input
            id='search-box'
            onChange={e => {
              callback(e.target.value);
            }}
            placeholder='Search this page'
            type='text'
            className='search-box'
          />
          <label htmlFor='search-box'>
            <SearchOutlined className='search-icon icon-bell' />
          </label>
        </div>
      ) : null}
      <div className='user-info-wrapper'>
        <Space direction='horizontal' size='large'>
          <button className='notifications-btn' onClick={() => history.push(`/${site?.id}/notifications`)}>
            <Badge count={notifications.length}>
              <BellOutlined className='icon-bell' />
            </Badge>
          </button>
          <Avatar user={user} />
        </Space>
      </div>
    </div>
  );
};

export default SiteHeader;
