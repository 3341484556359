import React from 'react';
import { Table, Space, Avatar } from 'antd';
import '../utils/avatarColorHelper';

const usersTableColumns = [
  {
    title: 'Name',
    key: 'displayName',
    ellipsis: true,
    width: '35%',
    sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    render: record => (
      <Space size='middle'>
        <span className='avatar'>
          <Avatar
            gap={10}
            size='large'
            className='nav-avatar'
            src={record?.photoURL || null}
            style={{ backgroundColor: record?.displayName?.replace(/\s/g, '').toLowerCase().toColor() }}>
            {(record?.displayName?.split(' ')?.[0]?.charAt(0) || '') +
              (record?.displayName?.split(' ')?.[1]?.charAt(0) || '')}
          </Avatar>
        </span>
        <div>
          <div className='users-table-name'>{record?.displayName}</div>
          {record.email}
        </div>
      </Space>
    )
  },
  {
    title: 'Role',
    key: 'role',
    width: '25%',
    render: record => record.role.charAt(0).toUpperCase() + record.role.slice(1),
    filters: [
      { text: 'Admin', value: 'admin' },
      { text: 'Manager', value: 'manager' },
      { text: 'Author', value: 'author' },
      { text: 'Reviewer', value: 'reviewer' }
    ],
    onFilter: (value, record) => record.role.includes(value)
  },
  {
    title: 'Last login',
    key: 'lastSignInTime',
    width: '35%',
    render: record => record?.lastSignInTime
  },
  {
    title: 'Permissions',
    key: 'uid',
    width: '25%',
    render: record => (record?.claims?.can_upload_files ? 'File upload' : '')
  }
];

const UsersTable = ({ data }) => {
  return (
    <Table
      className={data?.length > 10 ? 'ant-table-cell-pagination' : 'ant-table-cell'}
      columns={usersTableColumns}
      dataSource={data}
      rowKey={item => item.uid}
      pagination={{
        hideOnSinglePage: true,
        total: data.length,
        showTotal: (total, range) => (
          <span className='table-total'>
            Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
          </span>
        )
      }}
      scroll={{ y: 800 }}
    />
  );
};

export default UsersTable;
