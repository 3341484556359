export const getSensorStatus = (lastTimestamp, daily = false) => {
    if (lastTimestamp) {
        let timestamp = lastTimestamp;
        if (String(lastTimestamp).length === 10) {
            timestamp = Number(lastTimestamp) * 1000;
        }
        const timeFromNow = Date.now() - timestamp;
        let intervalOfInactivity = 22 * 60 * 1000; // equal to 22 minutes, e.g. skipped 2 or more snapshots
        if (daily) {
            intervalOfInactivity = 24 * 60 * 60 * 1000;
        }
        if (timeFromNow > intervalOfInactivity) {
            console.log('Sensor offline', timeFromNow >= intervalOfInactivity, timeFromNow, intervalOfInactivity);
            return 'Offline';
        } else {
            return 'Connected';
        }
    }
}