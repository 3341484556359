import React from 'react';
import { Table, Progress } from 'antd';
import { getSensorStatus } from '../../utils/dateTimeHelper';
import callApi from '../../utils/callApi';

const DataSourcesTable = ({ data, callback }) => {
	const dataSourcesTableColumns = [
		{
			title: 'Source',
			key: 'EquipmentName',
			render: record => (
				<span className='table-cell-primary'>{record.EquipmentName}</span>
			),
			width: '20%',
			filters: data.map(sensor => ({
				text: sensor?.EquipmentName,
				value: sensor?.EquipmentName
			})),
			onFilter: (value, record) => record?.EquipmentName.includes(value),
			sorter: (a, b) => a?.EquipmentName.localeCompare(b?.EquipmentName)
		},
		{
			title: 'Input type',
			key: 'EquipmentType',
			render: record => record.EquipmentType,
			width: '20%',
			filters: data.map(sensor => ({
				text: sensor?.EquipmentType,
				value: sensor?.EquipmentType
			})),
			onFilter: (value, record) => record?.EquipmentType.includes(value),
			sorter: (a, b) => a?.EquipmentType.localeCompare(b?.EquipmentType)
		},
		{
			title: 'Input ID',
			key: 'EquipmentUniqueIdentifier',
			render: record => record.EquipmentUniqueIdentifier,
			ellipsis: true,
			width: '15%',
			filters: data.map(sensor => ({
				text: sensor?.EquipmentUniqueIdentifier,
				value: sensor?.EquipmentUniqueIdentifier
			})),
			onFilter: (value, record) => record?.EquipmentUniqueIdentifier.includes(value),
			sorter: (a, b) =>
				a?.EquipmentUniqueIdentifier.localeCompare(b?.EquipmentUniqueIdentifier)
		},
		{
			title: 'Status',
			width: '10%',
			render: record => {
				const sensorStatus = getSensorStatus(
					record?.timestamp,
					['Feedstock', 'Grape_Harvest'].includes(record?.EquipmentUniqueIdentifier)
				);
				if (sensorStatus === 'Offline') {
					callApi('offline', { sensorId: record?.EquipmentUniqueIdentifier });
				}
				return (
					<span
						className={
							sensorStatus ? `${sensorStatus?.toLowerCase()}-info` : 'offline-info'
						}>
						{sensorStatus ?? 'Offline'}
					</span>
				);
			}
		},
		{
			title: 'Data confidence',
			width: '15%',
			render: record => (
				<Progress percent={Number(record?.DCF)} size='small' />
			)
		},
		{
			title: 'Data type',
			width: '15%',
			render: record =>
				record?.hasOwnProperty('EquipmentGroup') ? (
					<span>Onsite Sensor</span>
				) : (
					<span>Offsite Measurement</span>
				)
		},
		{
			title: 'Data entry',
			width: '10%',
			render: record =>
				record?.hasOwnProperty('EquipmentGroup') ? (
					<span>Automatic</span>
				) : (
					<span>Manual</span>
				)
		}
	];

	return (
		<Table
			onRow={record => ({
				onClick: () => callback(record)
			})}
			className={
				data?.length > 10
					? 'ant-table-cell-pagination table-click'
					: 'ant-table-cell table-click'
			}
			columns={dataSourcesTableColumns}
			dataSource={data}
			rowKey={item => item?.EquipmentUniqueIdentifier}
			pagination={{
				hideOnSinglePage: true,
				total: data?.length,
				showTotal: (total, range) => (
					<span className='table-total'>
						Showing <b>{range[0]}</b> to <b>{range[1]}</b> of <b>{total} records</b>
					</span>
				)
			}}
			scroll={{ y: 800 }}
		/>
	);
};

export default DataSourcesTable;
